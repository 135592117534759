import React, { useContext, useEffect } from "react";
import './TrackAddressChange.scss';
import PageWrapper from '../../container/PageWrapper'
import RadiantBorder from '../../global/RadiantBorder'
import Label from '../Forms/Label'
import Stepper from '../Stepper'
import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import { useLocation, useNavigate } from "react-router-dom"
import DataMapping from "../../context/data-mapping";
import { useState } from "react";
import AnalyticsContextProvider from "../../context/AnalyticsContext/AnalyticsContextProvider";
import commonFunctionObject from "../../common-function";

const TrackAddressChange = () => {
    const [data, dispatchData] = useContext(DataMapping);
    const { analytics } = useContext(AnalyticsContextProvider)
    const navigate = useNavigate();
    let viewServiceRequestData = data?.viewServiceData;
    console.log(viewServiceRequestData)
    let requestStatus = viewServiceRequestData?.[0]?.Status?.toLowerCase();
    console.log(requestStatus)
    const respDatastate = useLocation()
    // console.log(respDatastate.state.respData);
    const { pageName, setPageName } = useContext(pageNameContext);
    useEffect(() => {
        setPageName("");
        // viewServiceRequestData.sort(function(a,b){
        //     return new Date(b.CreatedDate) - new Date(a.CreatedDate);
        //   });
        if (data.length === 0) {
            navigate("/login")
        }else{
            var accountNumber = data?.currentActiveData.accountNumber
        var currentUserData = data?.parsedUserData[accountNumber]
        let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
        var gcid = currentUserData.GCID;
        let analytics_data = {
            user: {
                loginMethod: "",
                loginStatus: "",
                gcid: gcid,
                loginType: "",
                loanaccountNo: accountNumber,
                firstloginDate: ""
            },
            products: {
                productCode: productName,
            },
            data: {
                ctaText: "View Details",
                componentName: "TrackAddressChange",
                bannerTitle: "",
                menuTitle: "",
                faqTitle: "",
                searchTerm: "",
                lobActivity: "",
                status: "",
                ticketNo: viewServiceRequestData[0]?.CaseNumber,
                eventName:"View Details",
                serviceName:viewServiceRequestData[0]?.Case_Attribute__c, 
            }
        }
        analytics.trackData("serviceviewdetailsClick", analytics_data);
        }

        
        
    }, [])

    const populateServiceName = (obj)=>{
        if(obj?.Simplyfy_Name__c ){
            return obj.Simplyfy_Name__c 
        } else if (obj?.Case_Attribute__c){
            return obj.Case_Attribute__c
        } else {
            return "-";
        }
    }
    
    const populateServiceDescription = (obj)=>{
        if(obj?.Simplyfy_Description__c ){
            return obj.Simplyfy_Description__c 
        } else if (obj?.Case_Sub_Attribute__c){
            return obj.Case_Sub_Attribute__c
        } else {
            return "-";
        }
    }

   
    return (
        <PageWrapper className="track-address-change">
            <div className="track-address-head">
                <p><img src="assets/images/backArrow.svg" alt="back-arrow" onClick={() =>
                    navigate("/customer-service")
                } /></p>
                <p className="track-address-title">Track your Request</p>
            </div>
            <RadiantBorder className='track-address-change-border'>
                <div className="track-address-container">
                    <div className="track-address-label-title"><Label Label={"Requested for " + populateServiceName(viewServiceRequestData[0])} /></div>
                    <div className="track-address-id">
                        <Label Label="Tracking ID:" />
                        <div className="active-id"><Label Label={viewServiceRequestData?.[0]?.CaseNumber} /></div>
                    </div>
                    <div className="track-address-stepper">
                        <Stepper requestStatus={requestStatus} />
                    </div>

                    <div className="track-new-address-change">
                        {/* <div className="track-address-change-label"><Label Label="New Address:" /></div> */}
                        <div className="track-new-address-new-label"><Label Label={populateServiceName(viewServiceRequestData[0])} /><Label Label={populateServiceDescription(viewServiceRequestData[0])} /></div>
                    </div>
                </div>
            </RadiantBorder>
        </PageWrapper>
    )
}

export default TrackAddressChange;