import React, { useContext, useState } from "react";
import "./DigilockerSignInModal.scss";
import Modal from "../../global/Modal";
import Button from "../../global/Button/Button";
import DataMapping from '../../context/data-mapping';
import AesUtil from "../../utilities/AesUtil";
import CustomerServiceObject from "../../services/CustomerService";
import FailurePopUp from "../../global/FailurePopUp";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import PropTypes from 'prop-types';



const DigilockerSignInModal = ({ activeCurrentContract, setSignInDigiLocker }) => {
  const [data, dispatchData] = useContext(DataMapping);
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [themeState] = useContext(ThemeContext);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    "apiName": "",
    "message": "",
    "errorCode": ""
  });


  const getDigiAuthUrl = () => {
    //loader
    var reqObj = {
      "header": {
        "authToken": data?.authToken
      },
      "body": {
        "state": AesUtil.encryptData(JSON.stringify({
          "primaryGcid": activeCurrentContract.PRIMARY_GCID,
          "userId": data?.getUserDataV3.body.USER_ID
        }))
      }
    }
    CustomerServiceObject.digiAuthUrlService(reqObj).then((response) => {
      window.location.href = response.body.finalUrl;
    }).catch((error) => {
      setFailurePopUpContent({
        apiName: "",
        message: "We are experiencing technical difficulty please try after sometime",
        errorCode: error
      })
      setFailurePopUpModal(true)
      // dispatchData({
      //     name: "loaderFlag",
      //     payload: false,
      //     type: STOREDATA
      // })
    })
  }
  return (
    <>
      {failurePopUpModal ? <FailurePopUp
        onClick={() => setFailurePopUpModal(false)}
        visible={failurePopUpModal}
        setFailureModalStat={setFailurePopUpModal}
        failurePopUpContent={failurePopUpContent}
      /> : null}
      <Modal>
        <div className="digilocker-pop-up" style={{
          background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
        }}>
          <img
            src="assets/images/crossImg.svg"
            alt="close-icon"
            className="digi-pop-up-close-icon"
            onClick={() => setSignInDigiLocker(false)}


          />
          <p style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}` }} >Sign in to share the document</p>
          <img src="assets/images/digiLockerIcon.svg" alt="digilocker" />
          <Button className={"digi-pop-up-button"} onClick={() => { getDigiAuthUrl(); setSignInDigiLocker(false) }}>Sign In</Button>
        </div>
      </Modal>
    </>
  );
};

DigilockerSignInModal.propTypes = {
  setSignInDigiLocker: PropTypes.func,
  activeCurrentContract: PropTypes.object
}

export default DigilockerSignInModal;
