import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getHolidayMaster = (data) => {
 
    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, '/shaft/api/db/get-ccod-holiday-list/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getHolidayMaster