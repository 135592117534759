import React from "react";
import "./RadiantBorder.css";
import PropTypes from 'prop-types';
import DataMapping from "../../context/data-mapping";
import { useContext } from "react";


const RadiantBorder = ({ children, style, className, dynamicBorder }) => {
  const [data, dispatchData] = useContext(DataMapping);
  const isWealthSystemActive = data?.currentActiveData?.system?.toLowerCase() === "wealth";

  return (
    <div
      style={style}
      className={`${
        (dynamicBorder == "yes" && isWealthSystemActive) ? "radiant-no-border-container wealth-border" : (dynamicBorder == "yes") ? " radiant-no-border-container":""
      } ${className ? className : ""} ${ isWealthSystemActive  ? "wealth-radiant-border-container" :"radiant-border-container"}`}
    >
      {children}
    </div>
  );
};

RadiantBorder.propTypes = {
  children : PropTypes.node,
  className : PropTypes.string,
  style : PropTypes.object,
  dynamicBorder : PropTypes.string
}

export default RadiantBorder;
