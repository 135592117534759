import React from "react";
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import { THEME_DARK, THEME_LIGHT } from "../../context/ThemeContext/actions";

//CSS
import "./Footer.scss";

const Footer = () => {
    const [themeState, dispatch] = useContext(ThemeContext);
    return (
        <footer className="footer">
            <div className="main-container footer-container">
                <div className="footer-container-main">
                    <div className="footer-container-mobile">
                        <div className="footer-terms-and-condition">
                            <div className="footer-content">
                                <span className="c-pointer" onClick={() => { window.open('https://www.tatacapital.com/contact-us/branch-locator.html') }}>Branch Locator</span>
                                <span className="c-pointer" onClick={() => { window.open('https://www.tatacapital.com/about-us.html') }}>About us</span>
                                <span className="c-pointer" onClick={() => window.open('https://www.tatacapital.com/content/dam/tata-capital/pdf/tc-app/FAQs-LOGIN.PDF')}>FAQ</span>
                            </div>
                            <div className="footer-terms">
                                <span className="c-pointer" onClick={() => window.open('https://www.tatacapital.com/content/dam/tata-capital/pdf/tc-app/Retail&wealthPortalTnC.pdf')}>Terms & Conditions</span>
                            </div>
                        </div>
                        <div className="footer-contact">
                            <div className="footer-email c-pointer" onClick={() => window.open('mailto:customercare@tatacapital.com')}>
                                <p>Email :{" "}</p>
                                <p style={{ fontStyle: "italic", fontWeight: 600 }}>
                                    {" "}
                                    customercare@tatacapital.com
                                </p>
                            </div>
                            <div className="footer-customer-care-no c-pointer" onClick={() => window.open('tel:18602676060')}>
                                <p>Customer Care Number :</p>
                                <p style={{ fontStyle: "italic", fontWeight: 600 }}>1860 267 6060</p>
                            </div>
                        </div>
                        <div className="footer-social-media">
                            <div className="footer-social-media-icons">
                                {themeState === THEME_LIGHT ? (
                                    <>
                                        <img onClick={() => { window.open('https://www.facebook.com/tatacapital/') }}
                                            src="assets/images/darkFacebook.svg"
                                            alt="facebook"
                                        />
                                        <img
                                            onClick={() => { window.open('https://wa.me/7506756060') }}
                                            src="assets/images/darkWhatsapp.svg"
                                            alt="whatsapp"
                                        />
                                        <img
                                            onClick={() => { window.open('https://www.youtube.com/channel/UCCLXn_znjOupIWJ8502cUtg') }}
                                            src="assets/images/darkYoutube.svg"
                                            alt="youtube"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <img
                                            onClick={() => { window.open('https://www.facebook.com/tatacapital/') }}
                                            src="assets/images/facebookIconLight.svg"
                                            alt="facebook"
                                        />
                                        <img
                                            onClick={() => { window.open('https://wa.me/7506756060') }}
                                            src="assets/images/whatsappIconLight.svg"
                                            alt="whatsapp"
                                        />
                                        <img
                                            onClick={() => { window.open('https://www.youtube.com/channel/UCCLXn_znjOupIWJ8502cUtg') }}
                                            src="assets/images/youtubeIconLight.svg"
                                            alt="youtube"
                                        />
                                    </>
                                )}
                            </div>
                            <div className="footer-copyright">
                                <span>TATA Capital Ltd © 2023</span>
                            </div>
                        </div>
                    </div>
                    <div className="footer-container-desktop">
                        <span className="c-pointer" onClick={() => { window.open('https://www.tatacapital.com/contact-us/branch-locator.html') }}>Branch Locator</span>
                        <span className="c-pointer" onClick={() => { window.open('https://www.tatacapital.com/about-us.html') }}>About us</span>
                        <span className="c-pointer" onClick={() => window.open('https://www.tatacapital.com/content/dam/tata-capital/pdf/tc-app/FAQs-LOGIN.PDF')}>FAQ</span>
                        <span className="c-pointer" onClick={() => window.open('https://www.tatacapital.com/content/dam/tata-capital/pdf/tc-app/Retail&wealthPortalTnC.pdf')}>Terms & Conditions</span>
                        <span className="c-pointer" onClick={() => window.open('mailto:customercare@tatacapital.com')}>
                            Email :{" "}
                            <p style={{ fontStyle: "italic" }}
                            // onClick={()=>window.open('mailto:someone@example.com')}
                            >
                                {" "}
                                customercare@tatacapital.com
                            </p>
                        </span>
                        <span className="c-pointer" onClick={() => window.open('tel:18602676060')}>
                            Customer Care Number : <p>1860 267 6060</p>
                        </span>
                        <div className="footer-container-social-media">
                            {themeState === THEME_LIGHT ? (
                                <>
                                    <img onClick={() => { window.open('https://www.facebook.com/tatacapital/') }}
                                        src="assets/images/darkFacebook.svg"
                                        alt="facebook"
                                    />
                                    <img
                                        onClick={() => { window.open('https://wa.me/7506756060') }}
                                        src="assets/images/darkWhatsapp.svg"
                                        alt="whatsapp"
                                    />
                                    <img
                                        onClick={() => { window.open('https://www.youtube.com/channel/UCCLXn_znjOupIWJ8502cUtg') }}
                                        src="assets/images/darkYoutube.svg"
                                        alt="youtube"
                                    />
                                </>
                            ) : (
                                <>
                                    <img
                                        onClick={() => { window.open('https://www.facebook.com/tatacapital/') }}
                                        src="assets/images/facebookIconLight.svg"
                                        alt="facebook"
                                    />
                                    <img
                                        onClick={() => { window.open('https://wa.me/7506756060') }}
                                        src="assets/images/whatsappIconLight.svg"
                                        alt="whatsapp"
                                    />
                                    <img
                                        onClick={() => { window.open('https://www.youtube.com/channel/UCCLXn_znjOupIWJ8502cUtg') }}
                                        src="assets/images/youtubeIconLight.svg"
                                        alt="youtube"
                                    />
                                </>
                            )}
                        </div>

                        <span>Tata Capital Ltd © 2023</span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
