import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const fetchGstPdfDetails = (data) => {

  let requestJson = {
    "header": {
      "authToken": "fasafsasdasdasdasdasadaadada",
      "deviceType": "",
      "browserType": ""
    },
    "body": {
      "gstNo": "702723159000001"
    }
  }
  return new Promise((resolve, reject) => {
    stalwartApiCall('POST', data, '/shaft/api/cis/fetch-gst-pdf-details/v1').then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  });
}

export default fetchGstPdfDetails;