import getNewReferralDetails from "../../apis/generate-new-referral"
import getReferralDetails from "../../apis/get-referral-details"
import pincodeMaster from "../../apis/pincode-master"
import jsHelper from "../../utilities/jsHelper"

const ReferandEarnServiceObject = {

}

const pincodeMasterService = (requestObj) => {
    return new Promise((resolve, reject) => {
        pincodeMaster(requestObj).then((pincodeMasterResponse) => {
            if (pincodeMasterResponse.header.status.toUpperCase() === "SUCCESS") {
                resolve(pincodeMasterResponse.body.data)
            } else {
                reject()
            }
        }).catch(() => {
            reject("37")
        })
    })
}
ReferandEarnServiceObject.pincodeMasterService = pincodeMasterService;

const getReferralDetailsService = (reqObject) => {
    return new Promise((resolve, reject) => {
        getReferralDetails(reqObject).then((response) => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                if (response?.body?.ReferralDetails?.LeadR) {
                    resolve(response?.body?.ReferralDetails?.LeadR);
                } else {
                    reject({ message: response?.body?.Message })
                }
            } else {
                reject("304")
            }
        }).catch(error => {
            reject("305")
        })
    })

}
ReferandEarnServiceObject.getReferralDetailsService = getReferralDetailsService;

const getNewReferralService = (reqObject) => {
    return new Promise((resolve, reject) => {
        getNewReferralDetails(reqObject).then((response) => {

            if (response.header.status.toUpperCase() === "SUCCESS") {
                if (response.body.Status.toUpperCase() === "SUCCESS") {
                    resolve(response)
                } else {
                    if (response?.body?.Message == "Similar Lead already created in Database. No new lead created.") {
                        reject({ message: response?.body?.Message });
                    } else {
                        reject({ message: "Something went wrong!" })
                    }
                }
            } else {
                reject("306")
            }
        }).catch(error => {
            reject("307")
        })
    })
}
ReferandEarnServiceObject.getNewReferralService = getNewReferralService;

const formFieldCheck = (formfiledObject, value, userMobileNo, userEmailId) => {
    var mobregx = /^[6-9]{1}[0-9]{9}$/;
    var emailregx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let newErrorObj = {};
    let currentMobileNo = userMobileNo.replaceAll("|", ",");
    let removeSpaceFromMobileNo = currentMobileNo.replaceAll(" ", "")
    let mobileNumbers = removeSpaceFromMobileNo.split(",");
    let currentEmailId = userEmailId.replaceAll("|", ",");
    let removeSpaceFromEmail = currentEmailId.replaceAll(" ", "")
    let emailIds = removeSpaceFromEmail.split(",");

    let nameValidation = /^([a-zA-z]{1,})$/;

    console.log(mobileNumbers);
    console.log(emailIds);
    if (formfiledObject === "referralType") {
        if (value === "") {
            newErrorObj.referralType = "Please Select Referral Type"
        } else {
            newErrorObj.referralType = ""
        }
    } else if (formfiledObject === "referralOccupation") {
        if (value === "") {
            newErrorObj.referralOccupation = "Please Select Referral Occupation"
        } else {
            newErrorObj.referralOccupation = ""
        }
    } else if (formfiledObject === "firstName") {
        if (value === "") {
            newErrorObj.firstName = "Please Enter First Name"
        } else if (!nameValidation.test(value)) {
            newErrorObj.firstName = "Please Enter Valid First Name"
        } else {
            newErrorObj.firstName = ""
        }
    } else if (formfiledObject === "middleName") {
        if (value === "") {
            newErrorObj.middleName = "Please Enter Middle Name"
        } else if (!nameValidation.test(value)) {
            newErrorObj.middleName = "Please Enter Valid Middle Name"
        } else {
            newErrorObj.middleName = ""
        }
    } else if (formfiledObject === "lastName") {
        if (value === "") {
            newErrorObj.lastName = "Please Enter Last Name"
        } else if (!nameValidation.test(value)) {
            newErrorObj.lastName = "Please Enter Valid Last Name"
        } else {
            newErrorObj.lastName = ""
        }
    } else if (formfiledObject === "city") {
        if (value === "") {
            newErrorObj.city = "Please Enter City"
        } else {
            newErrorObj.city = ""
        }
    } else if (formfiledObject === "mobileNumber") {
        if (value !== "") {
            if (!mobregx.test(value)) {
                newErrorObj.mobileNumber = "Please Enter Valid Mobile Number"
            } else if (jsHelper.arrIncludes(mobileNumbers, value)) {
                newErrorObj.mobileNumber = "Mobile of Referrer and referral is same. Self Referral is not allowed."
            }
            else {
                newErrorObj.mobileNumber = ""
            }
        } else {
            newErrorObj.mobileNumber = ""
        }
    }
    else if (formfiledObject === "emailId") {
        if (value !== "") {
            if (!emailregx.test(value)) {
                newErrorObj.emailId = "Please Enter Valid Email Id"
            }
            else if (jsHelper.arrIncludes(emailIds, value)) {
                newErrorObj.emailId = "Email-Id of Referrer and referral is same. Self Referral is not allowed."
            }
            else {
                newErrorObj.emailId = ""
            }
        } else {
            newErrorObj.emailId = ""
        }
    }
    else if (formfiledObject === "referralProduct") {
        if (value === "") {
            newErrorObj.referralProduct = "Please Select Referral Product"
        } else {
            newErrorObj.referralProduct = ""
        }
    }
    else if (formfiledObject === "preferredTime") {
        if (value === "") {
            newErrorObj.preferredTime = "Please Select Preferred Time"
        } else {
            newErrorObj.preferredTime = ""
        }
    }
    return newErrorObj;

}
ReferandEarnServiceObject.formFieldCheck = formFieldCheck;

const newReferralFormValidation = (newReferralFormObj, userMobileNo, userEmailId) => {

    var mobregx = /^[6-9]{1}[0-9]{9}$/;
    var emailregx = /^[a-zA-Z0-9.]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let currentMobileNo = userMobileNo.replaceAll("|", ",");
    let removeSpaceFromMobileNo = currentMobileNo.replaceAll(" ", "")
    let mobileNumbers = removeSpaceFromMobileNo.split(",");

    let currentEmailId = userEmailId.replaceAll("|", ",");
    let removeSpaceFromEmail = currentEmailId.replaceAll(" ", "")
    let emailIds = removeSpaceFromEmail.split(",");
    let nameValidation = /^([a-zA-z]{1,})$/;
    let errorObject = {};
    if (newReferralFormObj.referralType === "") {
        errorObject.referralType = "Please Select Referral Type"
    } if (newReferralFormObj.referralOccupation === "") {
        errorObject.referralOccupation = "Please Select Referral Occupation"
    } if (!nameValidation.test(newReferralFormObj.firstName)) {
        errorObject.firstName = "Please Enter Valid First Name"

    }
    // if (!nameValidation.test(newReferralFormObj.middleName)) {
    //     errorObject.middleName = "Please Enter Valid Middle Name"

    // } 
    if (!nameValidation.test(newReferralFormObj.lastName)) {
        errorObject.lastName = "Please Enter Valid Last Name"

    } if (newReferralFormObj.city === "") {
        errorObject.city = "Please Enter City"
    } if (newReferralFormObj.mobileNumber !== "") {
        if (!mobregx.test(newReferralFormObj.mobileNumber)) {
            errorObject.mobileNumber = "Please Enter Valid Mobile Number"
        } else if (jsHelper.arrIncludes(mobileNumbers, newReferralFormObj.mobileNumber)) {
            errorObject.mobileNumber = "Mobile of Referrer and referral is same. Self Referral is not allowed."
        }
        else {
            errorObject.mobileNumber = ""
        }

    } else {
        errorObject.mobileNumber = "Please Enter Mobile Number"
    }
    if (newReferralFormObj.emailId !== "") {
        if (!emailregx.test(newReferralFormObj.emailId)) {
            errorObject.emailId = "Please Enter Valid Email Id"
        }
        else if (jsHelper.arrIncludes(emailIds, newReferralFormObj.emailId)) {
            errorObject.emailId = "Email-Id of Referrer and referral is same. Self Referral is not allowed."
        }
        else {
            errorObject.emailId = ""
        }


    } else {
        errorObject.emailId = "Please Enter Email Id"
    }
    if (newReferralFormObj.referralProduct === "") {
        errorObject.referralProduct = "Please Select Referral Product"
    } if (newReferralFormObj.preferredTime === "") {
        errorObject.preferredTime = "Please Select Preferred Time"
    }
    return errorObject;
}
ReferandEarnServiceObject.newReferralFormValidation = newReferralFormValidation;

export default ReferandEarnServiceObject;