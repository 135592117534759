const apiConfigObj = {

}


var appConfig = {

    //"env": "PRODUCTION",

    "env": process.env.REACT_APP_ENV.trim()

};

apiConfigObj.appConfig = appConfig;


export default apiConfigObj;