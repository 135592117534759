import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import commonFunctionObject from "../../common-function";
import RadiantBorder from "../../global/RadiantBorder";
import "./OldRefferalPage.scss"
import { useContext } from "react";
import DataMapping from "../../context/data-mapping";
import { STOREDATA } from "../../context/data-mapping/action/action.type";
import OverviewServiceObject from "../../services/Overview/OverviewService";
import { useState } from "react";
import FailurePopUp from "../../global/FailurePopUp";
import ReferandEarnServiceObject from "../../services/ReferandEarnService";
import OkButtonPopUp from "../../global/OkButtonPopUp";

const OldRefferalPage = () => {
    const [data, dispatchData] = useContext(DataMapping);
    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    });
    const [oldReferralDetails, setOldReferralDetails] = useState([]);
    useEffect(() => {
        commonFunctionObject.scrollToTop();
    }, []);
    useEffect(() => {
        getOldReferralDetails()
    }, []);
    const [panValue, setPanValue] = useState("");
    const [noPanPopUp, setNoPanPopUp] = useState(false);
    const [okButtonPopUpFlag, setOkButtonPopUpFlag] = useState(false)
    const [okButtonPopUpContent, setOkButtonPopUpContent] = useState("")
    const navigate = useNavigate();

    const getLastestFieldValFromField = (params) => {
        var array = params.split("|"), m = "";
        array.forEach(function (el) {
            if (el !== "") {
                el.split(",").forEach(function (e) {
                    if (e !== "" && m === "") {
                        m = e;
                    }
                });
            }
        });
        return m;
    }

    const systemPanCheck = () => {
        let retailPan = getLastestFieldValFromField(data?.getUserDataV3?.body?.pans);
        var ccodContracts = data?.getUserDataV3.body?.otherSystemDetails.filter((element) => element.SYSTEM_NAME.toLowerCase() === "ccod")
        let ccodPan = ccodContracts[0].ccodContractDetails.map(
            (ele) => {
                if (ele.cdiContract.PAN && ele.cdiContract.PAN.trim().length !== 0) {
                    return ele.cdiContract.PAN
                }
            });
        if (retailPan.length > 0 && retailPan) {
            setPanValue(retailPan)
            setNoPanPopUp(false)
            console.log("PAN ID set")
            return retailPan;
        } else if (ccodPan.length > 0 && ccodPan) {
            setPanValue(ccodPan[0])
            setNoPanPopUp(false)
            console.log("PAN ID set")
            return ccodPan;
        } else {
            return false
        }
    }

    const getOldReferralDetails = () => {

        let pancheck = systemPanCheck();

        if (pancheck) {
            var reqObject = {
                "header": {
                    "authToken": data?.authToken,
                },
                "body": {
                    // "referrerCCID": "AYCPG2169A"
                    "referrerCCID": pancheck
                }
            }
            dispatchData({
                type: STOREDATA,
                payload: true,
                name: "loaderFlag"
            })
            ReferandEarnServiceObject.getReferralDetailsService(reqObject).then((response) => {
                setOldReferralDetails(response);
                dispatchData({
                    type: STOREDATA,
                    payload: false,
                    name: "loaderFlag"
                })

            }).catch((error) => {
                if (error?.message) {
                    setFailurePopUpContent({
                        apiName: "",
                        message: error?.message,
                        errorCode: error
                    })
                } else {
                    setFailurePopUpContent({
                        apiName: "",
                        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                        errorCode: error
                    })
                }
                setFailurePopUpModal(true)
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                });
                console.log("error")
            })
        } else {
            // setFailurePopUpContent({
            //     apiName: "",
            //     message: "Pan Data Invalid",
            //     errorCode: ""
            // })
            // setFailurePopUpModal(true)
            setOkButtonPopUpContent("Pan Not Found")
            setOkButtonPopUpFlag(true)
        }

    }

    return (
        <>
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            {okButtonPopUpFlag ? <OkButtonPopUp
                onClick={() => setOkButtonPopUpFlag(false)}
                visible={okButtonPopUpFlag}
                setModalStat={setOkButtonPopUpFlag}
                Content={okButtonPopUpContent} /> : null}
            <div className="oldreferral-parent-container main-container">
                <div className='oldreferral-head'>
                    <p><img src="assets/images/backArrow.svg" alt="back-arrow" onClick={() => window.history.back()} /></p>
                    <p className="oldreferral-title">Old Referral</p>
                </div>

                <RadiantBorder className="oldreferral-container-border">
                    <div className="oldreferral-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Referral Name</th>
                                    <th>Referral Date</th>
                                    <th>Application Lead id</th>
                                    <th>Status of Application</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    oldReferralDetails.map((obj, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{obj.Referral_Name}</td>
                                                <td>{commonFunctionObject.convertDDMMYYYY(new Date(commonFunctionObject.replaceHyphenDate(obj.Referral_Date.split(" ")[0])))}</td>
                                                <td>{obj.Referral_ID}</td>
                                                <td>{obj.Referral_Stage}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </RadiantBorder>
            </div>
        </>
    )
}

export default OldRefferalPage;