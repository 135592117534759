import React from "react";

import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import { useContext } from "react";
import { THEME_DARK } from '../../context/ThemeContext/actions';

//styles
import "./Stepper-style.scss";
import { useState } from "react";
import { useEffect } from "react";
import PropTypes from 'prop-types';
const Stepper = (requestStatus) => {
  const [themeState] = useContext(ThemeContext);

  const [Status, setStatus] = useState(requestStatus.requestStatus);
  const [circlefirstactive,setcirclefirstactive] = useState("active");
  const [circlesecondactive,setcirclesecondactive] = useState("active");
  const [barfirstactive,setbarfirstActive] = useState("active");

  useEffect(()=>{
    console.log(Status)
    
    if (Status === "assigned" || Status === "open" || Status === "reopen" || Status === "new"){
      setcirclefirstactive("active");
      setcirclesecondactive("inactive");
      setbarfirstActive("active");
    }else if(Status === "closed"){
      setcirclefirstactive("active");
      setcirclesecondactive("active");
      setbarfirstActive("active");
    }
  },[])

  
  return (
    <div className='sr-popup-stepper'>
      <div className="progress-meter-box">
        <div className="circle-burger-box">
          <p className={`burger-circle burg-circ-1 active ${themeState === THEME_DARK ? "new_dark" : ""}`}>
            <img
              className="raised-img"
              src={`assets/images/stepper-progress.svg`}
            />
          </p>
          <p className={`burger-line burg-line-1 active`}></p>
          <p
            className={`burger-circle burg-circ-2 ${circlefirstactive} ${themeState === THEME_DARK ? "new_dark" : ""}`}
          >
            <img
              className="raised-img"
              src={`assets/images/stepper-progress.svg`}
            />
          </p>
          <p
            className={`burger-line burg-line-2 ${barfirstactive}`}
          ></p>
          <p
            className={` burger-circle burg-circ-3 ${circlesecondactive} ${themeState === THEME_DARK ? "new_dark" : ""}`}
          >
            <img
              className="raised-img"
              src={`assets/images/stepper-progress.svg`}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

Stepper.propTypes = {
  requestStatus : PropTypes.string
}

export default Stepper;
