import React, { useContext } from "react";
import ThemeContext from "../../../context/ThemeContext/store/ThemeContext";
import './UploadComputer.scss';
import PropTypes from 'prop-types';



const UploadComputer = ({ fileName }) => {
    const [themeState] = useContext(ThemeContext);
    return (
        <div className="upload-computer-container">
            <div className="upload-computer-data">
                <img src={
                    themeState === "THEME_DARK"
                      ? "assets/images/fileUploadIcone.svg"
                      : "assets/images/fileUploadIconWhite.svg"
                  } alt="file upload" />
                {/* <p >Upload from Computer</p> */}
                {
                    fileName ? <p>{fileName.name}</p> : <p >Upload from Computer</p>
                }
            </div>
        </div>
    )
}

UploadComputer.propTypes = { 
    fileName : PropTypes.string
  }

export default UploadComputer;