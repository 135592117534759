import React, {
  Suspense,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import JoyRide from "react-joyride";

import { useCookies } from 'react-cookie';
//Context
import ThemeContext from "./context/ThemeContext/store/ThemeContext";

//Components
import Header from "./components/Header";
//CSS
import 'antd/dist/antd.css';
import "./App.scss";

import reducer from "./context/data-mapping/reducer/reducer";
import DataMapping from "./context/data-mapping";
import PostLoginFooter from "./components/PostLoginFooter";
import Footer from "./container/Footer/Footer";

import Loader from "./global/Loader";
import Routes from "./Routes";
import Timer from "./components/Timer/Timer";
import { useLocation, useNavigate } from "react-router-dom";
import Logout from "./components/Logout";
import Analytics from "./utilities/Analytics";
import AnalyticsContextProvider from "./context/AnalyticsContext/AnalyticsContextProvider";

function App() {
  const [themeState] =
    useContext(ThemeContext);
  ;
  const analytics = new Analytics();
  const myData = JSON.parse(sessionStorage.getItem("dataMapping"));
  const [data, dispatchData] = useReducer(reducer, myData ? myData : []);
  const navigate = useNavigate()
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const [cookies, setCookie] = useCookies();
  const [logoutButtonFlag, setLogoutButtonFlag] = useState(false)

  useEffect(() => {
    checkLoginSession()
  }, [])

  function checkLoginSession() {

    console.log(location);
    var Authorization = cookies?.['Authorization']
    if (data?.authToken && Authorization && data.authToken == Authorization) {
    }
    else {
      navigate("/login" + location.search);
    }
  }

  return (
    <>
      <Suspense fallback={<Loader />}>
        <AnalyticsContextProvider.Provider value={{ analytics }}>
          <div className={`${themeState}`} >
            <div className="loader"></div>
            <div className="main-background" >
              {data?.["loaderFlag"] ? <Loader /> : null}
              <div className={pathname.toLowerCase() === "login" ? "main-wrapper login-wrapper" : "main-wrapper"}>
                <DataMapping.Provider value={[data, dispatchData]}>
                <Header />
                  {data?.authToken ? <Timer /> : null}
                  <Routes />
                  {data?.["currentActiveData"] ? <PostLoginFooter /> : null}

                </DataMapping.Provider>
                {data?.authToken ? null : <Footer />}
              </div>
            </div>
          </div>
        </AnalyticsContextProvider.Provider>
      </Suspense>
    </>
  );
}

export default App;
