import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getPanVerification = (data) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "pan": "AACPA5100D",
            "primaryGcid": "7030861"
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/jocata/pan-verification/v1',data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/dms/pan-verification/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getPanVerification