import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getPaytmCustomerDetails = (data) => {
    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, '/shaft/api/db/get-paytm-customer-details/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getPaytmCustomerDetails;