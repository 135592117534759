import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getBranchDetails = (data) => {
    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, '/shaft/api/db/get-branch-details-for-physical-noc/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getBranchDetails;