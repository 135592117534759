import React from 'react';
import './Label.scss';
import PropTypes from 'prop-types';

const Label = ({Label}) =>{
    return (
        <p className="form-label">{Label}</p>
    )
}

 
Label.propTypes = { 
    Label : PropTypes.string , 
}

export default Label;