import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//Context
import { useContext } from "react";

//CSS
import "./Input.scss";
import Tooltip from "../../components/Tooltip/Tooltip";
import DataMapping from "../../context/data-mapping";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";

const Input = ({
    className,
    error,
    errorSpace = true,
    type,
    ref,
    dynamicError,
    disabled = false,
    editIcon,
    maxLength,
    id,
    tooltipMessage,
    displayRenterField,
    fieldName,
    isRequestforContact,
    reEnterMob,
    reEnterEmail,
    autoComplete,
    mobileInput,
    reEnterMobileInput,
    emailInput,
    reEnterEmailInput,
    showOtpForMobile,
    showOtpForEmail,
    hoverTooltip,
    focus,
    editIconClass,
    failureEmailPopup,
    setEdited,
    ...otherProps
}) => { 
    
    const [inputDisabled, setInputDisabled] = useState(disabled)
    const [data, dispatchData] = useContext(DataMapping);
    const [themeState] = useContext(ThemeContext);

    const onEditClick = () => {
            if (isRequestforContact) {
                if(!reEnterMob && !reEnterEmail ){
                        setInputDisabled(false)
                }else{
                    setInputDisabled(true)
                }
            }else{
                setInputDisabled(false)
            }

            if (isRequestforContact) {
                if (fieldName === "mobile") {
                    displayRenterField("mobile");
                } else {
                    displayRenterField("email");
                }
            }else{
                setInputDisabled(false)
            }

            if(failureEmailPopup){
                setEdited(true);
            }

    }

    useEffect(() =>{
        if(mobileInput || reEnterMobileInput ||emailInput ||reEnterEmailInput){
            setInputDisabled(true);
        }else{
            if(data?.fcAccountData?.currentEmailId === "-" || data?.fcAccountData?.currentMobileNo ==="-"){
                setInputDisabled(false)
            } 
        }
    },[mobileInput, reEnterMobileInput,emailInput, reEnterEmailInput])

    return (
        <>
            <div className="input-div">
                <input
                    autoComplete={autoComplete}
                    id={id}
                    className={`${className} ${dynamicError ? "" : error ? "input-error" : ""}`}
                    maxLength={maxLength}
                    ref={ref}
                    type={type ? type : "text"}
                    autoFocus = {focus ? true : false}
                    disabled={inputDisabled ? true : false}
                    {...otherProps}
                />
                {
                    editIcon &&
                    <label className="input-edit-label" onClick={onEditClick} htmlFor={id}>
                        <div className= "edit_Img" style={editIconClass ? { background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#ECF0F5"}` , top:"1px" , bottom :"1px"}: null} >
                            {
                                failureEmailPopup ? 
                                
                                <img
                                    src={`assets/images/edit-icon-${themeState === "THEME_DARK" ? "light" : "dark"}.svg`}
                                />
                                : 
                                <img
                                src="assets/images/edit-icon.svg"
                            />
                            }
                        </div>
                    </label>

                }
                {tooltipMessage && tooltipMessage !== "" ? <Tooltip label={tooltipMessage} /> : null}
                {
                    hoverTooltip ?
                    // (hoverTooltip  && !reEnterMob) && (hoverTooltip && !reEnterEmail) ?
                    <div className="input-tooltip-div">
                        <div className="input-tooltip-text">Click Here To Edit</div>
                    </div>
                    : null
                }

            </div>
            {errorSpace && (
                <div className="error-span">
                    <span>{error}</span>
                </div>
            )}
        </>
    );
};

Input.propTypes = {
    className : PropTypes.string,
    error: PropTypes.node,
    errorSpace : PropTypes.bool,
    type: PropTypes.string,
    ref : PropTypes.string,
    onFocus : PropTypes.func,
    dynamicError : PropTypes.bool,
    disabled : PropTypes.bool,
    editIcon : PropTypes.bool,
    maxlength : PropTypes.number,
    id: PropTypes.string,
    tooltipMessage : PropTypes.string
};

export default Input;
