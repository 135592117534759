import React, { useContext } from 'react';
import './WealthRMDetails.scss'
import Modal from '../../../global/Modal'
import { useState } from 'react';
import ThemeContext from '../../../context/ThemeContext/store/ThemeContext';
import DataMapping from '../../../context/data-mapping';
import PropTypes from 'prop-types';

const WealthRMDetails = ({ closeRmDetails }) => {
    const [themeState] = useContext(ThemeContext);
    const [data, setData] = useContext(DataMapping);
    let clientProfileDetailsResponse = data?.basicDetails[data?.currentActiveData.accountNumber]?.clientProfileDetails[0];

    let { ClientName, PrimaryRMName, PrimaryRMEmail, PrimaryRMContactNo } = clientProfileDetailsResponse;

    return (
        <Modal style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}` }}>
            <div className={`wealth-rmdetails-modal-container ${themeState}`}>

                <div className='wealth-rmdetails-modal-closebtn'>
                    {/* <h2>Last five transactions</h2> */}
                    <img onClick={closeRmDetails} src={`${themeState === "THEME_DARK" ? "assets/images/wealth-close-dark.svg" : "assets/images/wealth-close.svg"}`} alt="arrow" />
                </div>
                <div className='wealth-rmdetails-modal-header'>
                    <p className='wealth-rm-value'>{ClientName}</p>
                </div>
                <div className='wealth-rm-data-parent'>
                    <div className='wealth-rm-data'>
                        <p className='wealth-rm-head'>Relationship Manager</p>
                        <p className='wealth-rm-value'>{PrimaryRMName}</p>
                    </div>
                    <div className='wealth-rm-data'>
                        <p className='wealth-rm-head'>Email</p>
                        <p className='wealth-rm-value'>{PrimaryRMEmail}</p>
                    </div>
                    <div className='wealth-rm-data'>
                        <p className='wealth-rm-head'>Mobile No.</p>
                        <p className='wealth-rm-value'>{PrimaryRMContactNo}</p>
                    </div>
                </div>
            </div>

        </Modal>
    )
}

WealthRMDetails.propTypes = {
    closeRmDetails: PropTypes.func,
}

export default WealthRMDetails