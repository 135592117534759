import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getChatBotCustomerDetails = (data) => {

    let requestJson = {
        "header": {
            "authToken": "95c2124c4a390ab6365627936a70841f9a01663be874f9d0752b9f1075534c11",
            "deviceType": "Desktop",
            "browser": "Chrome"
        },
        "body": {
            "primaryGcid": "7469541",
            "emailId": "ananya.khajuria@active.ai",
            "mobileNumber": "9958261308"
        }
    }
    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, '/shaft/api/direct/get-chatbot-customer-details/v1').then(response => {
            resolve(response.data.body.customerDetails);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getChatBotCustomerDetails;