import generateEmailOtp from "../../apis/generate-email-otp/generate-email-otp"
import generateMobileOtp from "../../apis/generate-mobile-otp/generate-mobile-otp"
import generateVoiceOtp from "../../apis/generate-voice-otp"
import getDisbursalDetails from "../../apis/get-disbursal-details/get-disbursal-details"
import getHolidayMaster from "../../apis/get-holiday-master"
import TemporaryBillabelAmount from "../../apis/get-temporary-billable-amount"
import payOnline from "../../apis/pay-online/pay-online"
import pushOnlineDisbursalDetails from "../../apis/push-online-disbursal-details/push-online-disbursal-details"
import updatePaymentTransactionStatus from "../../apis/update-payment-transaction-status/update-payment-transaction-status"
import verifyOtp from "../../apis/verify-otp/verify-otp"
import apiConfigObj from '../../app-config/app-config';

const OnlineDisbursalServiceObject = {

}

const getDisbursalDetailsService = (requestObj, ccodData, vdData,) => {

    return new Promise((resolve, reject) => {
        getDisbursalDetails(requestObj).then((getDisbursalDetailsResponse) => {
            if (getDisbursalDetailsResponse) {
                if (getDisbursalDetailsResponse.header.status.toUpperCase() === "SUCCESS") {
                    if (Object.keys(getDisbursalDetailsResponse.body).length === 0) {
                        resolve([])
                    } else {
                        var localStringObj = {
                            style: "currency",
                            currency: "INR",
                            decimal: '2'
                        }

                        var disbursalStatusArr = getDisbursalDetailsResponse.body.I_ZTW_DISB_NONTML_LOG.ITEM;

                        var disbursalArray = [];
                        if (Array.isArray(disbursalStatusArr)) {
                            disbursalStatusArr.forEach(function (element) {
                                var disbursalStatusObj = {};
                                disbursalStatusObj["LOCATION"] = (element.LOCATION) ? (element.LOCATION).toString() : '-';
                                disbursalStatusObj["ZAUTH_DT"] = (element.ZAUTH_DT) ? (element.ZAUTH_DT).toString() : '-';
                                disbursalStatusObj["GJAHR"] = (element.GJAHR) ? (element.GJAHR).toString() : '-';
                                disbursalStatusObj["ZREQ_AMT"] = (element.ZREQ_AMT) ? Number((element.ZREQ_AMT).toString()) : '0';
                                disbursalStatusObj["ZMAN_VEN_NAME"] = (element.ZMAN_VEN_NAME) ? (element.ZMAN_VEN_NAME).toString() : '-';
                                disbursalStatusObj["BANK_REF_NO"] = (element.BANK_REF_NO) ? (element.BANK_REF_NO).toString() : '-';
                                disbursalStatusObj["ZCRE_TIME"] = (element.ZCRE_TIME) ? (element.ZCRE_TIME).toString() : '-';
                                disbursalStatusObj["BUDAT"] = (element.BUDAT) ? (element.BUDAT).toString() : '-';
                                disbursalStatusObj["ZAUTH_STAT"] = (element.ZAUTH_STAT) ? (element.ZAUTH_STAT).toString() : '-';
                                disbursalStatusObj["MANU_CO_CD_TCL"] = (element.MANU_CO_CD_TCL) ? (element.MANU_CO_CD_TCL).toString() : '-';
                                disbursalStatusObj["BUKRS"] = (element.BUKRS) ? (element.BUKRS).toString() : '-';
                                disbursalStatusObj["HERSE"] = (element.HERSE) ? (element.HERSE).toString() : '-';
                                disbursalStatusObj["PNAME"] = (element.PNAME) ? (element.PNAME).toString() : '-';
                                disbursalStatusObj["ZTSLSALESORD"] = (element.ZTSLSALESORD) ? (element.ZTSLSALESORD).toString() : '-';
                                disbursalStatusObj["ZREJ_STAT"] = (element.ZREJ_STAT) ? (element.ZREJ_STAT).toString() : '-';
                                disbursalStatusObj["PRODUCT"] = (element.PRODUCT) ? (element.PRODUCT).toString() : '-';
                                disbursalStatusObj["ACT_AVAIL_LIMIT"] = (element.ACT_AVAIL_LIMIT) ? (element.ACT_AVAIL_LIMIT).toString() : '-';
                                disbursalStatusObj["ZDIS_AMT"] = (element.ZDIS_AMT) ? Number((element.ZDIS_AMT).toString()) : '0';
                                disbursalStatusObj["ZAUTH_USER"] = (element.ZAUTH_USER) ? (element.ZAUTH_USER).toString() : '-';
                                disbursalStatusObj["ZAUTH_TIME"] = (element.ZAUTH_TIME) ? (element.ZAUTH_TIME).toString() : '-';
                                disbursalStatusObj["ZREF_NO"] = (element.ZREF_NO) ? (element.ZREF_NO).toString() : '-';
                                disbursalStatusObj["MANU_CO_CODE"] = (element.MANU_CO_CODE) ? (element.MANU_CO_CODE).toString() : '-';
                                disbursalStatusObj["BELNR"] = (element.BELNR) ? (element.BELNR).toString() : '-';
                                disbursalStatusObj["REGION"] = (element.REGION) ? (element.REGION).toString() : '-';
                                disbursalStatusObj["ZTSLVENCODE"] = (element.ZTSLVENCODE) ? (element.ZTSLVENCODE).toString() : '-';
                                disbursalStatusObj["ZUSER"] = (element.ZUSER) ? (element.ZUSER).toString() : '-';
                                disbursalStatusObj["ZDEL_VEN"] = (element.ZDEL_VEN) ? (element.ZDEL_VEN).toString() : '-';
                                disbursalStatusObj["ZDEL_VEN_NAME"] = (element.ZDEL_VEN_NAME) ? (element.ZDEL_VEN_NAME).toString() : '-';
                                disbursalStatusObj["ZCRE_DT"] = (element.ZCRE_DT) ? (element.ZCRE_DT).toString() : '-';
                                disbursalStatusObj["ZREF_DT"] = (element.ZREF_DT) ? (element.ZREF_DT).toString() : '-';
                                disbursalStatusObj["BRANCH"] = (element.BRANCH) ? (element.BRANCH).toString() : '-';

                                disbursalArray.push(disbursalStatusObj);
                            })

                        } else {
                            var disbursalStatusObj = {};
                            disbursalStatusObj["LOCATION"] = (disbursalStatusArr.LOCATION) ? (disbursalStatusArr.LOCATION).toString() : '-';
                            disbursalStatusObj["ZAUTH_DT"] = (disbursalStatusArr.ZAUTH_DT) ? (disbursalStatusArr.ZAUTH_DT).toString() : '-';
                            disbursalStatusObj["GJAHR"] = (disbursalStatusArr.GJAHR) ? (disbursalStatusArr.GJAHR).toString() : '-';
                            disbursalStatusObj["ZREQ_AMT"] = (disbursalStatusArr.ZREQ_AMT) ? Number((disbursalStatusArr.ZREQ_AMT).toString()) : '0';
                            disbursalStatusObj["ZMAN_VEN_NAME"] = (disbursalStatusArr.ZMAN_VEN_NAME) ? (disbursalStatusArr.ZMAN_VEN_NAME).toString() : '-';
                            disbursalStatusObj["BANK_REF_NO"] = (disbursalStatusArr.BANK_REF_NO) ? (disbursalStatusArr.BANK_REF_NO).toString() : '-';
                            disbursalStatusObj["ZCRE_TIME"] = (disbursalStatusArr.ZCRE_TIME) ? (disbursalStatusArr.ZCRE_TIME).toString() : '-';
                            disbursalStatusObj["BUDAT"] = (disbursalStatusArr.BUDAT) ? (disbursalStatusArr.BUDAT).toString() : '-';
                            disbursalStatusObj["ZAUTH_STAT"] = (disbursalStatusArr.ZAUTH_STAT) ? (disbursalStatusArr.ZAUTH_STAT).toString() : '-';
                            disbursalStatusObj["MANU_CO_CD_TCL"] = (disbursalStatusArr.MANU_CO_CD_TCL) ? (disbursalStatusArr.MANU_CO_CD_TCL).toString() : '-';
                            disbursalStatusObj["BUKRS"] = (disbursalStatusArr.BUKRS) ? (disbursalStatusArr.BUKRS).toString() : '-';
                            disbursalStatusObj["HERSE"] = (disbursalStatusArr.HERSE) ? (disbursalStatusArr.HERSE).toString() : '-';
                            disbursalStatusObj["PNAME"] = (disbursalStatusArr.PNAME) ? (disbursalStatusArr.PNAME).toString() : '-';
                            disbursalStatusObj["ZTSLSALESORD"] = (disbursalStatusArr.ZTSLSALESORD) ? (disbursalStatusArr.ZTSLSALESORD).toString() : '-';
                            disbursalStatusObj["ZREJ_STAT"] = (disbursalStatusArr.ZREJ_STAT) ? (disbursalStatusArr.ZREJ_STAT).toString() : '-';
                            disbursalStatusObj["PRODUCT"] = (disbursalStatusArr.PRODUCT) ? (disbursalStatusArr.PRODUCT).toString() : '-';
                            disbursalStatusObj["ACT_AVAIL_LIMIT"] = (disbursalStatusArr.ACT_AVAIL_LIMIT) ? (disbursalStatusArr.ACT_AVAIL_LIMIT).toString() : '-';
                            disbursalStatusObj["ZDIS_AMT"] = (disbursalStatusArr.ZDIS_AMT) ? Number((disbursalStatusArr.ZDIS_AMT).toString) : '0';
                            disbursalStatusObj["ZAUTH_USER"] = (disbursalStatusArr.ZAUTH_USER) ? (disbursalStatusArr.ZAUTH_USER).toString() : '-';
                            disbursalStatusObj["ZAUTH_TIME"] = (disbursalStatusArr.ZAUTH_TIME) ? (disbursalStatusArr.ZAUTH_TIME).toString() : '-';
                            disbursalStatusObj["ZREF_NO"] = (disbursalStatusArr.ZREF_NO) ? (disbursalStatusArr.ZREF_NO).toString() : '-';
                            disbursalStatusObj["MANU_CO_CODE"] = (disbursalStatusArr.MANU_CO_CODE) ? (disbursalStatusArr.MANU_CO_CODE).toString() : '-';
                            disbursalStatusObj["BELNR"] = (disbursalStatusArr.BELNR) ? (disbursalStatusArr.BELNR).toString() : '-';
                            disbursalStatusObj["REGION"] = (disbursalStatusArr.REGION) ? (disbursalStatusArr.REGION).toString() : '-';
                            disbursalStatusObj["ZTSLVENCODE"] = (disbursalStatusArr.ZTSLVENCODE) ? (disbursalStatusArr.ZTSLVENCODE).toString() : '-';
                            disbursalStatusObj["ZUSER"] = (disbursalStatusArr.ZUSER) ? (disbursalStatusArr.ZUSER).toString() : '-';
                            disbursalStatusObj["ZDEL_VEN"] = (disbursalStatusArr.ZDEL_VEN) ? (disbursalStatusArr.ZDEL_VEN).toString() : '-';
                            disbursalStatusObj["ZDEL_VEN_NAME"] = (disbursalStatusArr.ZDEL_VEN_NAME) ? (disbursalStatusArr.ZDEL_VEN_NAME).toString() : '-';
                            disbursalStatusObj["ZCRE_DT"] = (disbursalStatusArr.ZCRE_DT) ? (disbursalStatusArr.ZCRE_DT).toString() : '-';
                            disbursalStatusObj["ZREF_DT"] = (disbursalStatusArr.ZREF_DT) ? (disbursalStatusArr.ZREF_DT).toString() : '-';
                            disbursalStatusObj["BRANCH"] = (disbursalStatusArr.BRANCH) ? (disbursalStatusArr.BRANCH).toString() : '-';
                            disbursalArray.push(disbursalStatusObj);
                        }

                        var data = onlineDisbursalParser(ccodData.cdiContract, vdData, ccodData.sapContract, disbursalArray)
                        resolve(data)
                    }
                } else {
                    reject("C-14")
                }

            } else {
                reject("C-15")
            }
        }).catch((error) => {
            reject("C-16")
        })
    })

}

OnlineDisbursalServiceObject.getDisbursalDetailsService = getDisbursalDetailsService;

function onlineDisbursalParser(userDetail, summaryDTLS, sapDetails, disbursalArray) {
    var onlineDisbursalObj = {};
    onlineDisbursalObj["CUSTOMER_NO"] = (userDetail.CUSTOMER_NO) ? userDetail.CUSTOMER_NO : "-";
    onlineDisbursalObj["CUSTOMER_NAME"] = (userDetail.CUSTOMER_NAME) ? userDetail.CUSTOMER_NAME : "-";
    onlineDisbursalObj["MANUFACTURE_GROUP_ID"] = (sapDetails.MANUFACTURE_GROUP_ID) ? sapDetails.MANUFACTURE_GROUP_ID : "-";
    onlineDisbursalObj["AVAIL_LIMIT"] = (summaryDTLS.AVAIL_LIMIT) ? summaryDTLS.AVAIL_LIMIT : "-";
    onlineDisbursalObj["PRODUCT"] = (summaryDTLS.PRODUCT) ? summaryDTLS.PRODUCT : "-";
    onlineDisbursalObj["REQUEST_DATE"] = requestDate();
    onlineDisbursalObj["PAYMENT_DATE"] = requestDate();
    onlineDisbursalObj["BRANCH"] = (disbursalArray[0].BRANCH) ? disbursalArray[0].BRANCH : "-"
    return onlineDisbursalObj;
}


function requestDate() {
    var currentDate = new Date();
    var dd = String(currentDate.getDate()).padStart(2, '0');
    var mm = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = currentDate.getFullYear();

    return dd + '/' + mm + '/' + yyyy;
}


const maskMobile = (mobile) => {
    const discardedMobile = mobile.slice(
        2,
        6
    );
    const discardedMobileArray = discardedMobile?.split("");
    const discardedMobileMap = discardedMobileArray?.map((item) => "*");
    const discardedMobileString = discardedMobileMap?.join("");

    const encryptedMobile = "**"
        .concat(
            discardedMobileString,
            mobile.slice(
                6,
                mobile.length
            )
        );
    return encryptedMobile;
}

OnlineDisbursalServiceObject.maskMobile = maskMobile

const maskEmail = (email) => {
    const discardedEmail = email.slice(
        2,
        email.indexOf("@")
    );
    const discardedEmailArray = discardedEmail?.split("");
    const discardedEmailMap = discardedEmailArray?.map((item) => "*");
    const discardedEmailString = discardedEmailMap?.join("");

    const encryptedEmail = email.slice(0, 2)
        .concat(
            discardedEmailString,
            email.slice(
                email.indexOf("@"),
                email.length
            )
        );

    return encryptedEmail
}
OnlineDisbursalServiceObject.maskEmail = maskEmail


const callPayOnlineAPI = (reqObj) => {
    return new Promise((resolve, reject) => {
        payOnline(reqObj).then((payOnlineResponse) => {
            if (payOnlineResponse.header.status.toUpperCase() === "SUCCESS") {
                if (Object.keys(payOnlineResponse.body).length !== 0) {
                    var payOnlineObjResponse = payOnlineResponse.body;
                    var payOnlineObj = {};

                    payOnlineObj["finalUrl"] = (payOnlineObjResponse.finalUrl) ? String(payOnlineObjResponse.finalUrl) : '-';
                    payOnlineObj["uniqueTransactionId"] = (payOnlineObjResponse.uniqueTransactionId) ? String(payOnlineObjResponse.uniqueTransactionId) : '-';
                    payOnlineObj["encryptedData"] = (payOnlineObjResponse.encryptedData) ? String(payOnlineObjResponse.encryptedData) : '-';
                    var pgsHref;
                    if (apiConfigObj.appConfig.env === "DEVELOPMENT") {
                        pgsHref = "https://online1.tatacapital.com:8085/#/payment-gateway?pgsmsg=" + encodeURIComponent(payOnlineResponse.body.encryptedData) + '&channel=CCOD';
                    } else {
                        pgsHref = "https://www.tatacapital.com/online/payment/tcl-payments/#/payment-gateway?pgsmsg=" + encodeURIComponent(payOnlineResponse.body.encryptedData) + '&channel=CCOD';
                    }
                    window.open(pgsHref, "_blank");
                    resolve(payOnlineObj.uniqueTransactionId);
                }
            } else {
                reject("C-08")
            }
        }).catch((error) => {
            reject("C-09")
        })
    })
}
OnlineDisbursalServiceObject.callPayOnlineAPI = callPayOnlineAPI



const paymentTransactionStatus = (transactionId, authToken) => {
    return new Promise((resolve, reject) => {
        var reqObj = {
            "header": {
                "authToken": authToken
            },
            "body": {
                "transactionId": transactionId,
                "transactionStatus": "IN PROGRESS"
            }
        }

        console.log("updatePaymentTransaction", reqObj);
        updatePaymentTransactionStatus(reqObj).then((response) => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                if (Object.keys(response.body).length !== 0) {

                    var updatePaymentResponseObj = response.body;
                    var updatePaymentObj = {};

                    updatePaymentObj["transactionId"] = (updatePaymentResponseObj.transactionId) ? String(updatePaymentResponseObj.transactionId) : '-';
                    updatePaymentObj["transactionStatus"] = (updatePaymentResponseObj.transactionStatus) ? String(updatePaymentResponseObj.transactionStatus) : '-';
                    updatePaymentObj["status"] = (updatePaymentResponseObj.status) ? String(updatePaymentResponseObj.status) : '-';
                    resolve()
                }
            }

        }).catch(function (error) {
            reject("C-09")
        });
    })

}

OnlineDisbursalServiceObject.paymentTransactionStatus = paymentTransactionStatus

const generateMobileOtpService = (requestObj) => {
    return new Promise((resolve, reject) => {
        generateMobileOtp(requestObj).then((generateMobileOtpResponse) => {
            if (generateMobileOtpResponse.header.status == "SUCCESS") {
                if (generateMobileOtpResponse.body.retStatus == "SUCCESS") {
                    resolve(generateMobileOtpResponse.body.otpRefNo)
                } else {
                    reject("C-21")
                }
            } else if (generateMobileOtpResponse.header.status.toUpperCase() == "FAILURE") {
                if (generateMobileOtpResponse.body.message.toLowerCase().includes('exceeded')) {
                    reject("number of attempts exceeded");
                }

            }
            else {
                reject("C-22")
            }
        }).catch((error) => {
            reject("C-23")
        })
    })
}
OnlineDisbursalServiceObject.generateMobileOtpService = generateMobileOtpService

const generateEmailOtpService = (requestObj) => {
    return new Promise((resolve, reject) => {
        generateEmailOtp(requestObj).then((generateEmailOtpResponse) => {
            if (generateEmailOtpResponse.header.status == "SUCCESS") {
                if (generateEmailOtpResponse.body.retStatus == "SUCCESS") {
                    resolve(generateEmailOtpResponse.body.otpRefNo)
                } else {
                    reject("C-21")
                }
            } else if (generateEmailOtpResponse.header.status.toUpperCase() == "FAILURE") {
                if (generateEmailOtpResponse.body.message.toLowerCase().includes('exceeded')) {
                    reject("number of attempts exceeded");
                }
            }
            else {
                reject("C-22")
            }
        }).catch((error) => {
            reject("C-23")
        })
    })
}
OnlineDisbursalServiceObject.generateEmailOtpService = generateEmailOtpService

const generateVoiceOtpService = (mobile, refId, auth) => {
    return new Promise((resolve, reject) => {
        generateVoiceOtp({
            "header": {
                "authToken": auth
            },
            "body": {
                "mobileNo": mobile,
                "otpRef": refId,
            }
        }).then((generateVoiceOtpResponse) => {
            
            if (generateVoiceOtpResponse.header.status.toUpperCase() === "SUCCESS") {
                if (generateVoiceOtpResponse.body.status.toUpperCase() === "SUCCESS") {
                    resolve(generateVoiceOtpResponse)
                } else {
                    reject("219")
                }
            } else {
                if (generateVoiceOtpResponse?.body?.message.toLowerCase().includes('exceeded')) {
                    reject("number of attempts exceeded");
                } else {
                    reject("220")
                }
            }
        }).catch((error) => {
            
            reject("221")
        })
    })
}
OnlineDisbursalServiceObject.generateVoiceOtpService = generateVoiceOtpService


const verifyOtpService = (otp, refNo, userInput, auth) => {
    return new Promise((resolve, reject) => {

        let requestObj;
        if (userInput.search("@") > -1) {
            requestObj = {
                "header": {
                    "grantType": "otp",
                    "serviceType": "email",
                    "authToken": auth,
                },
                "body": {
                    "emailId": userInput,
                    "otpRef": refNo,
                    "otp": otp
                }
            }
        } else {
            requestObj = {
                "header": {
                    "grantType": "otp",
                    "serviceType": "mobile",
                    "authToken": auth,
                },
                "body": {
                    "mobileNo": userInput,
                    "otpRef": refNo,
                    "otp": otp
                }
            }

        }
        verifyOtp(requestObj).then((verifyOtpResponse) => {

            if (verifyOtpResponse.header.status.toUpperCase() === "SUCCESS") {
                if (verifyOtpResponse.body.retStatus.toUpperCase() === "SUCCESS") {

                    resolve(verifyOtpResponse)
                } else {
                    //todo
                }

            } else {
                reject("otp invalid")
            }
            resolve(verifyOtpResponse)
        }).catch(((error) => {

            reject("03")
        }))
    })
}
OnlineDisbursalServiceObject.verifyOtpService = verifyOtpService

const pushOnlineDisbursalService = (onlineDisbursalObject, ccodData, companyName, activeCCODContract, auth) => {

    return new Promise((resolve, reject) => {

        pushOnlineDisbursalDetails({
            "header": {
                "authToken": auth,
                "company": companyName
            },
            "body": {
                "pName": ccodData.PROGRAM_NAME,
                "manuCoCode": onlineDisbursalObject.onlineDisbursalAccount,
                "corporateID": ccodData.MANU_CO_CODE,
                "zdelVen": activeCCODContract,
                "zrefDt": requestDateRequest(onlineDisbursalObject.requestDate),
                "budat": requestDateRequest(onlineDisbursalObject.requestDate),
                "zreqAmt": onlineDisbursalObject.disbursalRequestAmount,
                "location": "Mumbai",
                "region": "WEST",
                "ztslvenCode": "",
                "ztslSalesORD": "",
                "billableAmount": onlineDisbursalObject.billableAmount.replace('₹', '').trim(),
                "product": onlineDisbursalObject.product,
                "branchName": onlineDisbursalObject.branch,
                "customerName": onlineDisbursalObject.customerName
            }
        }).then((pushOnlineDisbursalResponse) => {
            if (pushOnlineDisbursalResponse.header.status.toUpperCase() === "SUCCESS") {
                var onlineDisbursalArr = pushOnlineDisbursalResponse.body.RETURN.ITEM;
                var onlineDisbursalTransactionId = pushOnlineDisbursalResponse.body.transactionId;
                var onlineDisbursalObj = {};
                onlineDisbursalObj["SYSTEM"] = (onlineDisbursalArr.SYSTEM) ? (onlineDisbursalArr.SYSTEM).toString() : '-';
                onlineDisbursalObj["NUMBER"] = (onlineDisbursalArr.NUMBER) ? (onlineDisbursalArr.NUMBER).toString() : '-';
                onlineDisbursalObj["FIELD"] = (onlineDisbursalArr.FIELD) ? (onlineDisbursalArr.FIELD).toString() : '-';
                onlineDisbursalObj["MESSAGE_V2"] = (onlineDisbursalArr.MESSAGE_V2) ? (onlineDisbursalArr.MESSAGE_V2).toString() : '-';
                onlineDisbursalObj["MESSAGE"] = (onlineDisbursalArr.MESSAGE) ? (onlineDisbursalArr.MESSAGE).toString() : '-';
                onlineDisbursalObj["MESSAGE_V3"] = (onlineDisbursalArr.MESSAGE_V3) ? (onlineDisbursalArr.MESSAGE_V3).toString() : '-';
                onlineDisbursalObj["MESSAGE_V4"] = (onlineDisbursalArr.MESSAGE_V4) ? (onlineDisbursalArr.MESSAGE_V4).toString() : '-';
                onlineDisbursalObj["LOG_NO"] = (onlineDisbursalArr.LOG_NO) ? (onlineDisbursalArr.LOG_NO).toString() : '-';
                onlineDisbursalObj["MESSAGE_V1"] = (onlineDisbursalArr.MESSAGE_V1) ? (onlineDisbursalArr.MESSAGE_V1).toString() : '-';
                onlineDisbursalObj["ID"] = (onlineDisbursalArr.ID) ? (onlineDisbursalArr.ID).toString() : '-';
                onlineDisbursalObj["ROW"] = (onlineDisbursalArr.ROW) ? (onlineDisbursalArr.ROW).toString() : '-';
                onlineDisbursalObj["TYPE"] = (onlineDisbursalArr.TYPE) ? (onlineDisbursalArr.TYPE).toString() : '-';
                onlineDisbursalObj["LOG_MSG_NO"] = (onlineDisbursalArr.LOG_MSG_NO) ? (onlineDisbursalArr.LOG_MSG_NO).toString() : '-';
                onlineDisbursalObj["PARAMETER"] = (onlineDisbursalArr.PARAMETER) ? (onlineDisbursalArr.PARAMETER).toString() : '-';

                if (onlineDisbursalObj.MESSAGE.toUpperCase() === "ENTRY SAVED") {
                    resolve(onlineDisbursalTransactionId)
                } else if (onlineDisbursalObj.MESSAGE.toUpperCase() === "ENTRY ALREADY EXISTS RECORD NOT SAVED") {
                    reject("ENTRY ALREADY EXISTS RECORD NOT SAVED")
                } else {
                    reject("C-30")
                }
            } else {
                reject("C-31")
            }
        }).catch((error) => {
            reject("C-33")
        })
    })
}

OnlineDisbursalServiceObject.pushOnlineDisbursalService = pushOnlineDisbursalService

function requestDateRequest(date) {
    var theDate = date.split('/');
    console.log(theDate);
    var yyyy = theDate[2];
    var mm = theDate[1];
    var dd = theDate[0];

    var requestDateRequest = yyyy + '-' + mm + '-' + dd;
    return requestDateRequest;
}

const getHolidayMasterService = (requestObj) =>{
    return new Promise((resolve, reject) => {
        getHolidayMaster(requestObj).then((getHolidayMasterResponse) => {
            if(getHolidayMasterResponse.header.status.toUpperCase() === "SUCCESS"){
                resolve(getHolidayMasterResponse)
            }else{
                reject("308")
            }
        }).catch((error)=>{
            reject("309");
        })
    })
    
}
OnlineDisbursalServiceObject.getHolidayMasterService = getHolidayMasterService;

const TemporaryBillabelAmountService = (requestObj) => {
    return new Promise((resolve, reject) => {
        TemporaryBillabelAmount(requestObj).then((TemporaryBillabelAmountResponse) => {
            if (TemporaryBillabelAmountResponse) {
                if (TemporaryBillabelAmountResponse.header.status.toUpperCase() === "SUCCESS") {
                    resolve(TemporaryBillabelAmountResponse?.body?.MT_TW_DISB_LIMIT_REP_RESP?.item);
                } else {
                    reject("")
                }

            } else {
                reject("")
            }
        }).catch((error) => {
            reject("")
        })
    })

}

OnlineDisbursalServiceObject.TemporaryBillabelAmountService = TemporaryBillabelAmountService;

export default OnlineDisbursalServiceObject