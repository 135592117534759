import React, { useState } from 'react';
import Button from '../../global/Button';
import Modal from '../../global/Modal';
import Input from '../../global/Input';
import './WhatsAppRegisterModal.scss';
import { useContext } from 'react';
import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import OverviewServiceObject from '../../services/Overview';
import DataMapping from '../../context/data-mapping';
import { STOREDATA } from '../../context/data-mapping/action/action.type';
import FailurePopUp from '../../global/FailurePopUp';
import PropTypes from 'prop-types';

const WhatsAppRegisterModal = ({ setWhatsAppPopUp, setWhatsAppSuccessPopUp }) => {
  const [themeState] = useContext(ThemeContext);
  const [contextData, dispatchData] = useContext(DataMapping);
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [handleDisble, setHandleDisble] = useState(true);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    apiName: "",
    message: "",
    errorCode: "",
  });

  const isWealthSystemActive = contextData?.currentActiveData?.system?.toLowerCase() === "wealth";

  const [formfiledObject, setFormfiledObject] = useState({
    mobileNumber: isWealthSystemActive ? contextData.basicDetails[contextData.currentActiveData.accountNumber].MOBILE_NO : contextData.loginMobileNo,
    radio: false,
  })
  const [formfiledErrorObject, setFormfiledErrorObject] = useState({
    mobileNumber: "",
    radio: "",
  })

  const inputPopulation = (value, fieldName) => {

    setFormfiledObject({ ...formfiledObject, [fieldName]: value })
    let finalErrorObject = OverviewServiceObject.whatsApptFormFieldCheck(fieldName, value)
    setFormfiledErrorObject({ ...formfiledErrorObject, ...finalErrorObject })
  }

  const getSystemId = () => {
    let currentSystem = contextData.currentActiveData?.system.toLowerCase();
    let systemID;
    if (currentSystem) {
      if (currentSystem === "retail") {
        systemID = "1"
      } else if (currentSystem === "las") {
        systemID = "2"
      } else if (currentSystem === "ccod") {
        systemID = "3"
      } else {
        systemID = "4"
      }
    }
    return systemID;
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    let newErrorObject = OverviewServiceObject.whatsapptFormSubmitCheck(formfiledObject)
    setFormfiledErrorObject({ ...formfiledErrorObject, ...newErrorObject })
    let validate = validationCheck(newErrorObject)
    let systemId = getSystemId();
    if (validate) {
      let requestObj = {
        "header": {
          "authToken": contextData?.authToken,
          "systemId": systemId
        },
        "body": {
          "mobileNo": formfiledObject.mobileNumber
        }
      }
      dispatchData({
        name: "loaderFlag",
        payload: true,
        type: STOREDATA
      })
      OverviewServiceObject.whatsApiService(requestObj).then((value) => {
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA
        })
        setWhatsAppSuccessPopUp(true)
        setWhatsAppPopUp(false)
      }).catch((error) => {
        setFailurePopUpContent({
          apiName: "",
          message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
          errorCode: error,
        });
        setFailurePopUpModal(true)
        dispatchData({
          name: "loaderFlag",
          payload: false,
          type: STOREDATA
        })
      })
    }

  }


  const validationCheck = (finalErrorObject) => {
    var count = 0
    Object.keys(formfiledObject).map((keyName) => {
      if (finalErrorObject[keyName] && finalErrorObject[keyName] !== "") {
        count += 1
      }
    })
    if (count > 0) {
      return false
    } else {
      return true
    }
  }
  return (
    <Modal>
      <div className='whatsapp-register-container' style={{ background: `${themeState === "THEME_DARK" ? "#22232E" : "#FFFFFF"}` }}>
        <div className='whatsapp-register-parent'>
          <div className='whatsapp-register-img-container'>
            {themeState === "THEME_DARK" ?
              <>
                <img className='whatsapp-register-image-dark' src={isWealthSystemActive ? "assets/images/light-icon/whatsapp-img-wealth.svg" : "assets/images/dark-icon/whatsapp-register-image-dark.svg"} alt="whatsapp-service" />
                <img className='whatsapp-register-background-image-dark' src={isWealthSystemActive ? "assets/images/dark-icon/whatsapp-bg-dark.svg" : "assets/images/dark-icon/whatsapp-bg-dark.svg"} alt='whatsApp Register' />
              </>
              :
              <>
                <img className='whatsapp-register-image-light' src={isWealthSystemActive ? "assets/images/light-icon/whatsapp-img-wealth.svg" : "assets/images/light-icon/whatsapp-image.svg"} alt="whatsapp image" />
                <img className='whatsapp-register-background-image-light' src={isWealthSystemActive ? "assets/images/light-icon/whatsapp-bg.svg" : "assets/images/light-icon/whatsapp-bg.svg"} alt='whatsApp Register' />
              </>
            }

          </div>
          <div className='whatsapp-register-content-container'>
            <div className='whatsapp-register-container-parent'>
              <p style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }} className='register'>Register to service your Loan via WhatsApp!</p>
              <form>
                <div className={themeState === "THEME_DARK" ? " whatsapp-mobile-form global-input-dark" : "whatsapp-mobile-form"}>
                  <label style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }} htmlFor='whatsapp_input'>Please confirm your mobile number registered with us</label>
                  <Input value={formfiledObject.mobileNumber} onChange={(e) => inputPopulation(e.target.value, "mobileNumber")} disabled={true} editIcon={true} style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#ECF0F5"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }} type='text' id='whatsapp_input' placeholder="Please Enter Mobile Number" errorSpace={false} />
                  <span className='error-span'>{formfiledErrorObject.mobileNumber}</span>
                </div>
                <div className='TandC_Whatsapp'>
                  <label className={`${themeState === "THEME_DARK" ? "whatsapp-lable-dark" : "whatsapp-lable-light"}`} style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}>

                    <input onChange={(e) => { inputPopulation(e.target.checked, "radio"); setHandleDisble(!handleDisble) }} style={{ border: `${themeState === "THEME_DARK" ? "#fff" : "000"}` }} type="checkbox" />

                    <span className={isWealthSystemActive ? "whatsappCheckboxWealth" : "whatsappCheckbox"}></span>
                  </label>

                  <div className='tandC_Content' style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "000"}` }}>
                    {/* <span>
                      I agree to the
                      <a onClick={() => window.open('assets/images/TermsandConditions.pdf')} className="termsConditionLink" >
                        &nbsp;Terms & Conditions&nbsp;
                      </a>
                      and <br />
                      <a className="termsConditionLink" >
                        Privacy Policy
                      </a>
                      .
                    </span> */}
                    {
                      isWealthSystemActive ? <p>
                        I agree to the
                        <a className="whatsApptermsConditionLink"
                          href="https://www.tatacapital.com/content/dam/tata-capital/pdf/whatsapp-pdf/WhatsAppTermsandConditions-Wealth.pdf" style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "000"}` }} target="_blank"><span className="whatsAppprivacyWealth">
                            Terms & Conditions
                          </span></a> and&nbsp;
                        <a className="whatsApptermsConditionLink"
                          href="https://www.tatacapital.com/content/dam/tata-capital/pdf/whatsapp-pdf/WhatsAppTermsandConditions-Wealth.pdf" style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "000"}` }} target="_blank"><span className="whatsAppprivacyWealth">
                            Privacy Policy
                          </span></a>
                      </p> : <p>
                        I agree to the
                        <a className="whatsApptermsConditionLink"
                          href="https://www.tatacapital.com/content/dam/tata-capital/pdf/whatsapp-pdf/Terms&Conditions.pdf" style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "000"}` }} target="_blank"><span className="whatsAppprivacy">
                            Terms & Conditions
                          </span></a> and&nbsp;
                        <a className="whatsApptermsConditionLink"
                          href="https://www.tatacapital.com/content/dam/tata-capital/pdf/whatsapp-pdf/Terms&Conditions.pdf" style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "000"}` }} target="_blank"><span className="whatsAppprivacy">
                            Privacy Policy
                          </span></a>
                      </p>
                    }

                    {/* {<span className='error-span'>{formfiledErrorObject.radio}</span>} */}
                  </div>
                </div>
                <Button disable={handleDisble} onClick={handleSubmit} type={"submit"} className={isWealthSystemActive ? "wealth-register-button" : "register-button"}>
                  <p>Submit</p>
                  <img src="assets/images/arrow-right.svg" alt="arrow-right" />
                </Button>
              </form>
            </div>
          </div>
          <img onClick={() => { setWhatsAppPopUp(false) }} className='close-register-whatsapp-service' src="assets/images/crossImg.svg" alt="cross image" />
        </div>
      </div>
      {failurePopUpModal ? <FailurePopUp
        onClick={() => setFailurePopUpModal(false)}
        visible={failurePopUpModal}
        setFailureModalStat={setFailurePopUpModal}
        failurePopUpContent={failurePopUpContent}
      /> : null}
    </Modal>
  )
}

WhatsAppRegisterModal.propTypes = {
  setWhatsAppPopUp: PropTypes.func,
  setWhatsAppSuccessPopUp: PropTypes.func
}

export default WhatsAppRegisterModal