import axios from 'axios';
import stalwartApiCall from '../../utilities/stalwartCommonApi';

const getLoanPositionDtls = (data) => {
    let requestJson = data;
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/cdi/get-loan-position-dtls/v1', requestJson).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/cdi/get-loan-position-dtls/v1').then(response => {
            
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getLoanPositionDtls;