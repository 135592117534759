export default {
    "Statement of Account|Account Statement|Statement|Loan Statement": {
        system: ['retail', 'ccod'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: [],
    },
    "Amortization Statement|Repayment Schedule|Loan Amortization|Amort": {
        system: ['retail'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: [],
    },
    "Final IT certificate|Final Certificate|Final Interest Certificate": {
        system: ['retail'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: [],
    },
    "Provisional Certificate|Provisional IT certificate|Provisional Interest Certificate|Document|Letter|Certificate": {
        system: ['retail'],
        product: ["home equity", "loan against property", 'home loan','insurance loan'],
        accountStatus: [],
        sourceSystem: [],
        productFamily: [],
    },

    "Agreement|Annexures|Loan Agreement": {
        system: ['retail'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: ['cfab']
    },
    "No objection certificate|No dues Certificate|NDC|NOC": {
        system: ["retail"],
        product: ["personal loan",
        "business loan",
        "loan against property",
        "insurance loan",
        "consumer durable"],
        accountStatus: ['cancelled', 'discharged', 'closed',""],
        sourceSystem: [],
        productFamily: ['cfab']
    },
    "Welcome Letter|Loan Letter": {
        system: ['retail'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Loan Foreclosure letter|Loan Foreclosure|Foreclosure": {
        system: ['retail'],
        product: ["personal loan", "used car", "car loan", "business loan","two wheeler","consumer durable"],
        accountStatus: ["new account", "partially advanced", "applied for cancellation", "approved", "accepted", "request for termination", "applied for termination", "fully advanced", "active"],
        sourceSystem: [],
        productFamily: ['cfab']
    },
    "Mobile number change|mobile change|contact change|mobile number update": {
        system: ['retail', 'ccod'],
        product: [],
        accountStatus: ["new account", "partially advanced", "applied for cancellation", "approved", "accepted", "request for termination", "applied for termination", "fully advanced", "active"],
        sourceSystem: [],
        productFamily: []
    },
    "Email ID change|Email ID update|Email ID|Email Address|Email": {
        system: ['retail', 'ccod'],
        product: [],
        accountStatus: ["new account", "partially advanced", "applied for cancellation", "approved", "accepted", "request for termination", "applied for termination", "fully advanced", "active"],
        sourceSystem: [],
        productFamily: []
    },
    "Address|communication address": {
        system: ['retail', 'ccod'],
        product: [],
        accountStatus: ["new account", "partially advanced", "applied for cancellation", "approved", "accepted", "request for termination", "applied for termination", "fully advanced", "active"],
        sourceSystem: [],
        productFamily: []
    },
    "TAN|Tax account Number change|updation": {
        system: ['retail'],
        product: [],
        accountStatus: ["new account", "partially advanced", "applied for cancellation", "approved", "accepted", "request for termination", "applied for termination", "fully advanced", "active"],
        sourceSystem: [],
        productFamily: ['cfab']
    },
    "Bill Payment|electricity|water|mobile|postpaid payment|Fastag|Education Fees|Gas|Landline|Broadband|Cylinder|Insurance|DTH|Housing fee|hospital|Cable TV|Clubs|Subscriptions|Recurring Deposit|Taxes": {
        system: [],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Overdue payment|EMI payment|overdue": {
        system: ['retail'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Service request|self service|Customer Service|request": {
        system: ['retail', 'ccod'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Offer|pre-approved offer|loan application|apply for loan": {
        system: [],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Loan details|account details|account information": {
        system: [],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Emi Details|Bank Detail|Banking details|repayment account details|NACH details": {
        system: ['retail', 'ccod'],
        product: [],
        accountStatus: ["new account", "partially advanced", "applied for cancellation", "approved", "accepted", "request for termination", "applied for termination", "fully advanced", "active"],
        sourceSystem: [],
        productFamily: []
    },
    "Charges|Fees|penalty": {
        system: ['retail'],
        product: [],
        accountStatus: [],
        sourceSystem: ['finone'],
        productFamily: []
    },
    "Loan Documents|Document": {
        system: ['retail', 'ccod'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Calculators": {
        system: [],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Credit Score|Credit report": {
        system: [],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "WhatsApp": {
        system: [],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Profile details|Profle|Name": {
        system: [],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Investment|MoneyFy": {
        system: ['retail', 'ccod'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "EMI amount|emi due date|disbursed amount|Sanctioned Amount|Rate of Interest|Tenure": {
        system: ['retail', 'ccod'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Stop|cancel mandate": {
        system: ['retail'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: ['cfab']
    },
    "Date of Birth|DOB|Birth Date": {
        system: ['retail'],
        product: [],
        accountStatus: ["new account", "partially advanced", "applied for cancellation", "approved", "accepted", "request for termination", "applied for termination", "fully advanced", "active"],
        sourceSystem: [],
        productFamily: []
    },
    "NACH change|Bank Account SWAP|Change Bank account details|NACH swap": {
        system: ['retail'],
        product: [],
        accountStatus: ["new account", "partially advanced", "applied for cancellation", "approved", "accepted", "request for termination", "applied for termination", "fully advanced", "active"],
        sourceSystem: [],
        productFamily: ['cfab']
    },
    "Online Disbursal|withdrawal": {
        system: ['ccod'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Payment|online payment|deposit": {
        system: ['ccod'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Overdue Summary|account summary": {
        system: ['ccod'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Disbursal|disbursement|disburse|Drawdown|Draw down": {
        system: ['las'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Payment|re-payment|due|overdue|Pay now": {
        system: ['las'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Account statement|statement of account|SOA": {
        system: ['las'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Holding statement|statement of holding": {
        system: ['las'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Outstanding|principle outstanding|Interest|interest due|due|Charges|other charges|charge|fee|fee|Sanction|Sanction limit|Limit|Loan limit": {
        system: ['las'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Pledging|additional pledging|pledge|lien|incremental pledging": {
        system: ['las'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Depledge|de-pledge|depledging|unlien": {
        system: ['las'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Drawing power|Drawing|Eligible drawing power|Available drawing power|Total drawing power": {
        system: ['las'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    },
    "Current holdings|Holdings": {
        system: ['las'],
        product: [],
        accountStatus: [],
        sourceSystem: [],
        productFamily: []
    }
}