import React, { useContext } from 'react';
import { createContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from '../../global/Button';
import Modal from '../../global/Modal';
import RadiantBorder from '../../global/RadiantBorder';
import { STOREDATA } from '../../context/data-mapping/action/action.type';
import DataMapping from '../../context/data-mapping';
import jsHelper from '../../utilities/jsHelper';
import "./AttachDocument.scss";
import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import PropTypes from 'prop-types';

const AttachDocument = ({ setAttachDoc, filterElements, panAttachDoc, addressAttachDoc, setDigiDocName }) => {
    console.log(filterElements)
    const [data, dispatchData] = useContext(DataMapping);
    const [themeState] = useContext(ThemeContext);

    const currentDocument = (element, value, setDocumentError) => {
        if (value === "on") {
            setDocumentError("")
        }
        setRadioValue(value)
        dispatchData({
            name: "digiDocFile",
            payload: element.name,
            type: STOREDATA
        })
        dispatchData({
            name: "docUri",
            payload: element.uri,
            type: STOREDATA
        })
        dispatchData({
            name: "digiDocData",
            payload: element,
            type: STOREDATA
        })

        var filterMimeData = filterElementAppendFn(element);
        var mimeType = filterMimeData[0].split("/")[1];
        var attachDocName = element.description;
        var digiDocName = attachDocName + "." + mimeType
        setDigiDocName(digiDocName)
    }

    const filterElementAppendFn = (element) => {
        if (jsHelper.isDefined(element)) {
            var digilockerData = element;
            var filterPdf = digilockerData.mime.filter(findpdf);
            return filterPdf
        } else {
            console.log("error pdf")
        }

    }

    const findpdf = (pdf) => {
        return pdf == 'application/pdf'
    }

    const attachDocNotLink = () => {
        if (panAttachDoc) {
            return <p style={{
                color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}`,
            }}>Your PAN is not linked in your digilocker account. Please link and try again</p>
        } else if (addressAttachDoc) {
            return "Your Aadhaar card/Driving license is not linked in your digilocker account. Please link and try again"
        } else {
            return "Your PAN card/driving license/ Aadhar card is not linked in your digilocker account. Please link and try again"
        }
    }

    const [documentError, setDocumentError] = useState("")
    const [radioValue, setRadioValue] = useState("")
    const validateDoc = () => {
        if (radioValue === "on") {
            setAttachDoc(false)
        } else {
            setDocumentError("Please Attach Document");
        }
    }

    return (
        <Modal>
            <div className="attachDocument-maincontainer">
                {/* <RadiantBorder> */}
                <div className="attachDocument-container" style={{
                    background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
                }}>
                    <div className="attachDocument-heading-container">
                        <div className="document-heading">
                            <img src="assets/images/backArrowBlack.svg" alt="backArrow" onClick={() => setAttachDoc(false)} className="documentBackArrow" style={{ filter: `${themeState === "THEME_DARK" ? "invert(1)" : ""}` }} />
                            <span style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}` }} className="heading">Attach Document</span>
                        </div>
                        <div>
                            <img src="assets/images/cancel.svg" onClick={() => setAttachDoc(false)}
                                className="documentCancelImg" />
                        </div>
                    </div>
                    {
                        filterElements.length !== 0 ?
                            <div className="input-field-container">
                                {
                                    filterElements.map(element => {
                                        console.log(element.name);
                                        return (
                                            <>
                                                <div className="radio-field">
                                                    <div>
                                                        <input type="radio" name="document" onChange={(event) => { currentDocument(element, event.target.value, setDocumentError) }} />
                                                    </div>
                                                    <p style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}` }} className="document-option">{element.name}</p>
                                                </div>

                                            </>
                                        )
                                    })
                                }
                            </div> : <div className="input-field-container">
                                {
                                    attachDocNotLink()
                                }
                            </div>
                    }
                    {documentError ? <p className='documentSelectError' style={{ color: "red" }}>{documentError}</p> : null}
                    <div >
                        {
                            filterElements.length !== 0 ? <Button className="attachDocumentbtn" onClick={() => validateDoc()}>
                                <img src="assets/images/attach-document.svg" />
                                <p>Attach Document</p></Button> : <Button className="attachDocumentbtn" >
                                <p>OK</p></Button>
                        }

                    </div>
                </div>
                {/* </RadiantBorder> */}
            </div>
        </Modal>
    )
}

AttachDocument.propTypes = {
    setAttachDoc: PropTypes.func,
    filterElements: PropTypes.array,
    panAttachDoc: PropTypes.string,
    digiDocName: PropTypes.string,
    setDigiDocName: PropTypes.func
}
export default AttachDocument;