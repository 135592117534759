import React, { useState, useContext } from "react";
import Modal from "../../global/Modal";
import './OkButtonPopUp.scss';
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import Button from "../../global/Button";
import PropTypes from 'prop-types';
import DataMapping from "../../context/data-mapping";

const OkButtonPopUp = ({ onClick, setModalStat, Content, Content2, button2=false, onClick2, onClick3 }) => {
    const [themeState] = useContext(ThemeContext);
  const [data, dispatchData] = useContext(DataMapping)


    return (
        <>
            <Modal style={{ background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""}`, }}>
                <div className="okButton-pop-up" style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`, }}>
                    <div className="sr-pop-up-close-btn" onClick={onClick}>
                        <img src={"assets/images/crossImg.svg"} alt="close" />
                    </div>
                    <div className="sr-pop-up-description">
                        <p style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}`, }}>
                            {Content}
                        </p>
                        {Content2 &&
                            <p className="confirmation-content2" style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}`, }}>
                                {Content2}
                            </p>
                        }
                    </div>
                    {button2 ?
                        <div className="confirmation-btns-group ">
                            <Button className="confirmation-btn-yes popup-button" onClick={onClick2}>Yes</Button>
                            <Button className="confirmation-btn-no popup-button" onClick={onClick3}>No</Button>
                        </div> :
                        <div className="sr-pop-up-proceed ">
                            <Button className={data?.currentActiveData?.system?.toLowerCase() === "wealth" ? "popup-button-wealth": "popup-button"} onClick={onClick}>OK</Button>
                        </div>

                    }
                </div>
            </Modal>
        </>
    )
}

OkButtonPopUp.propTypes = {
    Content: PropTypes.string,
    onClick: PropTypes.func
}

export default OkButtonPopUp