import React, { useCallback, useEffect, useRef, useState } from "react";
import TimeOutPopUp from "../TimeOutPopUp/TimeOutPopUp";

const Timer = () => {
  const [timer, setTimer] = useState("15:00");
  const Ref = useRef(null);
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      let timer =
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds);
      if (timer === "05:00") {
        setTimeout(() => {
          setTimer(timer);
        }, 100);
      } else {
        setTimer(timer);
      }
    }
  };

  //   function startTimer() {
  //     // clearTimer(getDeadTime());
  //     setTimer("01:00");
  //   }

  const memorizedTimer = useCallback(function clearTimer() {
    let e = getDeadTime();
    // console.log("Timer present");

    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      // console.log("eeee", e);
      startTimer(e);
    }, 1000);
    Ref.current = id;
    setTimer("15:00");
  }, []);

  //   function clearTimer() {
  //     let e = getDeadTime();
  //     console.log("Timer present");

  //     // If you adjust it you should also need to
  //     // adjust the Endtime formula we are about
  //     // to code next
  //     setTimer("01:00");

  //     // If you try to remove this line the
  //     // updating of timer Variable will be
  //     // after 1000ms or 1sec
  //     if (Ref.current) clearInterval(Ref.current);
  //     const id = setInterval(() => {
  //       startTimer(e);
  //     }, 1000);
  //     Ref.current = id;
  //   }

  function getDeadTime() {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 900);
    return deadline;
  }

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only

  useEffect(() => {
    // console.log(timer)
    if (timer === "05:00") {
      Object.values(events).forEach((item) => {
        document.removeEventListener(item, memorizedTimer, true);
      });
    }
  }, [timer]);

  useEffect(() => {
    // clearTimer(getDeadTime());
    Object.values(events).forEach((item) => {
      document.addEventListener(item, memorizedTimer, true);
    });
    return () => {
      Object.values(events).forEach((item) => {
        document.removeEventListener(item, memorizedTimer, true);
      });
    };
  }, []);

  //   const removeEvent = () => {
  //   Object.values(events).forEach((item) => {
  //     document.removeEventListener(item, startTimer);
  //   });
  //   };

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    // clearTimer(getDeadTime());
    memorizedTimer();
    Object.values(events).forEach((item) => {
      document.addEventListener(item, memorizedTimer, true);
    });
  };
  return (
    <>
      {timer < "05:00" ? (
        <TimeOutPopUp reset={onClickReset} timer={timer} />
      ) : null}
    </>
  );
};

export default Timer;
