import React, { useState } from "react";

//Use Context
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import { THEME_LIGHT } from "../../context/ThemeContext/actions";
import detect from "detect.js";
//CSS
import "./FullScreen.scss";

const FullScreen = () => {
  const [toogleScreen, setToggleScreen] = useState(true);
  var elem = document.documentElement;
  const user = detect.parse(navigator.userAgent);
  const browser = user.browser.family.split(" ")[0];
  const screenResize = () => {
    setToggleScreen(!toogleScreen);
    if (toogleScreen && browser === "Chrome") {
      elem.requestFullscreen();
    } else if (toogleScreen && browser === "Safari") {
      elem.webkitRequestFullscreen();
    } else if (toogleScreen && browser === "Firefox") {
      elem.mozRequestFullScreen();
    } else if (!toogleScreen && browser === "Chrome") {
      document.exitFullscreen();
    } else if (!toogleScreen && browser === "Safari") {
      document.webkitExitFullscreen();
    } else if (toogleScreen && browser === "Firefox") {
      document.mozCancelFullScreen();
    }
  };

  const [themeState, dispatch] = useContext(ThemeContext);
  return (
    <div className="fullScreen-container" onClick={screenResize}>
      {themeState === THEME_LIGHT ? (
        <img src={"assets/images/darkFullScreen.svg"} alt="darkFullScreen" />
      ) : (
        <img src={"assets/images/lightFullScreen.svg"} alt="lightFullScreen" />
      )}
    </div>
  );
};

export default FullScreen;
