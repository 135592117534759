import React from "react";
import "./ToolsAndCalcModal.scss";
import Modal from "../../global/Modal/Modal";
// import LoanTypeComponent from "../LoanTypeComponent/LoanTypeComponent";
import RadiantBorder from "../../global/RadiantBorder/RadiantBorder";
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import ToolsAndCalcLoantype from "../ToolsAndCalcLoantype/ToolsAndCalcLoantype";
import DataMapping from "../../context/data-mapping";
import PropTypes from 'prop-types';
const ToolsAndCalcModal = ({ setCalculatorModal }) => {
  const [themeState] = useContext(ThemeContext);
  const [contextData, dispatchData] = useContext(DataMapping);

  // const handleLoanTypeClick = (label) => {
  //   // console.log(e.target.childNodes[1].childNodes[0]);
  //   console.log(label);
  //   // 
  //   switch (label) {
  //     case "Personal Loan":
  //       window.open('https://www.tatacapital.com/personal-loan.html#toolsAndCalc')
  //       // window.open('https://www.google.com/')
  //       break;

  //     case "Home Loan":
  //       window.open('https://www.tatacapital.com/home-loan.html#toolsAndCalc')
  //       break;

  //     case "Business Loan":
  //       window.open('https://www.tatacapital.com/business-loan/business-loan-emi-calculator.html')
  //       break;

  //     case "Used Car Loan":
  //       window.open('#');
  //       break;

  //     default:
  //       window.open('https://www.google.com/');
  //       break;
  //   }
  // }
  return (
    <Modal>
      <div
        style={{
          background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`,
        }}
        className="toolncalc-loan-type-container"
      >
        <img
          onClick={() => setCalculatorModal(false)}
          src="assets/images/crossImg.svg"
          alt="close-icon"
          className="loan-type-cross-icon"
        />
        <div className="loan-type-header-container">
          <h2
            style={{
              color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
            }}
            className="capitalizedAphabets"
          >
            Hi {contextData?.customerName.toLowerCase()},
          </h2>
          <p
            style={{
              color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
            }}
          >
            Select a product and use EMI Calculator
          </p>
        </div>
        <div className={`loan-type-component-main-container ${themeState === 'THEME_DARK' ? 'loan-type-component-main-container-dark' : ''}`}>

          <div className="loan-type-flex-container">

            <RadiantBorder className="loan-type-radiant-container">
              <ToolsAndCalcLoantype
                label={"Personal Loan"}
                imageName={`${themeState === "THEME_DARK"
                  ? "personalLoanDark.svg"
                  : "personalLoanLight.svg"
                  }`}
              />
            </RadiantBorder>
            <RadiantBorder className="loan-type-radiant-container">
              <ToolsAndCalcLoantype
                label={"Home Loan"}
                imageName={`${themeState === "THEME_DARK"
                  ? "homeLoanDarkIcon.svg"
                  : "homeLoanLightIcon.svg"
                  }`}
              />
            </RadiantBorder>
            <RadiantBorder className="loan-type-radiant-container">
              <ToolsAndCalcLoantype
                label={"Business Loan"}
                imageName={`${themeState === "THEME_DARK"
                  ? "businessLoanDark.svg"
                  : "businessLoanLight.svg"
                  }`}
              />
            </RadiantBorder>
            <RadiantBorder className="loan-type-radiant-container">
              <ToolsAndCalcLoantype
                label={"Loan Against Property"}
                imageName={`${themeState === "THEME_DARK"
                  ? "loan-against-property-dark.svg"
                  : "loan-against-property.svg"
                  }`}
              />
            </RadiantBorder>
            <RadiantBorder className="loan-type-radiant-container">
              <ToolsAndCalcLoantype
                label={"Loan Against Securities"}
                imageName={`${themeState === "THEME_DARK"
                  ? "loan-against-security-dark.svg"
                  : "loan-against-security.svg"
                  }`}
              />
            </RadiantBorder>

          </div>
        </div>
      </div>
    </Modal>
  );
};

ToolsAndCalcModal.propTypes = {
  setCalculatorModal: PropTypes.func
}

export default ToolsAndCalcModal;

