import React from "react";
import Modal from "../../global/Modal";
import "./SuccessPopUp.scss";
import PropTypes from 'prop-types';

const SuccessPopUp = ({onClick, setSuccessModalStat, successPopUpContent , successPopUpContent2}) => {
    return (
        <>
            <Modal>
                <div className="success-container">
                    <div className="display-success-popUp">
                        <div className="success-img">
                            <img src="assets/images/success-tick.svg" alt="" />
                        </div>
                        <div className="success-popUp-content">
                            <div className="success-popUp-header">
                                <p>Success</p>
                                <div className="success-close-icon" onClick={onClick}>
                                    <img src="assets/images/green-cross.svg" alt="" />
                                </div>
                            </div>
                            <div className="success-popUp-message">
                                <p>{successPopUpContent}</p>
                                {
                                    successPopUpContent2 ?
                                    <p>{successPopUpContent2}</p>
                                    : null
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    )
}

SuccessPopUp.propTypes = {
    onClick : PropTypes.func,
    successPopUpContent : PropTypes.string
  }

export default SuccessPopUp