import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const clientProfileDetails = (data) => {

    let request = {
        "header": {
            "authToken": "ajdakjdbakjsdkadkdabdkakdas"
        },
        "body": {
            "userCode": "3934",
            "excludeHeldAway": "Y"
        }
    }

    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, '/shaft/api/wealth/get-client-profile-details/v1').then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error);
        })
    });
}

export default clientProfileDetails;