import React from 'react'
import "./TableComponent.scss"
import PropTypes from 'prop-types';

const TableComponent = ({ className, tabelHeading, tableData }) => {

    const dataFlag = tableData.length === 0 ? false : true;
    const getKeys = function () {
        return Object.keys(tableData[0]);
    }

    const getRowsData = function () {
        var items = tableData;
        var keys = tableData[0] && getKeys();
        return items.map((row, index) => {
            console.log(row);
            return <tr key={index}>{keys && <RenderRow key={index} data={row} keys={keys} />}</tr>
        })
    }

    const RenderRow = ({ data, keys }) => {
        return keys.map((key, index) => {
            return <td key={data[key]}>{data[key]}</td>
        })
    }
    console.log("äc", tabelHeading);
    console.log(tableData);
    return (
        <div className={className ? className : ""}>
            {dataFlag ? <table>
                <thead>
                    <tr>
                        {
                            tabelHeading.map(value => <th>{value}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {getRowsData()}
                </tbody>

            </table> : <div>
                <table>
                    <thead>
                        <tr>
                            {
                                tabelHeading.map(value => <th>{value}</th>)
                            }
                        </tr>
                    </thead>
                </table>
                <div className='noDataFound'>
                    <p>No Data Found</p>
                </div>

            </div>
            }

        </div>
    )
}
TableComponent.propTypes = {
    className : PropTypes.string,
    tabelHeading : PropTypes.string,
    tableData : PropTypes.array,
  }

export default TableComponent