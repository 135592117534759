const Validators = {
  required: (value) => {
    if (value !== null && (value?.length > 0 || value)) {
      return null;
    } else {
      return { required: "Value is required" };
    }
  },
  pattern: (regEx) => {
    return (value) => {
      return regEx.test(value) ? null : { pattern: "Not valid pattern" };
    };
  },
  name: (value) => {
    let name = /^([a-zA-Z]'{0,1})+ ([a-zA-Z]'{0,1})+ ?([a-zA-Z]'{0,1})+$/;
    // let name = /^([a-zA-z]{1,})\s[a-zA-Z]{1,}(\s{1})?([a-zA-Z]{1,})?\s{0}$/;
    // let name = /^[\w',.-][^0-9_!¡?÷?¿\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
    if (name.test(value)) {
      const [first, middle, last] = value.toLowerCase().split(" ");

      if (first !== middle && first !== last && middle !== last) {
        if (first?.length > 20 || last?.length > 20 || middle?.length > 20) {
          return { name: "Name is not valid" };
        } else {
          return null;
        }
      } else {
        return { name: "Name is not valid" };
      }
    } else {
      return { name: "Name is not valid" };
    }
  },
  mail: (value) => {
    // let emailRegEx =
    //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let newEmailRegex = /\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}/g;
    return newEmailRegex.test(value)
      ? null
      : { mail: "Entered mail is not valid" };
  },
  min: (minValue) => {
    return (value) => {
      let parsedValue = value ? parseInt(value?.replaceAll(",", "")) : "";
      return parsedValue < minValue
        ? {
          min: `Minimum value is ${minValue}`,
          minValue: minValue,
        }
        : null;
    };
  },
  max: (maxValue) => {
    return (value) => {
      let parsedValue = value ? parseInt(value?.replaceAll(",", "")) : "";
      return parsedValue > maxValue
        ? {
          max: `Maxmium value is ${maxValue}`,
          maxValue: maxValue,
        }
        : null;
    };
  },
};

export default Validators;
