import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import ThemeProvider from "./context/ThemeContext/store/ThemeProvider";
import { HashRouter } from "react-router-dom";
import PageNameProvider from "./context/ThemeContext/store/PageNameProvider";
import AppLogout from "./components/AppLogout/AppLogout";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider>
    <PageNameProvider>
      <HashRouter>
        {/* <AppLogout> */}
        <App />
        {/* </AppLogout> */}
      </HashRouter>
    </PageNameProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
