import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./Modal.css";
import PropTypes from 'prop-types';

const Modal = ({ children, onClick, style }) => {

  useEffect(() =>{
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    }
  })

  return ReactDOM.createPortal(
    <div onClick={onClick} className="modal" style={{ ...style }}>
      {children}
    </div>,
    document.getElementById("modal")
  );
};



Modal.propTypes = {
  children :PropTypes.node, 
  onClick : PropTypes.func, 
  style : PropTypes.object
}

export default Modal;
