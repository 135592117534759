import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getOffers = (data) => {

    let requestJson = {
        "header": {
            "header": {
                "authToken": "e5cda0908413c3911ad8e00b94f6b3ddca6b5872a383b0e10349379b23c03483"
            },
            "body": {
                "mobileNo": "9594779789"
            }
        }
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/sfdc/get-offers/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/sfdc/get-offers/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getOffers;