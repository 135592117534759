import stalwartApiCall from "../../utilities/stalwartCommonApi";

const TemporaryBillabelAmount = (data) => {
  
    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, '/shaft/api/ccod/get-temporary-billable-amount/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    })
}

export default TemporaryBillabelAmount;