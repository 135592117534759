import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import commonFunctionObject from "../../common-function";
import DataMapping from "../../context/data-mapping";
import { STOREDATA } from "../../context/data-mapping/action/action.type";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import LogoutServiceObject from "../../services/LogoutService";
import Modal from "../Modal";
import "./QualitricsPopUp.scss";
import PropTypes from 'prop-types';
import AnalyticsContextProvider from "../../context/AnalyticsContext/AnalyticsContextProvider";
import Button from "../../global/Button";
import RadiantBorder from "../RadiantBorder";

const QualitricsPopUp = ({ logOutApiCall }) => {
    const [contextData, dispathData] = useContext(DataMapping)
    const location = useLocation()
    const [themeState] = useContext(ThemeContext);
    const navigate = useNavigate()
    const { analytics } = useContext(AnalyticsContextProvider);

    const qualitricsApiCall = () => {
        var accountNumber = contextData?.currentActiveData?.accountNumber;
        var currentUserData = contextData?.parsedUserData[accountNumber];
        let productName = contextData?.currentActiveData?.system === "las" ? "LAS" : contextData?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
        let analytics_data = {
            user: {
                loginMethod: "",
                loginStatus: "",
                gcid: contextData?.getUserDataV3?.body?.PRIMARY_GCID,
                loginType: "",
                loanaccountNo: "",
                firstloginDate: ""
            },
            products: {
                productCode: productName,
            },
            data: {
                ctaText: "Click here",
                eventName: "click here to rate experience",
                entityType:"",
                componentName: "QualitricsPopUp",
                offerId: "",
                bannerTitle: "",
                menuTitle: "",
                faqTitle: "",
                searchTerm: "",
                lobActivity: "",
                status: "",
            }
        }

        
        var userData = contextData.getUserDataV3.body
        var qualObj = qualitricsCall(userData);
        var reqObj = {
            "header": {
                "authToken": contextData.authToken
            },
            "body": {
                "userId": userData.USER_ID ? userData.USER_ID : "",
                "lan": qualObj.lan,
                "entity": qualObj.entity, /*entity is nothing but product family of contract. TCHFL or CFAB */
                "accountStatus": qualObj.accountStatus, /*If close account then closed*/
                "customerName": qualObj.customerName,
                "emailId": qualObj.emailId,
                "mobileNo": qualObj.mobileNo
            }
        }
        analytics_data.data.entityType = qualObj.entity;
        analytics.trackData("clicktorateExperience", analytics_data);
        dispathData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        LogoutServiceObject.qualitricsService(reqObj).then((finalUrl) => {
            window.open(finalUrl);
            logOutApiCall();
            navigate("/login")
        }).catch((error) => {
            logOutApiCall();
            navigate("/login")

        })
    }



    const qualitricsCall = (userData) => {

        var openContracts = contextData.openContracts
        var lasopenContracts = contextData.lasContracts
        var closeContracts = contextData.closeContracts
        var ccodContracts = contextData.ccodContracts
        var userEmail = "";
        var userMobile = "";
        if (userData.emails) {
            userEmail = commonFunctionObject.getLastestFieldValFromField(userData.emails);
        }
        if (userData.mobiles) {
            userMobile = commonFunctionObject.getLastestFieldValFromField(userData.mobiles);
        }
        var date = commonFunctionObject.convertDDMMYYYY(new Date());
        var newDate = date.replace(/\//g, '-');
        var path = location.search.split('/');
        var pageName = path[path.length - 1];
        var pagePath = pageName.replace(/-/g, '').split('.');
        var JourneyType = pagePath[0];
        var qulitricsDetails
        if ((openContracts && Object.keys(openContracts).length !== 0) && (lasopenContracts) && Object.keys(lasopenContracts).length !== 0) {
            qulitricsDetails = {
                "lan": Object.keys(openContracts)[0],
                "entity": commonFunctionObject.getProductFamily(contextData?.parsedUserData[Object.keys(openContracts)[0]]).toLowerCase() === "tchfl" ? "TCHFL" : "TCL",
                "accountStatus": Object.keys(openContracts).length > 0 ? 'open' : 'closed',
                "customerName": (userData.FIRST_NAME && userData.LAST_NAME) ? userData.FIRST_NAME + " " + userData.LAST_NAME : '',
                "emailId": userEmail ? userEmail : '',
                "mobileNo": userMobile ? userMobile : '',
                "JourneyType": JourneyType,
                "Date": newDate,             //in dd-mm-yyyy format
                "sourceType": "web"
            }

        } else if (openContracts && Object.keys(openContracts).length !== 0) {
            qulitricsDetails = {
                "lan": Object.keys(openContracts)[0],
                "entity": commonFunctionObject.getProductFamily(contextData?.parsedUserData[Object.keys(openContracts)[0]]).toLowerCase() === "tchfl" ? "TCHFL" : "TCL",
                "accountStatus": Object.keys(openContracts).length > 0 ? 'open' : 'closed',
                "customerName": (userData.FIRST_NAME && userData.LAST_NAME) ? userData.FIRST_NAME + " " + userData.LAST_NAME : '',
                "emailId": userEmail ? userEmail : '',
                "mobileNo": userMobile ? userMobile : '',
                "JourneyType": JourneyType,
                "Date": newDate,             //in dd-mm-yyyy format
                "sourceType": "web"
            }
            /* qualitrics.setQualitricsData(qulitricsDetails, true); */

        } else if (lasopenContracts && closeContracts) {
            qulitricsDetails = {
                "lan": Object.keys(lasopenContracts)[0],
                "entity": 'TCLAS',
                "accountStatus":/*  "open/closed" */ lasopenContracts.length > 0 ? 'open' : 'closed',
                "customerName": (userData.FIRST_NAME && userData.LAST_NAME) ? userData.FIRST_NAME + " " + userData.LAST_NAME : '',
                "emailId": userEmail ? userEmail : '',
                "mobileNo": userMobile ? userMobile : '',
                "JourneyType": JourneyType,
                "Date": newDate,             //in dd-mm-yyyy format
                "sourceType": "web"
            }
        }
        else if (lasopenContracts && Object.keys(lasopenContracts).length !== 0) {
            qulitricsDetails = {
                "lan": Object.keys(lasopenContracts)[0],
                "entity": 'TCLAS',
                "accountStatus": Object.keys(lasopenContracts).length > 0 ? 'open' : 'closed',
                "customerName": (userData.FIRST_NAME && userData.LAST_NAME) ? userData.FIRST_NAME + " " + userData.LAST_NAME : '',
                "emailId": userEmail ? userEmail : '',
                "mobileNo": userMobile ? userMobile : '',
                "JourneyType": JourneyType,
                "Date": newDate,             //in dd-mm-yyyy format
                "sourceType": "web"
            }
        }
        else if (closeContracts && Object.keys(closeContracts).length !== 0) {
            qulitricsDetails = {
                "lan": Object.keys(closeContracts)[0],
                "entity": commonFunctionObject.getProductFamily(contextData?.parsedUserData[Object.keys(closeContracts)[0]]).toLowerCase() === "tchfl" ? "TCHFL" : "TCL",
                "accountStatus":/*  "open/closed" */ closeContracts.length > 0 ? 'open' : 'closed',
                "customerName": (userData.FIRST_NAME && userData.LAST_NAME) ? userData.FIRST_NAME + " " + userData.LAST_NAME : '',
                "emailId": userEmail ? userEmail : '',
                "mobileNo": userMobile ? userMobile : '',
                "JourneyType": JourneyType,
                "Date": newDate,             //in dd-mm-yyyy format
                "sourceType": "web"
            }
        } else if (ccodContracts && Object.keys(ccodContracts).length !== 0) {
            qulitricsDetails = {
                "lan": contextData.parsedUserData[Object.keys(ccodContracts)[0]].sapContract.CUSTOMER_NO,
                "entity": 'CFAB',
                "accountStatus": 'open',
                "customerName": (contextData.parsedUserData[Object.keys(ccodContracts)[0]].cdiContract.CUSTOMER_NAME) ? contextData.parsedUserData[Object.keys(ccodContracts)[0]].cdiContract.CUSTOMER_NAME : "",
                "emailId": contextData.parsedUserData[Object.keys(ccodContracts)[0]].cdiContract.EMAIL ? contextData.parsedUserData[Object.keys(ccodContracts)[0]].cdiContract.EMAIL : '',
                "mobileNo": contextData.parsedUserData[Object.keys(ccodContracts)[0]].cdiContract.MOBILE ? contextData.parsedUserData[Object.keys(ccodContracts)[0]].cdiContract.MOBILE : '',
                "JourneyType": JourneyType,
                "Date": newDate,             //in dd-mm-yyyy format
                "sourceType": "web"
            }
        }

        return qulitricsDetails;
    }

    return (
        <>
            <Modal>
                <div className='qualitrics-modal-popup' onClick={qualitricsApiCall}> 
                    <RadiantBorder>
                        <div className='qualitrics-popup-border' style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : "#fff"}`, color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}  >
                            <div className='close-btn'>
                                <img  onClick={(e) => { e.stopPropagation(); logOutApiCall() }} src={themeState === "THEME_DARK" ?   "assets/images/crossImg.svg" : "assets/images/qualtrics-close-icon.svg"}  alt="close" />
                            </div>
                            <div className="rate-experience-container">
                                <div className='rate-experience'>
                                    <p 
                                    style={{
                                        color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                    }}
                                    >
                                    We value your opinion.! 
                                    </p>
                                </div>
                                <div className='qualtrics-description'>
                                    <p 
                                    style={{
                                        color: `${themeState === "THEME_DARK" ? "#fff" : ""}`,
                                    }}
                                    >
                                        Take a moment to let us know how  we're doing
                                        {/* Hello, we would love to hear from you! <span className="clickHereQual" onClick={qualitricsApiCall}>Click here</span> to rate your Web Experience */}
                                    </p>
                                </div>
                                <div className="experience-emojis">
                                    <img src={"assets/images/neutral.gif"} alt="neutral-emoji" />
                                    <img src={"assets/images/good.gif"} alt="good-emoji" />
                                    <img src={"assets/images/great.gif"} alt="great-emoji" />

                                </div>
                                <div className="qualtrics-button">
                                <Button>Click Here</Button>
                                </div>
                            </div>
                        </div>
                    </RadiantBorder>
                </div>
            </Modal>
        </>
    )
}

QualitricsPopUp.propTypes = {
    logOutApiCall: PropTypes.func,

}

export default QualitricsPopUp