import getAssetDetails from "../../apis/asset-details/asset-details";
import generateSoaReport from "../../apis/create-soa-report/create-soa-report";
import createSoaReport from "../../apis/create-soa/create-soa";
import getBoGetUrl from "../../apis/bo-get-url/bo-get-url";
import getGenerateSoaReport from "../../apis/generate-soa-report/generate-soa-report";
import getSplitCharges from "../../apis/get-split-charges/get-split-charges";
import repaymentSchedule from '../../apis/generate-repay-schedule'
import commonFunctionObject from "../../common-function";
import generateInterestCertificate from '../../apis/generate-interest-certificate'
import jsHelper from "../../utilities/jsHelper";
import { useState } from "react";


const AccountDetailsServicObject = {};
const splitChargesApiService = (accountNumber, authToken) => {
  return new Promise((resolve, reject) => {

    const requestObj = {
      "header": {
        "authToken": authToken
      },
      "body": {
        "loanAcctNo": accountNumber
      }
    }
    getSplitCharges(requestObj).then((response) => {

      if (response.header.status.toLowerCase() === "success") {
        if (response.body.retStatus.toLowerCase() === "success") {
          var chargesData = {
            "Instrument Return Charges": {
              "data": [],
              "totalDue": "",
              "totalPaid": "",
              "outStanding": ""
            },
            "LPP Charges": {
              "data": [],
              "totalDue": "",
              "totalPaid": "",
              "outStanding": ""
            },
            "Other Charges": {
              "data": [],
              "totalDue": "",
              "totalPaid": "",
              "outStanding": ""
            }
          };

          var chargeCodeObj = {
            "Instrument Return Charges": [8],
            "LPP Charges": [7],
            "Other Charges": [841, 265, 612, 35, 563, 608, 827, 828, 660, 609]
          };
          var data = response.body.response;
          var instrumentReturnCharges = data.filter(function (element) {
            return chargeCodeObj["Instrument Return Charges"].includes(Number(element.chargeCode))
          });

          var filteredInstrumentData = instrumentReturnCharges.filter(function (ele) {
            return ele.chargeDescription !== "Instrument return TCHFL";
          })

          var instrumentReturnChargesData = filteredInstrumentData.map(function (element) {
            element["adviceDate"] = convertDDMMYYYY(new Date(commonFunctionObject.replaceHyphenDate(element["adviceDate"].slice(0, 10))));

            element["outstanding"] = (element["adviceAmt"] - element["allocatedAmt"]).toFixed(2);
            return element;
          })
          chargesData["Instrument Return Charges"].data = sortSplitChargesDate(instrumentReturnChargesData);
          chargesData["Instrument Return Charges"].totalDue = getSumValue(filteredInstrumentData, "adviceAmt");
          chargesData["Instrument Return Charges"].totalPaid = getSumValue(filteredInstrumentData, "allocatedAmt");
          chargesData["Instrument Return Charges"].outStanding = chargesData["Instrument Return Charges"].totalDue - chargesData["Instrument Return Charges"].totalPaid;

          var lppReturnCharges = data.filter(function (element) {
            return chargeCodeObj["LPP Charges"].includes(Number(element.chargeCode))
          });
          var lppReturnChargesChargesData = lppReturnCharges.map(function (element) {
            element["adviceDate"] = convertDDMMYYYY(new Date(commonFunctionObject.replaceHyphenDate(element["adviceDate"].slice(0, 10))));

            element["outstanding"] = (element["adviceAmt"] - element["allocatedAmt"]).toFixed(2);
            return element;
          })
          chargesData["LPP Charges"].data = sortSplitChargesDate(lppReturnChargesChargesData);
          chargesData["LPP Charges"].totalDue = getSumValue(lppReturnCharges, "adviceAmt");
          chargesData["LPP Charges"].totalPaid = getSumValue(lppReturnCharges, "allocatedAmt");
          chargesData["LPP Charges"].outStanding = chargesData["LPP Charges"].totalDue - chargesData["LPP Charges"].totalPaid;

          var otherCharges = data.filter(function (element) {
            return chargeCodeObj["Other Charges"].includes(Number(element.chargeCode));
          });

          //Hide Mediclaim Insurance (609) form otherCharges 

          var newOtherCharges = otherCharges.filter(function (ele) {
            return ele.chargeCodeDescription !== "Mediclaim Insurance (609)";
          })

          //Hide Life Insurance Premium HDFC Life form newOtherCharges

          var filterLifeInsurancePremium = newOtherCharges.filter(function (ele) {
            return ele.chargeCodeDescription !== "Life Insurance Premium HDFC Life";
          })

          var otherChargesChargesData = filterLifeInsurancePremium.map(function (element) {
            element["adviceDate"] = convertDDMMYYYY(new Date(commonFunctionObject.replaceHyphenDate(element["adviceDate"].slice(0, 10))));

            element["outstanding"] = (element["adviceAmt"] - element["allocatedAmt"]).toFixed(2);
            return element;
          })
          chargesData["Other Charges"].data = sortSplitChargesDate(otherChargesChargesData);
          chargesData["Other Charges"].totalDue = getSumValue(filterLifeInsurancePremium, "adviceAmt");
          chargesData["Other Charges"].totalPaid = getSumValue(filterLifeInsurancePremium, "allocatedAmt");
          chargesData["Other Charges"].outStanding = chargesData["Other Charges"].totalDue - chargesData["Other Charges"].totalPaid;
          resolve(chargesData)
        } else {
          //   serviceError = {
          //     "type": "api-failure: get-split-charges/v1",
          //     "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
          //     "code": "E-2017-B",
          //     "errorId": "199"
          //   }
          //   displayErrorModal(serviceError);
          //   $('body').removeClass('loader');
          reject("199")
        }
      } else {
        // serviceError = {
        //   "type": "api-failure: get-split-charges/v1",
        //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
        //   "code": "E-2017-A",
        //   "errorId": "198"
        // }
        // displayErrorModal(serviceError);
        // $('body').removeClass('loader');
        reject("198")
      }
    }).catch(() => {
      reject("split charges servise break")
    })
  })
}
AccountDetailsServicObject.splitChargesApiService = splitChargesApiService


function getSumValue(arr, key) {
  var sum = 0;
  arr.forEach(function (value) { sum += Number(value[key]) });
  return sum;
}


function sortSplitChargesDate(records) {
  return [].concat(records).sort(function (record1, record2) {
    var date1 = new Date(commonFunctionObject.replaceHyphenDate(convertMMDDYY(record1["adviceDate"])));
    var date2 = new Date(commonFunctionObject.replaceHyphenDate(convertMMDDYY(record2["adviceDate"])));
    if (date1.getTime() === date2.getTime()) {
      return 0;
    } else if (date1.getTime() > date2.getTime()) {
      return -1;
    } else {
      return 1;
    }
  });
}

function convertMMDDYY(date) {
  return date.split('/')[1] + '/' + date.split('/')[0] + '/' + date.split('/')[2];
}

function convertDDMMYYYY(date) {
  var month = ((date.getMonth() + 1) < 10) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
  var currentDate = ((date.getDate()) < 10) ? ('0' + (date.getDate())) : (date.getDate());
  return currentDate + '/' + month + '/' + date.getFullYear();
}

const assetDetailsService = (accountNumber) => {
  return new Promise((resolve, reject) => {
    const requestObj = {
      "header": {
        "authToken": "f027ad97b2a1b700a27ff0dab5c8eb0ca57ab063cabb46736b72a20c531b8198"
      },
      "body": {
        "contractNo": accountNumber
      }
    }
    getAssetDetails(requestObj).then((response) => {

      if (response.header.status.toUpperCase() === 'SUCCESS') {
        if (response.body.retStatus === 'SUCCESS') {
          var responseData = response.body.Response[0];
          const assetsDetailsObj = {
            "assetmodel": (responseData?.assetmodel && responseData?.assetmodel.trim() !== "") ? responseData.assetmodel : "-",
            "assetvariant": (responseData?.assetvariant && responseData?.assetvariant.trim() !== "") ? responseData.assetvariant : "-",
            "manufacturer": (responseData?.manufacturer && responseData?.manufacturer.trim() !== "") ? responseData.manufacturer : "-",
            "chasisnumber": (responseData?.chasisnumber && responseData?.chasisnumber.trim() !== "") ? responseData.chasisnumber : "-",
            "registrationnumber": (responseData?.registrationnumber && responseData?.registrationnumber.trim() !== "") ? responseData.registrationnumber : "-",
            "enginenumber": (responseData?.enginenumber && responseData.enginenumber?.trim() !== "") ? responseData.enginenumber : "-",
            // "isClosed": assetsDetailsAccountNumberArr[index].isClose
          }
          resolve(assetsDetailsObj)
        }
      } else if (response.header.status.toUpperCase() === 'FAILURE') {
        if (response?.errorBody) {
          if (response?.errorBody?.statusCode == "-1") {
            // serviceError = {
            //   "type": "api-failure: assets-details",
            //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
            //   "code": "E-2010-B",
            //   "errorId": "207"
            // }
            // displayErrorModal(serviceError);
            reject("207")
          } else {
            // serviceError = {
            //   "type": "api-failure: assets-details",
            //   "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
            //   "code": "E-2010-C",
            //   "errorId": "208"
            // }
            // displayErrorModal(serviceError);
            reject("208")
          }
        } else {
          //   serviceError = {
          //     "type": "api-failure: assets-details",
          //     "message": "We Are Experiencing Technical Difficulty. Please Try After Sometime..",
          //     "code": "E-2010-C",
          //     "errorId": "208"
          //   }
          //   displayErrorModal(serviceError);
          reject("208")
        }
      }

    }).catch((error) => {
      reject("asset Details service breake")
    })
  })

}
AccountDetailsServicObject.assetDetailsService = assetDetailsService


const getTotalPainAmount = (accountNumber, splitChargesData) => {
  var totalPaidAmt = 0;
  Object.keys(splitChargesData[accountNumber]).forEach(function (element) {
    var paidAmt = splitChargesData[accountNumber][element]["totalPaid"]
    totalPaidAmt += paidAmt;
  });
  return totalPaidAmt;
}

AccountDetailsServicObject.getTotalPainAmount = getTotalPainAmount

const getTotalOutStandingAmount = (accountNumber, splitChargesData) => {
  var totalPaidAmt = 0;
  Object.keys(splitChargesData[accountNumber]).forEach(function (element) {
    var paidAmt = splitChargesData[accountNumber][element]["outStanding"];
    totalPaidAmt += paidAmt;
  });
  return totalPaidAmt;
}
AccountDetailsServicObject.getTotalOutStandingAmount = getTotalOutStandingAmount

const createCcodLoanDetailsObjectService = (summaryData, trenchdata) => {
  return new Promise((resolve, reject) => {
    let loanData = {}
    var overDueCharges = c3Calc1(trenchdata);
    var otherCharges = c4Calc1(trenchdata);
    var processingFee = c8Calc1(trenchdata);
    var interestAmount = c9Calc1(trenchdata);
    var principalOutstanding = c6d1Calc1(trenchdata);

    var tenureDays = parseInt(summaryData.MAX_DAYS);
    var tenureYears = tenureDays / 365;
    var tenureMonths = tenureYears * 12;

    var localStringObj = {
      style: "currency",
      currency: "INR",
      decimal: '2'
    }

    loanData["PRODUCT"] = summaryData.PRODUCT
    loanData["TOTAL_LIMIT"] = summaryData.TOTAL_LIMIT
    loanData["TOTAL_REV_DT"] = commonFunctionObject.replaceHyphenDate(summaryData.TOTAL_REV_DT)
    loanData["TOTAL_LIMIT"] = summaryData.TOTAL_LIMIT
    loanData["tenureMonths"] = tenureMonths
    loanData["TOT_OD_AMT"] = summaryData.TOT_OD_AMT
    loanData["overDueCharges"] = Number(overDueCharges)
    loanData["otherCharges"] = Number(otherCharges)
    loanData["processingFee"] = Number(processingFee)
    loanData["interestAmount"] = Number(interestAmount)
    loanData["AVAIL_LIMIT"] = summaryData.AVAIL_LIMIT
    loanData["OUT_BAL"] = summaryData.OUT_BAL
    loanData["principalOutstanding"] = Number(principalOutstanding)
    loanData["otherAdjustments"] = "0.00"
    resolve(loanData)



  })

}
AccountDetailsServicObject.createCcodLoanDetailsObjectService = createCcodLoanDetailsObjectService


function c6d1Calc1(trenchData) {
  var trenchData = trenchData
  var sumc6d1 = 0;
  if (trenchData) {
    if (Array.isArray(trenchData)) {
      trenchData.forEach(function (element) {
        if ((element.BLART.toUpperCase() == "C6") || (element.BLART.toUpperCase() == "D1")) {
          sumc6d1 += parseFloat(element.CALC1);
        }
      });
    } else {
      if ((trenchData.BLART.toUpperCase() == "C6") || (trenchData.BLART.toUpperCase() == "D1")) {
        sumc6d1 += parseFloat(trenchData.CALC1);
      }
    }
  } else {
    sumc6d1 = 0;
  }
  return sumc6d1;
}

function c3Calc1(trenchData) {
  var trenchData = trenchData
  var sumC3 = 0;
  if (trenchData) {
    if (Array.isArray(trenchData)) {
      trenchData.forEach(function (element) {
        if (element.BLART.toUpperCase() == "C3") {
          sumC3 += parseFloat(element.CALC1);
        }
      });
    } else {
      if (trenchData.BLART.toUpperCase() == "C3") {
        sumC3 += parseFloat(trenchData.CALC1);
      }
    }

  } else {
    sumC3 = 0;
  }

  return sumC3;

}

function c4Calc1(trenchData) {

  var trenchData = trenchData
  var sumC4 = 0;
  if (trenchData) {
    if (Array.isArray(trenchData)) {
      trenchData.forEach(function (element) {
        if (element.BLART.toUpperCase() == "C4") {
          sumC4 += parseFloat(element.CALC1);
        }
      });
    } else {
      if (trenchData.BLART.toUpperCase() == "C4") {
        sumC4 += parseFloat(trenchData.CALC1);
      }
    }

  } else {
    sumC4 = 0;
  }

  return sumC4;

}

function c8Calc1(trenchData) {
  var trenchData = trenchData
  var sumC8 = 0;
  if (trenchData) {
    if (Array.isArray(trenchData)) {
      trenchData.forEach(function (element) {
        if (element.BLART.toUpperCase() == "C8") {
          sumC8 += parseFloat(element.CALC1);
        }
      });
    } else {
      if (trenchData.BLART.toUpperCase() == "C8") {
        sumC8 += parseFloat(trenchData.CALC1);
      }
    }

  } else {
    sumC8 = 0;
  }

  return sumC8;

}

function c9Calc1(trenchData) {
  var trenchData = trenchData
  var sumC9 = 0;
  if (trenchData) {
    if (Array.isArray(trenchData)) {
      trenchData.forEach(function (element) {
        if (element.BLART.toUpperCase() == "C9") {
          sumC9 += parseFloat(element.CALC1);
        }
      });
    } else {
      if (trenchData.BLART.toUpperCase() == "C9") {
        sumC9 += parseFloat(trenchData.CALC1);
      }
    }

  } else {
    sumC9 = 0;
  }

  return sumC9;

}

const createSoaService = (requestObj) => {
  return new Promise((resolve, reject) => {
    createSoaReport(requestObj).then((response) => {
      if (response.header.status.toUpperCase() === "SUCCESS") {
        if (typeof response.body["ns1:MT_TW_VENDOR_AC_STAT_RESP"].LINEITEMS.item === "object") {
          var localStringObj = {
            style: "currency",
            currency: "INR",
            decimal: '2'
          }
          if (response.body["ns1:MT_TW_VENDOR_AC_STAT_RESP"].RETURN.MESSAGE == "") {

            var vendorReturnArray = response.body["ns1:MT_TW_VENDOR_AC_STAT_RESP"].RETURN;
            var vendorLineItems = response.body["ns1:MT_TW_VENDOR_AC_STAT_RESP"].LINEITEMS.item;
            var vendorArray = response.body["ns1:MT_TW_VENDOR_AC_STAT_RESP"];
            var vendorObj = {};
            var vendorApiObj = {};
            var vendorApiArray = [];

            vendorObj["MESSAGE_V2"] = vendorReturnArray?.MESSAGE_V2 ? vendorReturnArray.MESSAGE_V2.toString() : '-';
            vendorObj["MESSAGE"] = vendorReturnArray?.MESSAGE ? vendorReturnArray.MESSAGE.toString() : '-';
            vendorObj["MESSAGE_V3"] = vendorReturnArray?.MESSAGE_V3 ? vendorReturnArray.MESSAGE_V3.toString() : '-';
            vendorObj["CODE"] = vendorReturnArray?.CODE ? vendorReturnArray.CODE.toString() : '-';
            vendorObj["MESSAGE_V4"] = vendorReturnArray?.MESSAGE_V4 ? vendorReturnArray.MESSAGE_V4.toString() : '-';
            vendorObj["LOG_NO"] = vendorReturnArray?.LOG_NO ? vendorReturnArray.LOG_NO.toString() : '-';
            vendorObj["MESSAGE_V1"] = vendorReturnArray?.MESSAGE_V1 ? vendorReturnArray.MESSAGE_V1.toString() : '-';
            vendorObj["TYPE"] = vendorReturnArray?.TYPE ? vendorReturnArray.TYPE.toString() : '-';
            vendorObj["LOG_MSG_NO"] = vendorReturnArray?.LOG_MSG_NO ? vendorReturnArray.LOG_MSG_NO.toString() : '-';
            // vendorObj["xmlns:ns1"] = vendorArray["xmlns:ns1"] ? vendorArray["xmlns:ns1"] : '-';
            // vendorObj["LINEITEMS"] = vendorArray.LINEITEMS ? vendorArray.LINEITEMS : '-';

            if (Array.isArray(vendorLineItems)) {
              vendorLineItems.forEach(function (element) {
                vendorApiObj = {};
                vendorApiObj["PSTNG_DATE"] = element?.PSTNG_DATE ? datechange(element.PSTNG_DATE) : '-';
                vendorApiObj["AMOUNT"] = element?.AMOUNT ? Number(element.AMOUNT.toString()).toLocaleString('en-IN', localStringObj) : '₹0.00';
                vendorApiObj["DB_CR_IND"] = element?.DB_CR_IND ? element.DB_CR_IND === "S" ? "Debit" : "Credit" : '-';
                vendorApiObj["REF_DOC"] = element?.REF_DOC ? element.REF_DOC : '-';
                vendorApiObj["ITEM_TEXT"] = element?.ITEM_TEXT ? element.ITEM_TEXT : '-';
                vendorApiObj["DOC_DATE"] = element?.DOC_DATE ? datechange(element.DOC_DATE) : '-';

                vendorApiArray.push(vendorApiObj);

              })
            } else {
              vendorApiObj = {};
              vendorApiObj["PSTNG_DATE"] = vendorLineItems?.PSTNG_DATE ? datechange(vendorLineItems.PSTNG_DATE) : '-';
              vendorApiObj["AMOUNT"] = vendorLineItems?.AMOUNT ? vendorLineItems.AMOUNT : '-';
              vendorApiObj["DB_CR_IND"] = vendorLineItems?.DB_CR_IND ? vendorLineItems.DB_CR_IND === "S" ? "Debit" : "Credit" : '-';
              vendorApiObj["REF_DOC"] = vendorLineItems?.REF_DOC ? vendorLineItems.REF_DOC : '-';
              vendorApiObj["ITEM_TEXT"] = vendorLineItems?.ITEM_TEXT ? vendorLineItems.ITEM_TEXT : '-';
              vendorApiObj["DOC_DATE"] = vendorLineItems?.DOC_DATE ? datechange(vendorLineItems.DOC_DATE) : '-';

              vendorApiArray.push(vendorApiObj);


            }

            var sortedTableData = vendorApiArray.sort(function (a, b) {
              return datechangeRequest(b.DOC_DATE) > datechangeRequest(a.DOC_DATE) ? 1 : -1;
            });

            // soaReportApi(fromDate, toDate, accountNumber, company, ProgramId);
            resolve(sortedTableData)

            // ccodAccountDetailsRenderObj.tableTemplate(vendorApiArray);
            // ccodAccountDetailsRenderObj.tableTemplateMobile(vendorApiArray);
            // soaReportApi(fromDate, toDate);
          } else {
            // var accountDetailsTable = document.getElemenstById('accountDetails-tableBody');            // accountDetailsTable.classList.add('display-none')
            // $('#disbursalStatusDataMob').addClass('display-none');
            var responseMessage = response.body["ns1:MT_TW_VENDOR_AC_STAT_RESP"].RETURN.MESSAGE;
            var resMes = responseMessage.split(',');
            if (resMes[1] === " no postings within time interval") {
              reject("C-10")
            } else {
              let errorObj = { responseMessage: responseMessage, error: "C-10" }
              reject(errorObj)
            }
          }
        } else {
          reject("C-11")

        }
      } else {
        reject("C-11")

      }
    }).catch((error) => {
      reject("")

    })
  })

}
AccountDetailsServicObject.createSoaService = createSoaService


const soaReportApi = (fromDate, toDatem, accountNumber, company, ProgramId, authToken) => {
  return new Promise((resolve, reject) => {
    let fd = new Date(commonFunctionObject.replaceHyphenDate(fromDate))
    let ed = new Date(commonFunctionObject.replaceHyphenDate(toDatem))
    let YYYMM1 = fd.getFullYear() + (fd.getMonth() + 1).toString().padStart(2, '0');
    let YYYMM2 = ed.getFullYear() + (ed.getMonth() + 1).toString().padStart(2, '0');

    let requestObj = {
      "header": {
        "authToken": authToken,
        "company": company
      },
      "body": {
        "programId": ProgramId,
        "partner": {
          "low": accountNumber, //customer_no
          "high": ""
        },
        "period": {
          "low": YYYMM1, // Date(yyyymm)
          "high": YYYMM2 // Date(yyyymm)
        }
      }
    }

    generateSoaReport(requestObj).then((response) => {

      if (response.header.status.toUpperCase() === "SUCCESS") {

        var soaResponseObj = response.body["ns1:MT_TW_SOA_RESP"].T_TW_SOA.item
        var soaTransactionArray = response.body["ns1:MT_TW_SOA_RESP"].T_TW_SOA_TAB.item;
        var soaTransArr = []
        soaResponseObj["ZGCID"] = soaResponseObj?.ZGCID ? String(soaResponseObj?.ZGCID) : '';
        soaResponseObj["NEXT_RENEW_DATE"] = soaResponseObj?.NEXT_RENEW_DATE ? String(soaResponseObj?.NEXT_RENEW_DATE) : '';
        soaResponseObj["TOT_OUT"] = soaResponseObj?.TOT_OUT ? String(soaResponseObj?.TOT_OUT) : '0.00';
        soaResponseObj["BANQS"] = soaResponseObj?.BANQS ? String(soaResponseObj?.BANQS) : '';
        soaResponseObj["COLLATERAL_NUMBER"] = soaResponseObj?.COLLATERAL_NUMBER ? String(soaResponseObj?.COLLATERAL_NUMBER) : '';
        soaResponseObj["OUT_CHRG"] = soaResponseObj?.OUT_CHRG ? String(soaResponseObj?.OUT_CHRG) : '0.00';
        soaResponseObj["DESCRIPTION"] = soaResponseObj?.DESCRIPTION ? String(soaResponseObj?.DESCRIPTION) : '';
        soaResponseObj["PRODUCT"] = soaResponseObj?.PRODUCT ? String(soaResponseObj?.PRODUCT) : '';
        soaResponseObj["OUT_INT"] = soaResponseObj?.OUT_INT ? String(soaResponseObj?.OUT_INT) : '0.00';
        soaResponseObj["SANC_DATE"] = soaResponseObj?.SANC_DATE ? String(soaResponseObj?.SANC_DATE) : '';
        soaResponseObj["SOA_PERIOD"] = soaResponseObj?.SOA_PERIOD ? String(soaResponseObj?.SOA_PERIOD) : '';
        soaResponseObj["SANC_ROI"] = soaResponseObj?.SANC_ROI ? String(soaResponseObj?.SANC_ROI) : '0.00';
        soaResponseObj["CURR_ROI"] = soaResponseObj?.CURR_ROI ? String(soaResponseObj?.CURR_ROI) : '0.00';
        soaResponseObj["ONLINE_ACCOUNT_LINK"] = soaResponseObj?.ONLINE_ACCOUNT_LINK ? String(soaResponseObj?.ONLINE_ACCOUNT_LINK) : '';
        soaResponseObj["TENURE"] = soaResponseObj?.TENURE ? String(soaResponseObj?.TENURE) : '0';
        soaResponseObj["LIM_UTILIZED"] = soaResponseObj?.LIM_UTILIZED ? String(soaResponseObj?.LIM_UTILIZED) : '';
        soaResponseObj["PENAL"] = soaResponseObj?.PENAL ? String(soaResponseObj?.PENAL) : '0.00';
        soaResponseObj["SANC_LIMIT"] = soaResponseObj?.SANC_LIMIT ? String(soaResponseObj?.SANC_LIMIT) : '0.00';
        soaResponseObj["SANC_REV_DT"] = soaResponseObj?.SANC_REV_DT ? String(soaResponseObj?.SANC_REV_DT) : '';
        soaResponseObj["NAME1"] = soaResponseObj?.NAME1 ? String(soaResponseObj?.NAME1) : '';
        soaResponseObj["AVAIL_LIMIT"] = soaResponseObj?.AVAIL_LIMIT ? String(soaResponseObj?.AVAIL_LIMIT) : '0.00';
        soaResponseObj["ADDRESS"] = soaResponseObj?.ADDRESS ? String(soaResponseObj?.ADDRESS) : '';
        soaResponseObj["SOA_GEN_DT"] = soaResponseObj?.SOA_GEN_DT ? String(soaResponseObj?.SOA_GEN_DT) : '';
        soaResponseObj["LAST_RATE_CHANGE"] = soaResponseObj?.LAST_RATE_CHANGE ? String(soaResponseObj?.LAST_RATE_CHANGE) : '';
        soaResponseObj["LIFNR"] = soaResponseObj?.LIFNR ? String(soaResponseObj?.LIFNR) : '';
        soaResponseObj["STATUS"] = soaResponseObj?.STATUS ? String(soaResponseObj?.STATUS) : '';
        soaResponseObj["PRINCIPAL_OVERDUE"] = soaResponseObj?.PRINCIPAL_OVERDUE ? String(soaResponseObj?.PRINCIPAL_OVERDUE) : '0.00';
        if (soaTransactionArray) {
          if (Array.isArray(soaTransactionArray)) {
            soaTransactionArray.forEach(function (element) {
              var soaTransactionObj = {}
              soaTransactionObj["BALANCE"] = element?.BALANCE ? String(element?.BALANCE) : '';
              soaTransactionObj["BUDAT"] = element?.BUDAT ? String(element?.BUDAT) : '';
              soaTransactionObj["CREDIT"] = element?.CREDIT ? String(element?.CREDIT) : '';
              soaTransactionObj["DEBIT"] = element?.DEBIT ? String(element?.DEBIT) : '';
              soaTransactionObj["DR_CR"] = element?.DR_CR ? String(element?.DR_CR) : '';
              soaTransactionObj["INST_NO"] = element?.INST_NO ? String(element?.INST_NO) : '';
              soaTransactionObj["INST_TYPE"] = element?.INST_TYPE ? String(element?.INST_TYPE) : '';
              soaTransactionObj["LIFNR"] = element?.LIFNR ? String(element?.LIFNR) : '';
              soaTransactionObj["RCPT_NO"] = element?.RCPT_NO ? String(element?.RCPT_NO) : '';
              soaTransactionObj["TRANS_DET"] = element?.TRANS_DET ? String(element?.TRANS_DET) : '';
              soaTransactionObj["VAL_DATE"] = element?.VAL_DATE ? String(element?.VAL_DATE) : '';
              soaTransactionObj["ZDTL_SOURCE"] = element?.ZDTL_SOURCE ? String(element?.ZDTL_SOURCE) : '';

              soaTransArr.push(soaTransactionObj);
            });
          } else {

            var soaTransactionObj = {}
            soaTransactionObj["BALANCE"] = soaTransactionArray?.BALANCE ? String(soaTransactionArray?.BALANCE) : '';
            soaTransactionObj["BUDAT"] = soaTransactionArray?.BUDAT ? String(soaTransactionArray?.BUDAT) : '';
            soaTransactionObj["CREDIT"] = soaTransactionArray?.CREDIT ? String(soaTransactionArray?.CREDIT) : '';
            soaTransactionObj["DEBIT"] = soaTransactionArray?.DEBIT ? String(soaTransactionArray?.DEBIT) : '';
            soaTransactionObj["DR_CR"] = soaTransactionArray?.DR_CR ? String(soaTransactionArray?.DR_CR) : '';
            soaTransactionObj["INST_NO"] = soaTransactionArray?.INST_NO ? String(soaTransactionArray?.INST_NO) : '';
            soaTransactionObj["INST_TYPE"] = soaTransactionArray?.INST_TYPE ? String(soaTransactionArray?.INST_TYPE) : '';
            soaTransactionObj["LIFNR"] = soaTransactionArray?.LIFNR ? String(soaTransactionArray?.LIFNR) : '';
            soaTransactionObj["RCPT_NO"] = soaTransactionArray?.RCPT_NO ? String(soaTransactionArray?.RCPT_NO) : '';
            soaTransactionObj["TRANS_DET"] = soaTransactionArray?.TRANS_DET ? String(soaTransactionArray?.TRANS_DET) : '';
            soaTransactionObj["VAL_DATE"] = soaTransactionArray?.VAL_DATE ? String(soaTransactionArray?.VAL_DATE) : '';
            soaTransactionObj["ZDTL_SOURCE"] = soaTransactionArray?.ZDTL_SOURCE ? String(soaTransactionArray?.ZDTL_SOURCE) : '';

            soaTransArr.push(soaTransactionObj);
          }
        }

        if (soaTransactionArray && soaTransactionArray.length > 0) {
          // soaPdfDownload(soaResponseObj, soaTransArr);
          // getPdf(fromDate, toDate, companyName);
          resolve({ "soaResponseObj": soaResponseObj, "soaTransArr": soaTransArr })

        } else if (soaTransactionArray && typeof soaTransactionArray === "object") {
          // soaPdfDownload(soaResponseObj, soaTransArr);
          // getPdf(fromDate, toDate, companyName);
          resolve({ "soaResponseObj": soaResponseObj, "soaTransArr": soaTransArr })
        } else {
          // serviceError = {
          //   type: 'api-failure:vendorApi',
          //   message: "Data Not Found",
          //   code: '',
          //   errorId: '',
          // };
          reject("Data Not Found")

          // displayErrorModal(serviceError);
        }

      } else {
        // serviceError = {
        //   type: 'api-failure:vendorApi',
        //   message: "We are facing technical difficulty",
        //   code: 'E-2021-AD',
        //   errorId: 'C-10',
        // };
        // $('body').removeClass('loader');
        // displayErrorModal(serviceError);
        reject("C-10")
      }
    }).catch((error) => {
      reject("")
    })

  })

}
AccountDetailsServicObject.soaReportApi = soaReportApi



function datechangeRequest(date) {
  var changeDate = date.split("-");
  var newDateFormatRequest = changeDate[2] + "-" + changeDate[1] + "-" + changeDate[0];
  return newDateFormatRequest;
}

function datechange(date) {
  var changeDate = date.split("-");
  var newDateFormat = changeDate[2] + "-" + changeDate[1] + "-" + changeDate[0];
  return newDateFormat;
}

const AmortizationFinoneService = (reqObj) => {
  return new Promise((resolve, reject) => {
    repaymentSchedule(reqObj).then(response => {
      if (response.header.status === "SUCCESS") {
        if (response.body.pdfData) {
          var pdf = response.body.pdfData
          var byteArr = commonFunctionObject.base64ToArrayBuffer(pdf);
          var fileName = "Repayment Schedule.pdf";
          var mimeType = "application/pdf";
          commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);
          resolve(response)
        } else {
          reject("49")
        }
      } else {
        reject("48")
      }
    }).catch((error) => {
      reject(error)
    })
  })
}


AccountDetailsServicObject.AmortizationFinoneService = AmortizationFinoneService

const boGetUrlService = (reqObj) => {

  return new Promise((resolve, reject) => {
    getBoGetUrl(reqObj).then((response) => {
      if (response !== "") {
        if (response.header.status.toUpperCase() === "SUCCESS") {
          if (response.body.finalUrl !== "") {
            window.open(response.body.finalUrl, '_blank');
            resolve(response)
            // console.log(reqObj.header.requestType);
          } else {
            console.warn("finalUrl is not defined");
          }
        } else {
          console.warn("header fail");
        }
      }
    }).catch((error) => {
      reject("bo-get-url service break")
    })
  })
}
AccountDetailsServicObject.boGetUrlService = boGetUrlService



const getGenerateSoaReportService = (reqObj, statementId) => {

  return new Promise((resolve, reject) => {
    getGenerateSoaReport(reqObj).then((response) => {
      if (response !== "") {
        if (response.header.status.toUpperCase() === "SUCCESS") {
          if (response.body.pdfData) {
            var pdf = response.body.pdfData;
            var byteArr = commonFunctionObject.base64ToArrayBuffer(pdf);
            var fileName = "";
            if (statementId == "statement") {
              fileName = "statement report.pdf";
            }
            else {
              fileName = "report.pdf";
            }
            var mimeType = "application/pdf";
            commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);
            resolve(response)
          }
          else {
            reject("52")

          }
        }
        else {
          reject("51")
        }

      }
      else {
        reject("get-generate-soa-report Service break")
      }
    }).catch(() => {

    })
  })

}
AccountDetailsServicObject.getGenerateSoaReportService = getGenerateSoaReportService

const AmortizationBancsService = (reqObj) => {
  return new Promise((resolve, reject) => {
    getBoGetUrl(reqObj).then((response) => {
      if (response !== "") {
        if (response.header.status.toUpperCase() === "SUCCESS") {
          if (response.body.finalUrl !== "") {
            window.open(response.body.finalUrl, '_blank');
            resolve()
            // console.log(reqObj.header.requestType);
          } else {
            console.warn("finalUrl is not defined");
          }
        } else {
          console.warn("header fail");
        }
      }
    }).catch((error) => {
      reject("")
    })

  })
}
AccountDetailsServicObject.AmortizationBancsService = AmortizationBancsService;

const InterestCertificateServiceFinone = (requestObj, certificatename) => {

  return new Promise((resolve, reject) => {
    generateInterestCertificate(requestObj).then(response => {
      if (response.header.status === "SUCCESS") {
        if (response.body.pdfData) {
          var pdf = response.body.pdfData
          var byteArr = commonFunctionObject.base64ToArrayBuffer(pdf);
          var fileName = certificatename === "finalitcertificate" ? "Final IT Certificate.pdf" : "Provisional Certificate.pdf";
          var mimeType = "application/pdf";
          commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);
          resolve(response)
        } else {
          reject("24")

        }
      } else {
        reject("24")
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

AccountDetailsServicObject.InterestCertificateServiceFinone = InterestCertificateServiceFinone

const InterestCertificateServiceBancs = (requestObj) => {
  return new Promise((resolve, reject) => {
    getBoGetUrl(requestObj).then(response => {
      if (response.header.status === "SUCCESS") {
        if (response.body.finalUrl) {
          window.open(response.body.finalUrl, '_blank');
        }
        resolve()
      } else {
        reject()
      }
    }).catch((error) => {
      reject(error)
    })
  })
}
AccountDetailsServicObject.InterestCertificateServiceBancs = InterestCertificateServiceBancs


function checkForeclosure(userDetail, productName) {
  var flag = false;
  var product_Family = userDetail.productInfo.PRODUCT_FAMILY;

  var lowTicketLoans = [
    "personal loan",
    "business loan",
    "used car",
  ]
  if (lowTicketLoans.includes(productName.toLowerCase())) {
    let disbursedAmount = userDetail?.retailAccountDetails?.body.getLoanPositionDtlsReturn.disbursedAmount
    if (disbursedAmount <= 150000) {
      if (product_Family.toUpperCase() === "CFAB") {
        if (userDetail.productInfo.SOURCE_SYSTEM.toUpperCase() === "FINONE") {
          flag = true;
          return flag
        }
      }
    }
  } else if (jsHelper.isDef(userDetail.productInfo) && !jsHelper.isEmpObj(userDetail.productInfo)) {
    if (jsHelper.isDefined(userDetail.productInfo.IS_RETENTION)) {
      if (userDetail.productInfo.IS_RETENTION.toUpperCase() === 'N') {
        var checkProduct = foreclosureProductCheck(productName);
        if (checkProduct) {
          if (product_Family.toUpperCase() === "CFAB") {
            if (userDetail.productInfo.SOURCE_SYSTEM.toUpperCase() === "FINONE") {
              flag = true;
              return flag;
            }
          }
        }
      }
    }
  }
  return flag
}


AccountDetailsServicObject.checkForeclosure = checkForeclosure

function foreclosureProductCheck(productName) {
  var flag = false;

  switch (productName.toLowerCase()) {
    case 'two wheeler':
      flag = true;
      break;
    case 'consumer durable':
      flag = true;
      break;
  }
  return flag;
}
AccountDetailsServicObject.foreclosureProductCheck = foreclosureProductCheck

export default AccountDetailsServicObject