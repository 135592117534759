import clientTransactionDetails from "../../apis/client-transactions-details";
import getWealthSSOUrl from "../../apis/get-wealth-sso-url";
import commonFunctionObject from "../../common-function";

const WealthServicesObject = {

}

let wealthDashboardPopulation = (clientHoldingDetailsResponse) => {
    let ValuesDisplayObj = {
        currentValue: 0,
        unRealizedGainLoss: 0,
        netGainLoss: 0,
        assetAllocation: {
            equity: {
                value: 0,
                percentage: 0
            },
            debt: {
                value: 0,
                percentage: 0
            },
        },
        cost: 0,
        dividendInterest: 0,
        netGainLossAbsolute: 0,
    }

    let sumOfPortfolioWeightForEquity = 0;
    let sumOfPortfolioWeightForDebt = 0;

    let ValuesDisplayCalculate = clientHoldingDetailsResponse.forEach(element => {
        if (element.MarketValue) {
            ValuesDisplayObj.currentValue += element.MarketValue
        }

        if (element.ValueOfCost) {
            ValuesDisplayObj.cost += element.ValueOfCost
        }

        if (element.NetGain) {
            ValuesDisplayObj.netGainLoss += element.NetGain
        }

        if (element.Dividend) {
            ValuesDisplayObj.dividendInterest += element.Dividend
        }

        if (element.PortfolioWeight) {
            const titleLowerCase = element.Title.toLowerCase();

            if (titleLowerCase === 'equity') {
                sumOfPortfolioWeightForEquity += element.PortfolioWeight;
            } else if (titleLowerCase === 'debt') {
                sumOfPortfolioWeightForDebt += element.PortfolioWeight;
            }
        }

    });

    // Sum of 'MarketValue' -  Sum of 'ValueOfCost'
    ValuesDisplayObj.unRealizedGainLoss = ValuesDisplayObj.currentValue - ValuesDisplayObj.cost
    
    // ((SUM of 'Netgain'  /  SUM of 'ValueOfCost' ) *100 ) in %
    ValuesDisplayObj.netGainLossAbsolute = isNaN(((ValuesDisplayObj.netGainLoss/Math.abs(ValuesDisplayObj.cost)) * 100)) ? 0 : ((ValuesDisplayObj.netGainLoss/Math.abs(ValuesDisplayObj.cost)) * 100);

    // Equity %
    // Sum(PortfolioWeight) where "Title":"Equity"
    ValuesDisplayObj.assetAllocation.equity.percentage = sumOfPortfolioWeightForEquity;

    // Debt %
    // Sum(PortfolioWeight) where "Title":"Debt"
    ValuesDisplayObj.assetAllocation.debt.percentage = sumOfPortfolioWeightForDebt;

    //Equity Value
    //  Sum(PortfolioWeight) where "Title":"Equity", (Equity %/100)*Values as on date(Current value)
    ValuesDisplayObj.assetAllocation.equity.value = (ValuesDisplayObj.assetAllocation.equity.percentage / 100) * ValuesDisplayObj.currentValue

    // Debt Value
    // Sum(PortfolioWeight) where "Title":"Debt", (Debt %/100)*Values as on date(Current value)
    ValuesDisplayObj.assetAllocation.debt.value = (ValuesDisplayObj.assetAllocation.debt.percentage / 100) * ValuesDisplayObj.currentValue

    for (let keys in ValuesDisplayObj) {
        if (keys !== "assetAllocation") {
            ValuesDisplayObj[keys] = Number(ValuesDisplayObj[keys].toFixed(2));
            ValuesDisplayObj[keys] = truncateDigits(ValuesDisplayObj[keys]);
        } else {
            var title = ["debt", "equity"]
            title.forEach((type) => {
                ValuesDisplayObj.assetAllocation[type].value = Number(ValuesDisplayObj.assetAllocation[type].value.toFixed(2))
                ValuesDisplayObj.assetAllocation[type].value = truncateDigits(ValuesDisplayObj.assetAllocation[type].value);

                ValuesDisplayObj.assetAllocation[type].percentage = Number(ValuesDisplayObj.assetAllocation[type].percentage.toFixed(2))
                ValuesDisplayObj.assetAllocation[type].percentage = truncateDigits(ValuesDisplayObj.assetAllocation[type].percentage);
            })
        }
    }

    return ValuesDisplayObj;
}

WealthServicesObject.wealthDashboardPopulation = wealthDashboardPopulation;

function truncateDigits(value) {
    const numberString = value.toString();
    if (value > 999999999 && value < 1000000000) {
        return Number(numberString.split('').splice(0, 12).join(''));
    } else if (value <= 999999999.99) {
        return Number(value);
    } else {
        return 999999999.99;
    }

}

const getClientTransactionService = (reqObj) => {
    return new Promise((resolve, reject) => {
        clientTransactionDetails(reqObj).then((response) => {
            if (response.header.status === "SUCCESS") {
                resolve(response.body.transactionsDetails)
            } else {
                reject("W-05")
            }
            // resolve(response)

        }).catch(() => {
            reject("W-06")
        })
    })
}
WealthServicesObject.getClientTransactionService = getClientTransactionService;

const filterLastFiveTransactions = (response) => {
    response.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.Date) - new Date(a.Date);
    });

    let filteredArr = [];
    for (let i = 0; i < 5; i++) {
        if (i < response.length) {
            filteredArr.push(response[i])
        }
    }
    filteredArr.forEach(ele => {
        ele.Date = commonFunctionObject.convertDateToDDMMMYYYY(new Date(ele.Date))
    })

    return filteredArr

}

WealthServicesObject.filterLastFiveTransactions = filterLastFiveTransactions;


const wealthQuicklinkRedirection = (reqObj) => {
    return new Promise((resolve, reject) => {
        getWealthSSOUrl(reqObj).then((response) => {
            if (response.header.status === "SUCCESS") {
                resolve(response.body.finalUrl)
            } else {
                reject("W-07")
            }
            // resolve(response)

        }).catch(() => {
            reject("W-08")
        })
    })
}
WealthServicesObject.wealthQuicklinkRedirection = wealthQuicklinkRedirection;

function appendZeros(familyCode) {
    var dataFamilyCode = '';
    var familyCodeLength = familyCode.length;
    if (familyCodeLength < 5) {
      var zeroLen = 5 - familyCodeLength;
      var preString = '';
      for (var i = 0; i < zeroLen; i++) {
        preString += '0';
      }
      dataFamilyCode = preString + '' + familyCode;
      console.log(preString + '' + familyCode);
    } else {
      dataFamilyCode = familyCode;
    }
    return dataFamilyCode;
  }
  WealthServicesObject.appendZeros = appendZeros;

export default WealthServicesObject;