import React from 'react';
import './UploadDigilocker.scss';

const UploadDigilocker = () =>{
    return(
        <div className="DigiLocker-container">
            <div className="digilocker-data">
                <img src="assets/images/digilocker.svg" alt="digilocker"/>
                <p>Upload from Digilocker</p>
            </div>
        </div>
    )
}

export default UploadDigilocker;