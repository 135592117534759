import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import Button from "../../global/Button";
import Modal from "../../global/Modal";
import NewReferralPage from "../NewReferralPage";
import NewReferral from "../NewReferralPage/NewReferralPage";
import OldRefferalPage from "../OldRefferalPage";
import "./ReferAndEarnPopup.scss"
import DataMapping from "../../context/data-mapping";
import jsHelper from "../../utilities/jsHelper";

const ReferAndEarnPopup = ({ setreferandearnpopup }) => {
    const [themeState] = useContext(ThemeContext);
    const navigate = useNavigate();
    const [data, dispatchData] = useContext(DataMapping);
    const [redirecttofaq, setredirecttofaq] = useState(false);
    const [newoldreferralchecked, setnewoldreferralchecked] = useState(false);
    const [openmodalorpage, setopenmodalorpage] = useState(false);
    const { pageName, setPageName } = useContext(pageNameContext)
    const [selectedState, setSelectedState] = useState("new-referral");
    const [checktermcondition, setchecktermcondition] = useState(false)
    const [radioObject, setRadioObject] = useState({
        newRef: true,
        oldRef: false
    })

    const { setCustomerServicePageName } = useContext(pageNameContext);
    // const [noEmailPopUp, setNoEmailPopUp] = useState(false);
    const [showNoEmailPopUp, setShowNoEmailPopUp] = useState();

    console.log(setCustomerServicePageName)

    // redirect to faq page
    const redirecttofaqpage = () => {
        // setCustomerServicePageName("faq")
        // // setredirecttofaq(true);
        // navigate("./customer-service", { state: { screenName: "faq" } })
        // setreferandearnpopup(false)
        // navigate("/profile", { state: { redirectionFrom: "faq" } });
        window.open('https://www.tatacapital.com/content/dam/tata-capital/pdf/tc-app/Refer%20and%20earn-FAQs.pdf', "_blank");
    }

    const setradioChecked = (event) => {
        if (event.target.id == "new-referral") {

            let newObj = {
                newRef: true,
                oldRef: false
            }
            setRadioObject({ ...radioObject, ...newObj })
            setSelectedState("new-referral")

        }
        if (event.target.id == "old-referral") {
            let newObj = {
                newRef: false,
                oldRef: true
            }
            setRadioObject({ ...radioObject, ...newObj })
            setSelectedState("old-referral")
        }
    }

    const setcheckboxChecked = (event) => {
        if (event.target.id = "term-and-condition") {
            if (event.target.checked === true) {
                setchecktermcondition(true)
            } else {
                setchecktermcondition(false)
            }
        }
    }

    const handleSubmit = () => {
        systemEmailCheck()
    }

    useEffect(() => {
    }, [])

    const getLastestFieldValFromField = (params) => {
        var array = params.split("|"), m = "";
        array.forEach(function (el) {
            if (el !== "") {
                el.split(",").forEach(function (e) {
                    if (e !== "" && m === "") {
                        m = e;
                    }
                });
            }
        });
        return m;
    }

    const systemEmailCheck = () => {

        let retailEmailId = getLastestFieldValFromField(data?.getUserDataV3?.body?.emails);
        var ccodContracts = data?.getUserDataV3.body?.otherSystemDetails.filter((element) => element.SYSTEM_NAME.toLowerCase() === "ccod")
        let ccodEmail = ccodContracts[0].ccodContractDetails.map(
            (ele) => {
                if (ele.cdiContract.EMAIL && ele.cdiContract.EMAIL.trim().length !== 0) {
                    return ele.cdiContract.EMAIL
                }
            });
        let ccodEmailId = ccodEmail[0];
        if (!retailEmailId && !ccodEmailId) {
            setShowNoEmailPopUp(true);
        } else {
            if (selectedState === "new-referral") {
                setreferandearnpopup(false)
                setPageName("")
                navigate("/new-referral-page");
            } else if (selectedState === "old-referral") {
                setreferandearnpopup(false)
                setPageName("");
                navigate("/old-referral-page")
            } else {
                setPageName("");
                setSelectedState("new-referral")
            }
        }




    }

    return (
        <>
            <Modal>
                <div className="refer-and-earn-pop-up" style={{ backgroundColor: `${themeState === "THEME_DARK" ? "#22232E" : "#FFFFFF"}` }}>
                    <div className="close-btn">
                        <img className="refer-and-earn-close" src="assets/images/crossImg.svg" alt="cross image" onClick={() => { setreferandearnpopup(false) }} />
                    </div>

                    <div>
                        <div>
                            <p className="refer-and-earn-heading" style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }}>Refer and Earn</p>
                            <p className="refer-and-earn-name-label capitalizedAphabets " style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }}>Hi {data?.customerName}! Nice to meet you again</p>
                        </div>
                        <div>
                            <p className="refer-and-earn-choose-option" style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }}>What would you like to do today?</p>
                            <div className="refer-and-earn-input-field">
                                <div className="refer-and-earn-input-field-item">
                                    <input type="radio" name="select-option" id="new-referral" checked={radioObject.newRef} onChange={(event, key) => { setradioChecked(event, key) }} />
                                    <label style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }} htmlFor="new-referral">New Referral</label>
                                </div>
                                <div className="refer-and-earn-input-field-item">
                                    <input type="radio" name="select-option" id="old-referral" checked={radioObject.oldRef} onChange={(event, key) => { setradioChecked(event, key) }} />
                                    <label style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }} htmlFor="old-referral">Check status of old Referral</label>
                                </div>
                            </div>
                        </div>
                        <div className="refer-and-earn-info">
                            <p className="view-mob-and-emailid" style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }}>All communication shall be sent on your Registered mobile number and E-mail ID as updated in Tata Capital records.To view your Registered mobile number and E-mail Id<span className="refer-and-earn-link" onClick={() => { setreferandearnpopup(false); navigate("/profile")}}> Click here</span></p>
                            <p className="refer-and-earn-faq" style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }}>For FAQs<span className="refer-and-earn-link" onClick={redirecttofaqpage}> Click here</span></p>
                            <p className="refer-and-earn-term-condition" style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }}>By Proceeding ahead you agree to<span className="refer-and-earn-link"
                             onClick={() => window.open('https://www.tatacapital.com/content/dam/tata-capital/pdf/tc-app/Refer%20and%20earn-TnC.pdf', "_blank")}
                             > Terms and Conditions</span></p>
                        </div>
                        <div className="refer-and-earn-declaration">
                            <label className={themeState === "THEME_DARK" ? "refer-and-earn-dark" : "refer-and-earn-light"}>
                                <input type="checkbox" id="term-and-condition" checked={checktermcondition} onChange={(event) => { setcheckboxChecked(event) }} />
                                <span className="refer-and-earn-Checkbox"></span>
                            </label>
                            <p style={{ color: `${themeState === "THEME_DARK" ? "#ffffff" : "000000"}` }}>I am not an employee of Tata Capital Itd and / or any of its subsidiaries/affiliates and our employee out sourced by Tata Capital Ltd.</p>
                        </div>
                        <div className="refer-and-earn-btn">
                            <Button onClick={() => { handleSubmit() }} disable={checktermcondition ? false : true} className={checktermcondition ? "inactivebtn" : "activebtn"}>Continue</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            {
                showNoEmailPopUp &&
                <Modal style={{ background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""}`, }}>
                    <div className="no-email-popup" style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`, }}>
                        <div className="no-email-popup-close-btn" onClick={() => setShowNoEmailPopUp(false)}>
                            <img src={"assets/images/crossImg.svg"} alt="close" />
                        </div>
                        <div className="no-email-popup-description">
                            <p style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}`, }}>
                                No Email Id is Present
                            </p>
                        </div>
                    </div>
                </Modal>
            }

        </>
    )
}

export default ReferAndEarnPopup;
