import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getWealthSSOUrl = (data) => {

    let requestJson = {
        "header": {
            "authToken": "ajdakjdbakjsdkadkdabdkakdas",
            "requestType": "orderStatus",   /* riskProfiling | transact | portfolioAnalytics  | allReports */

        },

        "body": {
            "clientCode": "wealth01339"

        }
    }

    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, 'shaft/api/wealth/get-wealth-sso-url/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getWealthSSOUrl;