import { Navigate, useRoutes } from "react-router-dom";



// import LoginScreen from "./screens/LoginScreen/LoginScreen";
// import ELocker from "./screens/E-Locker/ELocker";
// import Investment from "./screens/Investment/Investment";
// import Overview from "./screens/Overview";
// import Profile from "./screens/Profile/Profile";
// import CustomerService from "./screens/CustomerService/CustomerService";
// import Insurance from "./screens/Insurance";
// import RetailAccountDetails from "./screens/Retail-Account-Details";
// import OnlineDisbursal from "./screens/OnlineDisbursal";
// import DisbursalInformation from "./screens/DisbursalInformation";
import Offers from "./screens/Offers/Offers";
// import OtpPage from "./components/OtpPage";
// import TrackRequest from "./components/TrackRequest";
// import OverDueSummary from "./screens/OverDueSummary/OverDueSummary";
// import RcUpdation from "./components/RcUpdation";
// import CreditScore from "./screens/CreditScore";
// import TrackAddressChange from "./components/TrackAddressChange/TrackAddressChange";
// import IframeBBPS from "./components/IframeBBPS";
import UpdateDOB from "./components/UpdateDOB/UpdateDOB";
import WhatsAppRegisterModal from "./components/WhatsAppRegisterModal";
import WhatsappServiceSubscribe from "./components/WhatsappServiceSubscribe";
import React from "react";
import TrackAddressChange from "./components/TrackAddressChange/TrackAddressChange";


const LoginScreen = React.lazy(() => import("./screens/LoginScreen/LoginScreen"));
const ELocker = React.lazy(()=> import("./screens/E-Locker/ELocker"));
const Investment = React.lazy(()=> import("./screens/Investment/Investment"));
const Overview = React.lazy(()=> import("./screens/Overview"));
const Profile = React.lazy(()=> import("./screens/Profile/Profile"));
const CustomerService = React.lazy(()=> import("./screens/CustomerService/CustomerService"));
const Insurance = React.lazy(()=> ("./screens/Insurance"));
const RetailAccountDetails = React.lazy(() => import("./screens/Retail-Account-Details"));
const OnlineDisbursal = React.lazy(()=> import("./screens/OnlineDisbursal"));
const DisbursalInformation = React.lazy(()=> import("./screens/DisbursalInformation"));
// const Offers = React.lazy(()=> import("./screens/Offers/Offers"));
const OtpPage = React.lazy(()=> import("./components/OtpPage"));
const TrackRequest = React.lazy(()=> import("./components/TrackRequest"));
const OverDueSummary = React.lazy(()=> import("./screens/OverDueSummary/OverDueSummary"));
const RcUpdation = React.lazy(()=> import("./components/RcUpdation"));
const CreditScore = React.lazy (() => import("./screens/CreditScore"));
// const TrackAddressChange = React.lazy (() => import("./components/TrackAddressChange/TrackAddressChange"));
const IframeBBPS = React.lazy(() => import("./components/IframeBBPS"));
const OldRefferalPage = React.lazy(() => import("./components/OldRefferalPage"));
const NewReferralPage = React.lazy(() => import("./components/NewReferralPage"));
const IncorrectLoanDetails = React.lazy(() => import("./components/IncorrectLoanDetails"));

// const LoginScreen = React.lazy(() => import("./screens/LoginScreen/LoginScreen"));
// const ELocker = React.lazy(()=> import ("./screens/E-Locker/ELocker"));
// const Investment = React.lazy(()=> import ("./screens/Investment/Investment"));
// const Overview = React.lazy(()=> import ("./screens/Overview"));
// const Profile = React.lazy(()=> import("./screens/Profile/Profile"));
// const CustomerService = React.lazy(()=> import("./screens/CustomerService/CustomerService"));
// const Insurance = React.lazy(()=> import("./screens/Insurance"));
// const RetailAccountDetails = React.lazy(()=> import("./screens/Retail-Account-Details"));
// const OnlineDisbursal = React.lazy(()=> import("./screens/OnlineDisbursal"));
// const DisbursalInformation = React.lazy(()=> import("./screens/DisbursalInformation"));
// const Offers = React.lazy(()=> import("./screens/Offers/Offers"));
// const TrackRequest = React.lazy(()=> import("./components/TrackRequest"));
// const OverDueSummary = React.lazy(()=> import("./screens/OverDueSummary/OverDueSummary"));
// const RcUpdation = React.lazy(()=> import("./components/RcUpdation"));
// const CreditScore = React.lazy (() => import("./screens/CreditScore"));
// const TrackAddressChange = React.lazy (() => import("./components/TrackAddressChange/TrackAddressChange"));
// const IframeBBPS = React.lazy(() => import("./components/IframeBBPS"));
// const OtpPage = React.lazy(() => import("./components/OtpPage"))


let routes = [
    {
        "path": "login",
        "element": <LoginScreen />
    },
    {
        "path": "e-locker",
        "element": <ELocker />
    },

    {
        "path": "investment",
        "element": <Investment />
    },
    {
        "path": "overview",
        "element": <Overview />
    },
    {
        "path": "profile",
        "element": <Profile />
    },
    {
        "path": "customer-service",
        "element": <CustomerService />
    },
    {
        "path": "insurance",
        "element": <Insurance />
    },
    {
        "path": "account-details",
        "element": <RetailAccountDetails />
    },
    {
        "path": "online-disbursal",
        "element": <OnlineDisbursal />
    },
    {
        "path": "disbursal-information",
        "element": <DisbursalInformation />
    },
    {
        "path": "offers",
        "element": <Offers />
    },
    {
        "path": "*",
        "element": <Navigate to="/login" />
    },
    {
        "path": "otpPage",
        "element": <OtpPage to="/otpPage" />
    },
    {
        "path": "trackRequest",
        "element": <TrackRequest to="/trackRequest" />
    },
    {
        "path": "overDue-summary",
        "element": <OverDueSummary to="/overDue-summary" />
    },
    {
        "path": "trackRequest",
        "element": <TrackRequest to="/trackRequest" />
    },
    {
        "path": "rcUpdation",
        "element": <RcUpdation to="/rcUpdation" />
    },
    {
        "path": "credit-score",
        "element": <CreditScore to="/credit-score" />
    },
    {
        "path": "track-address-change",
        "element": <TrackAddressChange to="track-address-change" />
    },
    {
        "path": "bill-payment",
        "element": <IframeBBPS to="bill-payment"/>
    },
    {
        "path":"new-referral-page",
        "element": <NewReferralPage/>
    },
    {
        "path":"old-referral-page",
        "element": <OldRefferalPage/>
    },
    {
        "path":"incorrect-loan-details",
        "element": <IncorrectLoanDetails/>
    },
    
    /* {
        "path": "whatsapp-register",
        "element": <WhatsAppRegisterModal to="whatsapp-register" />
    },
    {
        "path": "whatsapp-service-subscribe",
        "element":<WhatsappServiceSubscribe/>
    } */
    // {

    //     "path": "update-dob-form",
    //     "element": <UpdateDOB to="update-dob-form" />
    // }

];

let Routes = () => useRoutes(routes);

export default Routes;