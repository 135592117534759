import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const fetchGstInvoiceDetails = (data) => {

    let requestJson = {
        "header": {
            "authToken": "fasafsasdasdasdasdasadaadada",
            "deviceType": "",
            "browserType": ""
        },
        "body": {
            "contractNumber": "TCHHL0125845556511551",
            "fromDate": "01/01/2023",//(MM/DD/YYYY)
            "toDate": "03/01/2023"
        }
    }
    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, '/shaft/api/cis/fetch-gst-invoice-details/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default fetchGstInvoiceDetails;