import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const clientHoldingDetails = (data) => {

    let request = {
        "header": {
            "authToken": "ajdakjdbakjsdkadkdabdkakdas"
        },
        "body": {
            "clientCode": "3934",
            "excludeHeldAway": "N",
            "clientType": "H"
        }
    }
    

    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, '/shaft/api/wealth/get-client-holding-details/v1').then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error);
        })
    });
}

export default clientHoldingDetails;