import React, { useState } from "react";

//CSS
import "./ToggleButton.scss";
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import { THEME_LIGHT, THEME_DARK } from "../../context/ThemeContext/actions";
import AnalyticsContextProvider from "../../context/AnalyticsContext/AnalyticsContextProvider";
import DataMapping from "../../context/data-mapping";

const ToggleButton = () => {
  const currentTheme = sessionStorage.getItem("currentTheme");
  const { analytics } = useContext(AnalyticsContextProvider);
  console.log("currenttheme", currentTheme);
  const [themeState, dispatch, otpState] = useContext(ThemeContext);
  const [toggle, setToggle] = useState(currentTheme === THEME_LIGHT);
  const [data, dispatchData] = useContext(DataMapping);
  console.log("Analytics: ", analytics);
  const handleToggle = () => {
    dispatch({ type: themeState === THEME_LIGHT ? THEME_DARK : THEME_LIGHT });
    let trackName = {
      [THEME_DARK]: "Dark Theme",
      [THEME_LIGHT]: "Light Theme"
    }
    let analytics_data = {
      user: {
          loginMethod: "",
          loginStatus: "",
          gcid: "",
          loginType: "",
          loanaccountNo: "",
          firstloginDate: ""
      },
      products: {
          productCode:"",
      },
      data: {
          ctaText:trackName[themeState === THEME_LIGHT ? THEME_DARK : THEME_LIGHT],            
          componentName:"ToggleButton",
          bannerTitle:"",
          menuTitle:"",
          faqTitle:"",
          searchTerm:"",
          lobActivity:"",
          status:"",
          eventName: trackName[themeState === THEME_LIGHT ? THEME_DARK : THEME_LIGHT]
      }
  }
    analytics.trackData("ctaInteraction", analytics_data);

    setToggle(!toggle);
    // sessionStorage.setItem(
    //   "contextObj",
    //   JSON.stringify({
    //     themeState: themeState === THEME_LIGHT ? THEME_DARK : THEME_LIGHT,
    //     otpState: otpState,
    //   })
    // );
  };
  return (
    <div className="toggle-button header-toggle-button" onClick={handleToggle}>
      {themeState === THEME_LIGHT ? (
        <div className={data?.currentActiveData?.system?.toLowerCase() === "wealth"? "toggle-button-light-wealth" : "toggle-button-light"}>
          <div className="light-mode-left-container">
            <img src={"assets/images/lightMoon.svg"} alt="lightMoon" />
          </div>
          <div className="light-mode-right-container">
            <div className="dark-sun-rays">
              {
                data?.currentActiveData?.system?.toLowerCase() === "wealth" ?
                <img src={"assets/images/lightSunRaysWealth.svg"} alt="lightSunRaysWealth"/>
                :
                <>
              <img src={"assets/images/lightSunRays.svg"} alt="lightSunRays" />
              <div className="dark-sun">
              <img src={"assets/images/lightSun.svg"} alt="lightSun" />
            </div>
            </>
              }
            </div>
            
          </div>
        </div>
      ) : (
        <div className="toggle-button-dark">
          <div className="dark-mode-left-container">
            <img src={"assets/images/lightMoon.svg"} alt="lightMoon" />
          </div>
          <div className="dark-mode-right-container">
            <img
              src={"assets/images/darkSunRays.svg"}
              alt="darkSunRays"
              className="lightSunRays"
            />
            <img
              src={"assets/images/darkSun.svg"}
              alt="darkSun"
              className="lightSun"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ToggleButton;
