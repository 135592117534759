import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//Context
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import { THEME_LIGHT, THEME_DARK } from "../../context/ThemeContext/actions";
import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
//Components
import FullScreen from "../../global/FullScreen";
import ToggleButton from "../../global/ToggleButton";

//CSS
import "./Header.scss";
import DataMapping from "../../context/data-mapping";

const Header = ({ }) => {
  const location = useLocation();
  const navigate = useNavigate()
  const {
    customerServicePageName,
    setCustomerServicePageName,
    pageName,
    setPageName,
  } = useContext(pageNameContext);
  const checkPageNameArray = ["FAQs", "Raise a New Request", "Service Request"];
  const pathname = location.pathname.split("/")[1];

  const [themeState, dispatch] = useContext(ThemeContext);
  const [data, dispatchData] = useContext(DataMapping);


  return (
    <>

      {pathname === "login" ? (
          <header>
            <div className={pathname.toLowerCase() === "login" ? "main-container header-login-container main-header-login-container" : "main-container header-login-container"}>
              <div className="header-login-container-main">
                <div className="header-login-container-left">
                  <div className="tata-capital-login-logo">
                    {themeState === THEME_LIGHT ? (
                      <img
                        src={"assets/images/TataCapitalLogo.svg"}
                        alt="TataCapitalLogo"
                        onClick={() => { window.open('https://www.tatacapital.com/') }}
                      />
                    ) : (
                      <img
                        src={"assets/images/lightTataCapitalLogo.svg"}
                        alt="TataCapitalLogo"
                        onClick={() => { window.open('https://www.tatacapital.com/') }}
                      />
                    )}
                  </div>
                </div>
                <div className="header-login-container-right">
                  <ToggleButton />
                  <FullScreen />
                </div>
              </div>
            </div>
          </header>
      ) : (
        <div className="main-container header-container">
          <div className="header-container-left">
            <p className="header-left-title">
              {checkPageNameArray.includes(pageName) ? (
                <>
                  <img
                    src="assets/images/backArrow.svg"
                    alt="back-arrow"
                    className="back-arrow-click"
                    onClick={() => {
                      setCustomerServicePageName("banner");
                      setPageName("Self Service");
                    }}
                  />
                </>
              ) : (
                ""
              )}
              {pageName}
            </p>
            <p className="header-left-title-mobile">
              {checkPageNameArray.includes(pageName) ? (
                <>
                  <img
                    src="assets/images/backArrow.svg"
                    alt="back-arrow"
                    className="back-arrow-image"
                    onClick={() => {
                      setCustomerServicePageName("banner");
                      setPageName("Self Service");
                    }}
                  />
                </>
              ) : (
                ""
              )}
              {pageName}
            </p>
          </div>
          <div className="header-container-right">
            <div className={data?.currentActiveData?.system?.toLowerCase() === "wealth" ? "header-toggle-and-image-container-wealth" : "header-toggle-and-image-container"}>
              <ToggleButton />
              <FullScreen />
              {
                data?.currentActiveData?.system?.toLowerCase() === "wealth" ?
                  <img
                    className="post-login-tata-logo-wealth"
                    // src={"assets/images/wealth-logo.svg"}
                    src={`${themeState === "THEME_DARK" ? "assets/images/dark-icon/TataCapitalWealthLogoDarkTheme.svg" : "assets/images/light-icon/TataCapitalWealthLogoLightTheme.svg"}`}
                    alt="TataCapitalLogo"
                    onClick={() => { navigate("/overview") }}
                  /> :
                  <img
                    style={{
                      filter: `${themeState === "THEME_DARK" ? "brightness(0) invert(1)" : ""
                        }`,
                    }}
                    className="post-login-tata-logo"
                    src={"assets/images/TataCapitalLogo.svg"}
                    alt="TataCapitalLogo"
                    onClick={() => { navigate("/overview") }}
                  />
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
