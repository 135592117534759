import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const summary = (data) => {
    let requestJson = {
        "header": {
            "authToken": ""
        },
        "body": {
            "commonClientCode": ""
        }

    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/las-esb/summary/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/las-esb/summary/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default summary;
