import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import commonFunctionObject from "../../common-function";
import pageNameContext from "../../context/ThemeContext/store/PageNameContext";
import Button from "../../global/Button";
import CustomDropDown from "../../global/CustomDropDown";
import Input from "../../global/Input";
import RadiantBorder from "../../global/RadiantBorder";
import Label from "../Forms/Label";
import "./NewReferralPage.scss"
import ReferandEarnServiceObject from "../../services/ReferandEarnService";
import DataMapping from "../../context/data-mapping";
import { STOREDATA } from "../../context/data-mapping/action/action.type";
import { flushSync } from "react-dom";
import FailurePopUp from "../../global/FailurePopUp";
import jsHelper from "../../utilities/jsHelper";
import Modal from "../../global/Modal";
import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import Select from 'react-select'


const NewReferralPage = () => {
    const [data, dispatchData] = useContext(DataMapping);
    const [isOnlyClosedAccount, setIsOnlyClosedAccount] = useState(false);
    const [cityArray, setcityArray] = useState([]);
    const [cityInputChange, setcityInputChange] = useState("");
    const [cityDropDownState, setcityDropDownState] = useState(false);
    const [failurePopUpModal, setFailurePopUpModal] = useState(false);
    const [failurePopUpContent, setFailurePopUpContent] = useState({
        "apiName": "",
        "message": "",
        "errorCode": ""
    });

    const navigate = useNavigate();
    const [referraltype, setreferraltype] = useState(false)
    const [referraltypeoption, setreferraltypeoption] = useState("Select Referral Type");

    const [referraloccupation, setreferraloccupation] = useState(false)
    const [referraloccupationoption, setreferraloccupationoption] = useState("Select Occupation");

    const [productarray, setproductarray] = useState(false)
    const [productarrayoption, setproductarrayoption] = useState("Select Product");

    const [contacttime, setcontacttime] = useState(false)
    const [contacttimeoption, setcontacttimeoption] = useState("Select Time");

    const [cityname, setcityname] = useState(false)
    const [citynameoption, setcitynameoption] = useState("Select City");

    const [panValue, setPanValue] = useState("");
    const [noPanPopUp, setNoPanPopUp] = useState(false);

    const [thankYouPopup, setThankYouPopUp] = useState(false);
    const [disclaimer, setDisclaimer] = useState("");
    const [themeState] = useContext(ThemeContext);
    const [email, setEmail] = useState();

    const [filteredCityList, setFilteredCityList] = useState([]);


    const ReferralType = [
        {
            value: "Friend"
        },
        {
            value: "Relative"
        },
        {
            value: "Colleague"
        }
    ]

    const ReferralOccupation = [
        {
            value: "Salaried"
        },
        {
            value: "Self Employed"
        }
    ]

    const ProductArray = [
        {
            value: "Personal Loan"
        },
        {
            value: "Home Loan"
        },
        {
            value: "Business Loan"
        },
        {
            value: "Used Car Loan"
        },
        {
            value: "Loan Against Property"
        }
    ]

    const ContactTime = [
        {
            value: "Anytime"
        },
        {
            value: "9am-12pm"
        },
        {
            value: "12pm-3pm"
        },
        {
            value: "3pm-6pm"
        }
    ]

    useEffect(() => {
        commonFunctionObject.scrollToTop();
        pincodemastercall();
    }, []);

    const pincodemastercall = () => {

        var pincodeMasterObj = {
            "header": {
                "authToken": data.authToken,
                "filter": "all"
            },
            "body": {
                "value": ""
            }
        }
        dispatchData({
            name: "loaderFlag",
            payload: true,
            type: STOREDATA
        })
        ReferandEarnServiceObject.pincodeMasterService(pincodeMasterObj).then((pincodeMasterstateresponse) => {
            console.log(pincodeMasterstateresponse)
            dispatchData({
                name: "loaderFlag",
                payload: false,
                type: STOREDATA
            })
            pincodeMasterstateresponse.sort(function (a, b) {
                if (a["CITY_NAME"] < b["CITY_NAME"]) return -1;
                else if (a["CITY_NAME"] > b["CITY_NAME"]) return 1;
                return 0;
            });

            //Not receiving Uniquevalue filteringOUt

            var cityNames = []
            pincodeMasterstateresponse.forEach((value) => {
                cityNames.push(value.CITY_NAME)
            })

            var lookup = {};
            var items = cityNames;
            var cityNamesArray = [];

            for (var item, i = 0; item = items[i++];) {
                var name = item;

                if (!(name in lookup)) {
                    lookup[name] = 1;
                    cityNamesArray.push(name);
                }
            }

            let cityArray = []
            cityNamesArray.map((ele) => {
                cityArray.push({ "value": ele, "label": ele })
            })
            setcityArray(cityArray);

            console.log(cityNamesArray);
        }).catch((error) => {
            console.log(error)
        })
    }

    // const handleInputChange = () => {
    //     let stateObject = pincodeMasterForStateResponse.filter((value) => {
    //         return stateName.toLowerCase() === value.STATE_NAME.toLowerCase()
    //     })
    //     console.log(stateObject)
    // }






    const setOpenDropDown = (dropDown, optionStatus) => {
        flushSync(() => {
            setreferraltype(false);
            setreferraloccupation(false);
            setproductarray(false);
            setcontacttime(false);
            dropDown(optionStatus)
        });

    }

    const setcityDropDownValue = (val) => {
        setcityInputChange(val)
        setcityDropDownState(false)
        inputPopulation("city", val)
    }

    const handlecityDropDownInputChange = (e) => {
        if (e !== null) {
            inputPopulation("city", e.value)
        }
        // setcityInputChange(e.value);
        // setcityDropDownState(true);
        // const filteredList = cityArray.filter(val => val.toLowerCase().includes(e.target.value.toLowerCase()))
        // setFilteredCityList(filteredList)

    }
    const [newReferalFormObject, setNewReferalFormObject] = useState({
        city: "",
        firstName: "",
        middleName: "",
        lastName: "",
        mobileNumber: "",
        emailId: "",
        referralType: "",
        referralOccupation: "",
        referralProduct: "",
        preferredTime: "",


    })
    const [newReferalFormErrorObject, setNewReferalFormErrorObject] = useState({
        city: "",
        firstName: "",
        middleName: "",
        lastName: "",
        mobileNumber: "",
        emailId: "",
        referralType: "",
        referralOccupation: "",
        referralProduct: "",
        preferredTime: "",
    })
    const inputPopulation = (fieldName, value) => {
        let userMobileNo = data?.getUserDataV3.body.mobiles;
        let userEmailId = data?.getUserDataV3.body.emails;
        setNewReferalFormObject({ ...newReferalFormObject, [fieldName]: value });
        let newReferralErrorObj = ReferandEarnServiceObject.formFieldCheck(fieldName, value, userMobileNo, userEmailId);
        setNewReferalFormErrorObject({ ...newReferalFormErrorObject, ...newReferralErrorObj })
        // if(newReferralErrorObj.mobileNumber === "Please Enter Referral Mobile Number"){
        //     alert("MAa Chuddane same no. Daala")
        // }
    }

    const getLastestFieldValFromField = (params) => {
        var array = params.split("|"), m = "";
        array.forEach(function (el) {
            if (el !== "") {
                el.split(",").forEach(function (e) {
                    if (e !== "" && m === "") {
                        m = e;
                    }
                });
            }
        });
        return m;
    }

    const systemPanCheck = () => {
        let retailPan = getLastestFieldValFromField(data?.getUserDataV3?.body?.pans);
        var ccodContracts = data?.getUserDataV3.body?.otherSystemDetails.filter((element) => element.SYSTEM_NAME.toLowerCase() === "ccod")
        let ccodPan = ccodContracts[0].ccodContractDetails.map(
            (ele) => {
                if (ele.cdiContract.PAN && ele.cdiContract.PAN.trim().length !== 0) {
                    return ele.cdiContract.PAN
                }
            });
        if (retailPan.length > 0 && retailPan) {
            setPanValue(retailPan)
            setNoPanPopUp(false)
            console.log("PAN ID set")
            return retailPan;
        }
        if (ccodPan.length > 0 && ccodPan) {
            setPanValue(ccodPan[0])
            setNoPanPopUp(false)
            console.log("PAN ID set")
            return ccodPan[0];
        } else {
            return false
        }
    }


    const overdueCheckFn = () => {
        let overdueRetail;
        let overdueCcod;
        if (data?.basicDetails) {
            let allContracts = data?.basicDetails ? Object.keys(data?.basicDetails) : "";
            let retailContracts = allContracts.filter((contract) => {
                if (data?.basicDetails[contract]?.retailAccountDetails) {
                    return contract
                }
            })
            let ccodContracts = allContracts.filter((contract) => {
                if (data?.basicDetails[contract]?.ccodAccountDetails) {
                    return contract
                }
            })
            if (retailContracts.length > 0) {
                // let retailContracts = Object.keys(data?.getLoanPositionDTLS);
                if (retailContracts.length === 1) {
                    // let accStatusCheck = data?.basicDetails ? data?.getLoanPositionDTLS[data?.currentActiveData.accountNumber].retailAccountDetails ? commonFunctionObject.checkActiveStatus(data?.basicDetails[data?.currentActiveData.accountNumber].retailAccountDetails) : "active" : "active";
                    let accStatusCheck = data?.basicDetails ? data?.basicDetails[retailContracts] ? commonFunctionObject.checkActiveStatus(data?.basicDetails[retailContracts]?.retailAccountDetails) : "active" : "active";
                    if (accStatusCheck === "closed") {
                        setIsOnlyClosedAccount(true)
                    }
                }
                overdueRetail = retailContracts.map((contract) => {
                    if (typeof (data?.basicDetails[contract]?.retailAccountDetails?.body?.getLoanPositionDtlsReturn?.overdueAmount) === "object") {
                        return ""
                    } else if (data?.basicDetails[contract]?.retailAccountDetails?.body?.getLoanPositionDtlsReturn?.overdueAmount <= 0) {
                        return ""
                    } else if (data?.basicDetails[contract]?.retailAccountDetails?.body?.getLoanPositionDtlsReturn?.overdueAmount > 0) {
                        return data?.basicDetails[contract]?.retailAccountDetails?.body?.getLoanPositionDtlsReturn?.overdueAmount;
                    }
                })
            } 
            if (ccodContracts.length > 0) {
                overdueCcod = ccodContracts.map((contract) => {
                    if (typeof (data?.basicDetails[contract]?.ccodAccountDetails?.TOT_OD_AMT) === "object") {
                        return ""
                    } else if (data?.basicDetails[contract]?.ccodAccountDetails?.TOT_OD_AMT <= 0) {
                        return ""
                    } else if (data?.basicDetails[contract]?.ccodAccountDetails?.TOT_OD_AMT > 0) {
                        return data?.basicDetails[contract]?.ccodAccountDetails?.TOT_OD_AMT;

                    }
                })
            }

        }

        // if (data?.getLoanPositionDTLS) {
        //     let retailContracts = Object.keys(data?.getLoanPositionDTLS);
        //     if (retailContracts.length === 1) {
        //         // let accStatusCheck = data?.basicDetails ? data?.getLoanPositionDTLS[data?.currentActiveData.accountNumber].retailAccountDetails ? commonFunctionObject.checkActiveStatus(data?.basicDetails[data?.currentActiveData.accountNumber].retailAccountDetails) : "active" : "active";
        //         let accStatusCheck = data?.getLoanPositionDTLS ? data?.getLoanPositionDTLS[data?.currentActiveData.accountNumber] ? commonFunctionObject.checkActiveStatus(data?.getLoanPositionDTLS[data?.currentActiveData.accountNumber]) : "active" : "active";
        //         if (accStatusCheck === "closed") {
        //             setIsOnlyClosedAccount(true)
        //         }
        //     }
        //     overdue = retailContracts.map((contract) => {
        //         if (typeof (data?.getLoanPositionDTLS[contract]?.body?.getLoanPositionDtlsReturn?.overdueAmount) === "object") {
        //             return ""
        //         } else if (data?.getLoanPositionDTLS[contract]?.body?.getLoanPositionDtlsReturn?.overdueAmount <= 0) {
        //             return ""
        //         } else if (data?.getLoanPositionDTLS[contract]?.body?.getLoanPositionDtlsReturn?.overdueAmount > 0) {
        //             return data?.getLoanPositionDTLS[contract]?.body?.getLoanPositionDtlsReturn?.overdueAmount;
        //         }
        //     })
        // } else if (data?.vdSummary) {
        //     let ccodContracts = Object.keys(data?.vdSummary);
        //     overdue = ccodContracts.map((contract) => {
        //         if (typeof (data?.vdSummary[contract]?.TOT_OD_AMT) === "object") {
        //             return ""
        //         } else if (data?.vdSummary[contract]?.TOT_OD_AMT <= 0) {
        //             return ""
        //         } else if (data?.vdSummary[contract]?.TOT_OD_AMT > 0) {
        //             return data?.vdSummary[contract]?.TOT_OD_AMT;

        //         }
        //     })
        // }


        // return overdue;
        // console.log("qwert", overdue);

            let overdue ;
            if(overdueRetail && !overdueCcod){
                overdue = overdueRetail
            }
            if(!overdueRetail && overdueCcod){
                overdue = overdueCcod
            }
            if(overdueRetail && overdueCcod){
                overdue= overdueRetail.concat(overdueCcod)
            }
            
            let finalOverDue = overdue.filter((ele) => {
                return ele > 0;
            })
            if (finalOverDue.length > 0) {
                return true
            } else {
                return false
            }

    }

    const getNewReferral = (e) => {
        e.preventDefault();
        let userEmailArr = "";
        let userMobileNoArr = "";

        let retailUserMobileNo = data?.getUserDataV3.body.mobiles;
        let retailUserEmailId = data?.getUserDataV3.body.emails;
        userEmailArr += retailUserEmailId;
        userMobileNoArr += retailUserMobileNo;


        var ccodContracts = data?.getUserDataV3.body?.otherSystemDetails.filter((element) => element.SYSTEM_NAME.toLowerCase() === "ccod")
        let ccodUserMpbileNo = ccodContracts[0].ccodContractDetails.map(
            (ele) => {
                if (ele.cdiContract.MOBILE && ele.cdiContract.MOBILE.trim().length !== 0) {
                    userMobileNoArr += ele.cdiContract.MOBILE;
                    return ele.cdiContract.MOBILE;
                } else {
                    return false;
                }
            });
        console.log(ccodUserMpbileNo);

        let ccodUserEmail = ccodContracts[0].ccodContractDetails.map(
            (ele) => {
                if (ele.cdiContract.EMAIL && ele.cdiContract.EMAIL.trim().length !== 0) {
                    userEmailArr += ele.cdiContract.EMAIL;
                    return ele.cdiContract.EMAIL
                } else {
                    return false;
                }
            });

        console.log(ccodUserEmail);
        let pancheck = systemPanCheck();
        let overdueCheck = overdueCheckFn();
        let newReferralErrorObj = ReferandEarnServiceObject.newReferralFormValidation(newReferalFormObject, userMobileNoArr, userEmailArr);
        setNewReferalFormErrorObject({ ...newReferalFormErrorObject, ...newReferralErrorObj });

        var productName = commonFunctionObject.getProductShortName(newReferalFormObject.referralProduct)


        let currentEmailId = userEmailArr.replaceAll("|", ",");
        let emailIds = currentEmailId.split(",");
        setEmail(emailIds[0])
            let errorTest = validationCheck(newReferralErrorObj)
        if (errorTest && pancheck) {
            var reqObj = {
                "header": {
                    "authToken": data?.authToken
                },
                "body": {
                    "city": jsHelper.toString(newReferalFormObject.city),
                    "occupation": jsHelper.toString(newReferalFormObject.referralOccupation),
                    "description": jsHelper.toString(newReferalFormObject.preferredTime),
                    "productCode": jsHelper.toString(productName),
                    "mobileNumber": jsHelper.toString(newReferalFormObject.mobileNumber),
                    "firstName": jsHelper.toString(newReferalFormObject.firstName),
                    "lastName": jsHelper.toString(newReferalFormObject.lastName),
                    "email": jsHelper.toString(newReferalFormObject.emailId),
                    "middleName": jsHelper.toString(newReferalFormObject.middleName),
                    "relationshipWithReferrer": jsHelper.toString(newReferalFormObject.referralType),
                    "referrerCCID": pancheck
                }
            }
            dispatchData({
                type: STOREDATA,
                payload: true,
                name: "loaderFlag"
            })
            ReferandEarnServiceObject.getNewReferralService(reqObj).then((response) => {

                if (response.header.status.toUpperCase() === "SUCCESS") {
                    if (overdueCheck) {
                        setThankYouPopUp(true)
                        setDisclaimer(true)
                    }
                    else {
                        setThankYouPopUp(true)
                        // setDisclaimer(false)
                    }
                    console.log(response)
                    dispatchData({
                        type: STOREDATA,
                        payload: false,
                        name: "loaderFlag"
                    })
                }
            }).catch((error) => {
                if (error?.message) {
                    setFailurePopUpContent({
                        apiName: "",
                        message: error?.message,
                        errorCode: error
                    })
                    setFailurePopUpModal(true)

                } else {
                    setFailurePopUpContent({
                        apiName: "",
                        message: "We Are Experiencing Technical Difficulty. Please Try After Sometime.",
                        errorCode: error
                    })
                    setFailurePopUpModal(true)
                }
                dispatchData({
                    name: "loaderFlag",
                    payload: false,
                    type: STOREDATA
                });
                console.log("error")

            })

        }


    }

    const validationCheck = (finalErrorObject) => {
        var count = 0
        Object.keys(newReferalFormObject).map((keyName) => {
            if (finalErrorObject[keyName] && finalErrorObject[keyName] !== "") {
                count += 1
            }
        })

        if (count > 0) {
            return false
        } else {
            return true
        }
    }
    return (
        <>
            {failurePopUpModal ? <FailurePopUp
                onClick={() => setFailurePopUpModal(false)}
                visible={failurePopUpModal}
                setFailureModalStat={setFailurePopUpModal}
                failurePopUpContent={failurePopUpContent}
            /> : null}
            <div className="newreferral-parent-container main-container">
                <div className='newreferral-head'>
                    <p><img src="assets/images/backArrow.svg" alt="back-arrow" onClick={() => window.history.back()} /></p>
                    <p className="newreferral-title">New Referral</p>
                </div>

                <RadiantBorder className="newreferral-container-border">
                    <div className="newreferral-form">
                        <form onSubmit={getNewReferral}>
                            <div className="newreferral-input-container">
                                <div className="newreferral-input-field">
                                    <Label Label="Referral Type" />
                                    <CustomDropDown
                                        optionArray={ReferralType}
                                        optionState={referraltype}
                                        setOptionState={(optionStatus) => { setOpenDropDown(setreferraltype, optionStatus) }}
                                        selectedOption={referraltypeoption}
                                        setSelectedOption={setreferraltypeoption}
                                        error={newReferalFormErrorObject.referralType}
                                        errorSpace={true}
                                        setreferraloccupation={setreferraloccupation}
                                        setproductarray={setproductarray}
                                        setcontacttime={setcontacttime}
                                        referraltype={referraltype}
                                        handleDropDownChange={(value) => inputPopulation("referralType", value)}
                                        placeholderClass={referraltypeoption === "Select Referral Type" ? "dropdown-placeholder" : ""}

                                    />
                                </div>
                                <div className="newreferral-input-field">
                                    <Label Label="Referral Occupation" />
                                    <CustomDropDown
                                        optionArray={ReferralOccupation}
                                        optionState={referraloccupation}
                                        setOptionState={(optionStatus) => { setOpenDropDown(setreferraloccupation, optionStatus) }}
                                        /* setOptionState={setreferraloccupation} */
                                        selectedOption={referraloccupationoption}
                                        setSelectedOption={setreferraloccupationoption}
                                        error={newReferalFormErrorObject.referralOccupation}
                                        errorSpace={true}
                                        setreferraltype={setreferraltype}
                                        setproductarray={setproductarray}
                                        setcontacttime={setcontacttime}
                                        referraloccupation={referraloccupation}
                                        handleDropDownChange={(value) => inputPopulation("referralOccupation", value)}
                                        placeholderClass={referraloccupationoption === "Select Occupation" ? "dropdown-placeholder" : ""}

                                    />
                                </div>
                                <div className="newreferral-input-field">
                                    <Label Label="For which product are you referring" />
                                    <CustomDropDown
                                        optionArray={ProductArray}
                                        optionState={productarray}
                                        /* setOptionState={setproductarray} */
                                        setOptionState={(optionStatus) => { setOpenDropDown(setproductarray, optionStatus) }}
                                        selectedOption={productarrayoption}
                                        setSelectedOption={setproductarrayoption}

                                        error={newReferalFormErrorObject.referralProduct}
                                        errorSpace={true}
                                        handleDropDownChange={(value) => inputPopulation("referralProduct", value)}
                                        placeholderClass={productarrayoption === "Select Product" ? "dropdown-placeholder" : ""}

                                    />
                                </div>
                                <div className="newreferral-input-field">
                                    <Label Label="City" />
                                    <Select options={cityArray} placeholder="Select City" className={"newreferral-input new-referal-citydropdown"} name="city" onChange={(e) => { handlecityDropDownInputChange(e) }} />
                                    {/* <Input className={"newreferral-input"} placeholder="Select City" name="city" onChange={(e) => { handlecityDropDownInputChange(e) }} value={cityInputChange} error={newReferalFormErrorObject.city} /> */}

                                    {/* {
                                        cityInputChange && cityDropDownState && filteredCityList.length > 0 ? <div className="refer-earn-city-dropdown"> <div className="refer-city-dropDown">
                                            {
                                                cityArray.filter(val => val.toLowerCase().includes(cityInputChange.toLowerCase())).map((val, index) => <p key={index} onClick={() => setcityDropDownValue(val)} className="cityval">{val}</p>)
                                            }
                                        </div></div> : ""
                                    } */}


                                    <div className="error-description">
                                        <span>{newReferalFormErrorObject.city}</span>
                                    </div>
                                    {/* <CustomDropDown
                                    optionArray={cityNamesArray}
                                    optionState={cityname}
                                    setOptionState={setcityname}
                                    selectedOption={citynameoption}
                                    setSelectedOption={setcitynameoption}
                                    errorSpace={true}
                                /> */}
                                </div>
                                <div className="newreferral-input-field">
                                    <Label Label="First Name" />
                                    <Input className={"newreferral-input"} name="firstName" placeholder="First Name of referral" onChange={(e) => {
                                        inputPopulation("firstName", e.target.value)
                                    }} error={newReferalFormErrorObject.firstName} />
                                </div>
                                <div className="newreferral-input-field">
                                    <Label Label="Middle Name" />
                                    <Input className={"newreferral-input"} name="middleName" placeholder="Middle Name of referral" onChange={(e) => {
                                        inputPopulation("middleName", e.target.value)
                                    }}
                                    //  error={newReferalFormErrorObject.middleName} 
                                    />
                                </div>
                                <div className="newreferral-input-field">
                                    <Label Label="Last Name" />
                                    <Input className={"newreferral-input"} name="lastName" placeholder="Last Name of referral" onChange={(e) => {
                                        inputPopulation("lastName", e.target.value)
                                    }} error={newReferalFormErrorObject.lastName} />
                                </div>
                                <div className="newreferral-input-field">
                                    <Label Label="Mobile Number" />
                                    <Input className={"newreferral-input"} name="mobileNumber" placeholder="Mobile Number of the referral" onChange={(e) => {
                                        inputPopulation("mobileNumber", e.target.value)
                                    }} error={newReferalFormErrorObject.mobileNumber} maxLength="10" />
                                </div>
                                <div className="newreferral-input-field">
                                    <Label Label="Email" />
                                    <Input className={"newreferral-input"} name="emailId" placeholder="Email ID of referral" onChange={(e) => {
                                        inputPopulation("emailId", e.target.value)
                                    }} error={newReferalFormErrorObject.emailId} />
                                </div>
                                <div className="newreferral-input-field">
                                    <Label Label="Preferred time of contact" />
                                    <CustomDropDown
                                        optionArray={ContactTime}
                                        optionState={contacttime}
                                        setOptionState={(optionStatus) => { setOpenDropDown(setcontacttime, optionStatus) }}
                                        /* setOptionState={setcontacttime} */
                                        selectedOption={contacttimeoption}
                                        setSelectedOption={setcontacttimeoption}
                                        errorSpace={true}
                                        handleDropDownChange={(value) => inputPopulation("preferredTime", value)}
                                        error={newReferalFormErrorObject.preferredTime}
                                        placeholderClass={contacttimeoption === "Select Time" ? "dropdown-placeholder" : ""}

                                    />
                                </div>
                            </div>

                            <div className="newreferral-btn">
                                <Button type={"submit"}>Submit</Button>
                            </div>
                        </form>
                    </div>
                </RadiantBorder>
            </div>
            {
                thankYouPopup &&
                <Modal style={{ background: `${themeState === "THEME_DARK" ? "rgba(0,0,0,0.7)" : ""}`, }}>
                    <div className="thankyou-pop-up" style={{ background: `${themeState === "THEME_DARK" ? "#2E2F36" : ""}`, }}>
                        <div className="thankyou-pop-up-close-btn" onClick={() => window.history.back()}>
                            <img src={"assets/images/crossImg.svg"} alt="close" />
                        </div>
                        <div className="thankyou-pop-up-description">
                            <p style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}`, }}>
                                Thank you {data?.customerName} for this opportunity. We will reach out to {newReferalFormObject.firstName + " "} {newReferalFormObject.middleName + " "}{newReferalFormObject.lastName} as per the time provided by you. Your Email-Id as in our records is {email}.{!isOnlyClosedAccount && <>  To update   <span className="refer-and-earn-link" onClick={() => {
                                    navigate("/customer-service", { state: { screenName: "change-email-id" } })
                                }}>Click here</span>.</>}
                            </p>
                            {disclaimer && <p className="overdueamt-disclaimer" style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}`, }}>We have noticed there is a delinquency in your loan account, kindly pay your due loan charges to ensure your eligibility for referral incentive.</p>}
                        </div>
                    </div>
                </Modal>
            }

        </>
    )
}

export default NewReferralPage;