import React, { useContext, useEffect } from 'react';
import './UpdateDOB.scss';
import PageWrapper from '../../container/PageWrapper';
import RadiantBorder from '../../global/RadiantBorder';
import Button from '../../global/Button';
// import Input from '../../global/Input';
// import Label from '../Forms/Label';
// import Radio from '../Forms/Radio';
// import { useContext } from "react";
// import ThemeContext from "../../context/ThemeContext/store/ThemeContext";
import CustomDropDown from '../../global/CustomDropDown/CustomDropDown';
import { useState } from 'react';
import DatePicker from '../Forms/DatePicker';
import UploadDigilocker from '../Forms/UploadDigilocker';
import UploadComputer from '../Forms/UploadComputer';
import pageNameContext from '../../context/ThemeContext/store/PageNameContext';
import FormDatePicker from '../Forms/FormDatePicker/FormDatePicker';
import DigiLockerSignInModal from '../../components/DigilockerSignInModal/DigilockerSignInModal';
import CustomerServiceObject from '../../services/CustomerService';
import DataMapping from '../../context/data-mapping';
import AttachDocumentPopup from '../../components/AttachDocumentPopup/AttachDocument';
import commonFunctionObject from '../../common-function';
import { useLocation, useSearchParams } from 'react-router-dom';
import UploadForm from '../Forms/UploadForm';
import { FileUploader } from 'react-drag-drop-files';
import useReactiveForm from '../../customHooks/useReactiveForm/useReactiveForm';
import Validators from '../../customHooks/useReactiveForm/validator';
import ModernDatepicker from '../../components/Forms/ReactDatepicker';
import moment from 'moment';
import 'moment/locale/zh-cn';
import jsHelper from '../../utilities/jsHelper';
import FailurePopUp from '../../global/FailurePopUp';
import { STOREDATA } from '../../context/data-mapping/action/action.type';
import AnalyticsContextProvider from '../../context/AnalyticsContext/AnalyticsContextProvider';
import PropTypes from 'prop-types';

const UpdateDOB = ({ setScreenState, serviceRequestFn, activeComp, getDigiUserDocFunc, setCurrentPage }) => {
  const [attachDoc, setAttachDoc] = useState(false)
  const [signInDigiLocker, setSignInDigiLocker] = useState(false)
  const [issueLists, setIssueLists] = useState()
  const [filterElements, setFilterElements] = useState([])
  const [data, dispatchData] = useContext(DataMapping)
  const [submitFlag, setSubmitFlag] = useState(false);
  const { analytics } = useContext(AnalyticsContextProvider);
  const activeCurrentContract = data?.parsedUserData[data.currentActiveData.accountNumber]
  const [digiDocName, setDigiDocName] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("context daa", data);
  const userDodDDMMYYY = (date) => {
    var dob = date.split("/");
    return dob[1] + "/" + dob[0] + "/" + dob[2];
  }
  // data?.dobDigiData?.value?.dateOfBirth ? new Date(data?.dobDigiData?.value?.dateOfBirth).toISOString() : 
  const [dates, setDate] = useState(data?.dobDigiData?.value?.dateOfBirth ? moment(userDodDDMMYYY(data?.dobDigiData?.value?.dateOfBirth)) : "");
  const [failurePopUpModal, setFailurePopUpModal] = useState(false);
  const [failurePopUpContent, setFailurePopUpContent] = useState({
    "apiName": "",
    "message": "",
    "errorCode": ""
  });
  const checkLocation = useLocation();

  const { pageName, setPageName } = useContext(pageNameContext)
  setPageName("")
  useEffect(() => {
    var parameter = commonFunctionObject.getURLParams(checkLocation.search);
    if (parameter !== "") {
      if (parameter.src.toLowerCase() === "digilocker" && parameter.subsrc.toLowerCase() === 'login-success') {
        checkprefilledValues();
        removeQueryParams()
      }
    }

  }, [])

  const location = useLocation()
  console.log(dates);

  const [optionState, setOptionState] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select Birth Proof");
  const [startDate, setStartDate] = useState();
  const [dateError, setDateError] = useState("");
  const [dobInputError, setDobInputError] = useState();
  const [dobProofError, setdobProofError] = useState("");

  const dobproofArray = [
    {
      value: "PAN Card",
    },
    {
      value: "Aadhar Card",
    },
    {
      value: "Passport",
    },
    {
      value: "Birth Certificate",
    }
  ];


  const [dobForm, handleDobChange] = useReactiveForm({
    birthDate: ["", [Validators.required]]
  })

  const issueList = () => {
    const requestObj = {
      "header": {
        "authToken": data?.authToken
      },
      "body": {}
    }
    CustomerServiceObject.issueListService(requestObj).then((response) => {
      if (response.header.status.toUpperCase() === "SUCCESS") {
        setIssueLists(response.body.items)
        filterIssueList(response.body.items)
        setSignInDigiLocker(false)
        setAttachDoc(true)
        setDoc(data.digiDocFile)
      }
    }).catch((error) => {
      setFailurePopUpContent({
        apiName: "get-issues-list/v1",
        message: "We are experiencing technical difficulty please try after sometime",
        errorCode: error
      })
      setFailurePopUpModal(true)
    })
  }

  const checkDigiUser = () => {
    var accountNumber = data?.currentActiveData.accountNumber;
    var currentUserData = data?.parsedUserData[accountNumber];
    var gcid = currentUserData.GCID;
    let productName = data?.currentActiveData?.system === "las" ? "LAS" : data?.currentActiveData?.system === "ccod" ? "CCOD" : commonFunctionObject.getProductName(currentUserData);
    let analytics_data = {
      user: {
        loginMethod: "",
        loginStatus: "",
        gcid: gcid,
        loginType: "",
        loanaccountNo: accountNumber,
        firstloginDate: ""
      },
      products: {
        productCode: productName,
      },
      data: {
        ctaText: "",
        componentName: "",
        bannerTitle: "",
        menuTitle: "",
        faqTitle: "",
        searchTerm: "",
        lobActivity: "",
        status: "",
        otpStatus: "",
        eventName: "DC:Digi-Locker-Icon-Click"
      }
    }
    analytics.trackData("digilockerClick", analytics_data);

    let myObj = {
      activeComp: activeComp,
      value: {
        "gcid": activeCurrentContract.GCID,
        "formProps": {
          "setScreenState": setScreenState,
          "serviceRequestFn": serviceRequestFn,
          "activeComp": activeComp,
          "activeCurrentContract": activeCurrentContract,
          "getDigiUserDocFunc": getDigiUserDocFunc
        }
      }
    }
    if (dates !== "") {
      myObj["value"]["dateOfBirth"] = commonFunctionObject.convertDDMMYYYY(new Date(commonFunctionObject.replaceHyphenDate(dates)))
    }
    getDigiUserDocFunc(myObj)
    setSignInDigiLocker(true);
    dispatchData({
      name: "dobDigiData",
      payload: myObj,
      type: STOREDATA
    })

  }


  const dobfilter = (el) => {
    var arr = ["ADHAR", "DRVLC", "PANCR"];
    return arr.includes(el.doctype);
  }

  const filterIssueList = (issueLists) => {
    if (activeComp === "DOB") {
      setFilterElements(issueLists.filter(dobfilter));
    } else {

    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    var productFamily = commonFunctionObject.getProductFamily(activeCurrentContract)
    if (jsHelper.isDefined(dates)) {
      var userDob = commonFunctionObject.convertDDMMYYYY(new Date(commonFunctionObject.replaceHyphenDate(dates)))
      setDobInputError("");
      //digilocker condition
      if (data?.digiDocData) {
        var filterMimeData = filterElementAppendFn()
        var digilockerData = data?.digiDocData;
        var mimeType = filterMimeData[0].split("/")[1];
        console.log(mimeType);
        var reqObj = {
          "header": {
            "authToken": data?.authToken,
            "serviceRequestType": "dob",
            "productFamily": jsHelper.toString(productFamily),
            "attachmentType": "digiLocker"
          },
          "body": {
            "gcid": jsHelper.toString(activeCurrentContract.GCID),
            "accountNumber": jsHelper.toString(activeCurrentContract.CONTRACT_NUMBER),
            "description": userDob,
            "uri": digilockerData.uri,
            "documentName": activeCurrentContract.CONTRACT_NUMBER + "_" + digilockerData.description + "." + mimeType
          }
        }
        let serviceDetails = {
          "name": 'Change Date Of Birth',
          "shortName": "dob",
          "response": "",
          "dataValue": userDob,
          // "productCode": jsHelper.toString(displayAccNo.getAttribute('data-productCode')),
          "documentType": jsHelper.isDef(data?.digiDocData) ? "digiLocker" : "manualUpload",
          'erroCode': {
            "shaftService": 'E-3007-B',
            "otherService": "E-3007-C"
          },
          "errorId": {
            "shaftService": "86",
            "otherService": "87"
          }
        }
        setDigiDocName("")
        serviceRequestFn(reqObj, serviceDetails);

        setDate("");
        setFile("");
        dispatchData({
          name: "digiDocFile",
          payload: null,
          type: STOREDATA
        })
        setSelectedOption("Select Birth Proof");
      }
      else {
        if (selectedOption !== "" && selectedOption !== "Select Birth Proof") {
          setdobProofError("")
          if (file.name !== "") {
            if (file.size < 2097152) {
              commonFunctionObject.convertToBase64([file], true).then(function (fileBase64StrArr) {
                if (jsHelper.isDefined(fileBase64StrArr)) {
                  var fileExtension = file.name.split(".");
                  var extension = fileExtension[1];
                  var requestObj = {
                    "header": {
                      "authToken": data?.authToken,
                      "serviceRequestType": "dob",
                      "productFamily": productFamily
                    },
                    "body": {
                      "gcid": jsHelper.toString(activeCurrentContract.GCID),
                      "accountNumber": jsHelper.toString(activeCurrentContract.CONTRACT_NUMBER),
                      "description": userDob,
                      "attachments": [{
                        "title": activeCurrentContract.CONTRACT_NUMBER + "_" + selectedOption + "." + extension,
                        "versionData": fileBase64StrArr[0]
                      }]
                    }
                  }
                  let serviceDetails = {
                    "name": 'Change Date Of Birth',
                    "shortName": "dob",
                    "response": "",
                    "dataValue": userDob,
                    // "productCode": jsHelper.toString(displayAccNo.getAttribute('data-productCode')),
                    // "documentType": jsHelper.isDef(digiData) ? "digiLocker" : "manualUpload",
                    'erroCode': {
                      "shaftService": 'E-3007-B',
                      "otherService": "E-3007-C"
                    },
                    "errorId": {
                      "shaftService": "86",
                      "otherService": "87"
                    }
                  }
                  serviceRequestFn(requestObj, serviceDetails);

                  setDate("");
                  setFile("");
                  dispatchData({
                    name: "digiDocFile",
                    payload: null,
                    type: STOREDATA
                  })
                  setValid(false);
                  setSelectedOption("Select Birth Proof");
                } else {

                }
              }).catch()

            } else {
              setuploadFileError("Size limit 2 MB")
            }

          } else {
            setuploadFileError("Please Upload File");
          }
        }
        else {
          setdobProofError("Please Select Accepted Birth Proof Type")
        }
      }
    } else {
      if (!startDate || startDate === "") {
        setDobInputError("Please Enter Birth Date");
      }
    }
    setSubmitFlag(true);

  }

  const filterElementAppendFn = () => {
    if (jsHelper.isDefined(data?.digiDocData)) {
      var digilockerData = data?.digiDocData;
      var filterPdf = digilockerData.mime.filter(findpdf);
      return filterPdf
    } else {
      console.log("error pdf")
    }

  }

  const findpdf = (pdf) => {
    return pdf == 'application/pdf'
  }

  const [file, setFile] = useState()
  const [valid, setValid] = useState()
  const [doc, setDoc] = useState()
  const [uploadFileError, setuploadFileError] = useState("")
  const [birthProofDrop, setBirthProofDrop] = useState(false)

  const uploadFile = (file) => {
    setBirthProofDrop(true)
    handleDobChange("tanFile", file)
    setFile(file);
    var validExtension = ['giff', 'pdf', 'jpeg', 'jpg', 'png', 'bmp'];
    if (file?.name) {
      var extension = file.name.split(".")
      if (validExtension.includes(extension[extension.length - 1])) {
        setValid(true);
        setuploadFileError("");
      }
      else {
        setuploadFileError("Please Select Supported Type");
        setValid(false);
      }
    } else {
    }
  }

  const populateDob = () => {
    var currentDob = convertDOBformat(activeCurrentContract?.DOB.substring(0, 10));
    return currentDob;
  }


  const convertDOBformat = (date) => {
    var splitDate = date.split('/');
    return splitDate[1] + '/' + splitDate[0] + '/' + splitDate[2];
  }

  const activeDob = populateDob();

  const digiAttachDocName = () => {
    let digiDocName
    if (jsHelper.isDefined(data?.digiDocData)) {
      var filterMimeData = filterElementAppendFn();
      var mimeType = filterMimeData[0].split("/")[1];
      var attachDocName = data?.digiDocData.description;
      {
        submitFlag ? digiDocName = "" :
          digiDocName = attachDocName + "." + mimeType
      }
      return digiDocName;
    } else {

    }
  }

  const checkprefilledValues = () => {
    if (jsHelper.isDefined(data?.dobDigiData)) {
      issueList()
      dispatchData({
        name: "dobDigiData",
        payload: {},
        type: STOREDATA
      })
    }
  }

  const removeQueryParams = () => {
    const param = searchParams.get('src');

    if (param) {
      // 👇️ delete each query param
      searchParams.delete('src');
      searchParams.delete('subsrc');
      searchParams.delete('resource');

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };
  



  return (
    <>
      {failurePopUpModal ? <FailurePopUp
        onClick={() => setFailurePopUpModal(false)}
        visible={failurePopUpModal}
        setFailureModalStat={setFailurePopUpModal}
        failurePopUpContent={failurePopUpContent}
      /> : null}
      <div className='DOBWrapper'>
        <div className='prev DOB-heading'>
          <span><img src="assets/images/backArrow.svg" alt="" className='dob-backbtn' srcSet="" onClick={() => {
            setScreenState("list-of-sr")
            setPageName("Raise a New Request")
            setCurrentPage("")
          }} /></span>
          <span >Change Date of Birth</span>
        </div>
        <RadiantBorder className='radiantBorderDiv'>
          <form onSubmit={handleSubmit}>
            <div className='DOB-container'>

              <div className='changeDOB-container'>
                <div className='readonly-dob'>
                  <p className='text-style'>Current Date Of Birth</p>
                  {/* <p className='readonly-date'>06/11/1988</p> */}
                  <ul className="docu-list-for-req">
                    <li>{activeDob}</li>
                  </ul>
                </div>
                <div className='dob-and-proof'>
                  <div className='change-dob'>
                    <p className='text-style'>Correct Date of Birth</p>
                    {/* <FormDatePicker dobInputError={dobInputError} setDobInputError= {setDobInputError} startDate={startDate}  setStartDate={setStartDate} /> */}
                    <div className='dob-datepicker'>
                      {/* <ModernDatepicker name="birthDate" placeholder='Enter Date Of Birth' value={dates !== null ? moment(dates) : undefined} onChange={(date) => {
                      setDate((date))
                    }} /> */}
                      <ModernDatepicker placeholder='Enter Date Of Birth' dateLimit={true} onChange={(date) => {
                        console.log("date", date);
                        setDobInputError("")
                        setDate((date))
                      }} value={dates} />
                    </div>
                    {
                      dobInputError ? <p className='description-error' style={{ color: "red" }}>{dobInputError}</p> : null
                    }
                  </div>

                  <div className='upload-proof'>
                    <p className='text-style'>Upload Proof</p>
                    <div className='proof-select'>
                      <div className='upload-digilocker' onClick={() => checkDigiUser()}> <UploadDigilocker />
                        {
                          // < p > { digiAttachDocName() }</p>
                          <p>{digiDocName}</p>
                        }
                      </div>
                      <div className='or'><p className='or-text-style'>OR</p></div>
                      <div className='upload-computer'>
                        <FileUploader handleChange={uploadFile}>
                          <UploadForm fileName={file?.name} placeholder="Upload from Computer" />
                        </FileUploader>
                        {uploadFileError ? <p className='description-error' style={{ color: "red" }}>{uploadFileError}</p> : null}
                      </div>
                    </div>
                  </div>

                  {
                    birthProofDrop ?
                      <div className='dobProof'>
                        <CustomDropDown
                          optionArray={dobproofArray}
                          optionState={optionState}
                          setOptionState={setOptionState}
                          selectedOption={selectedOption}
                          setSelectedOption={setSelectedOption}
                          handleDropDownChange={() => setdobProofError("")}
                        />
                        {dobProofError ? <p className='description-error' style={{ color: "red" }}>{dobProofError}</p> : null}
                      </div> : null
                  }

                  <div className='raise-a-request-button-container'>
                    <Button disable={(valid && !data?.digiDocFile) ? false : (!valid && data?.digiDocFile) ? false : (valid && data?.digiDocFile) ? false : true} className="raise-a-request-button" type="submit">
                      Raise a Request
                      <img src="assets/images/arrow-right.svg" alt="arrow-right" />
                    </Button>
                  </div>
                </div>

              </div>
            </div>
          </form>

        </RadiantBorder>
        {attachDoc ? <AttachDocumentPopup setAttachDoc={setAttachDoc} filterElements={filterElements} setDigiDocName={setDigiDocName} /> : null}

        {signInDigiLocker ? <DigiLockerSignInModal setSignInDigiLocker={setSignInDigiLocker} activeCurrentContract={activeCurrentContract} /> : null}

      </div >
    </>

  )
}
UpdateDOB.propTypes = {
  setScreenState: PropTypes.func,
  serviceRequestFn: PropTypes.func,
  activeComp: PropTypes.string,
  getDigiUserDocFunc: PropTypes.func,
  setCurrentPage : PropTypes.func
}

export default UpdateDOB;