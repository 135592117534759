import React,{useContext} from 'react';
import './UploadForm.scss';
import ThemeContext from '../../../context/ThemeContext/store/ThemeContext';
import PropTypes from 'prop-types';

const UploadForm = ({ placeholder, supportedDoc, uploadFile, fileName }) => {
    const [themeState] =
    useContext(ThemeContext);
    return (

        <div className="upload-form">
            <div className='upload-form-container'>
                <div className="upload-form-data">
                    <img src={themeState === "THEME_DARK" ? "assets/images/fileUploadIcone.svg": "assets/images/fileUploadIconWhite.svg" } alt="form upload" />
                    <p>{fileName ? fileName : placeholder}</p>
                </div>
            </div>
            <p className="supported-doc">{supportedDoc}</p>
        </div>





    )
}

UploadForm.propTypes = {
    placeholder : PropTypes.string,
    supportedDoc : PropTypes.string,
    fileName : PropTypes.string,
  }

export default UploadForm;