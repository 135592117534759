import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getReferralDetails = (data) => {

    let requestJson = {
        "header": {
            "authToken": "dcegrgrtgrtg"
        },
        "body": {
            "referrerCCID": "AYCPG2169A"
        }
    }

    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/qualitrics/get-qualitrics-url/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/sfdc/get-referral-details/v1').then(response => {

            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getReferralDetails;