import React, { useContext } from 'react';
import './WhatsappServiceSubscribe.scss';
import Modal from '../../global/Modal';
import Button from '../../global/Button';
import ThemeContext from '../../context/ThemeContext/store/ThemeContext';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DataMapping from '../../context/data-mapping';

const WhatsappServiceSubscribe = ({ setWhatsAppSuccessPopUp }) => {
    const [themeState] = useContext(ThemeContext);
    const [data, dispatchData] = useContext(DataMapping);
    const isWealthSystemActive = data?.currentActiveData?.system?.toLowerCase() === "wealth";


    const navigate = useNavigate()
    return (
        <Modal>
            <div className='whatsapp-service-container' style={{ background: `${themeState === "THEME_DARK" ? "#22232E" : "#FFFFFF"}` }}>
                <div className='whatsapp-service-parent'>
                    <div className='whatsapp-image-container'>
                        {themeState === "THEME_DARK" ?
                            <>
                                <img className='whatsapp-subscribe-image-dark' src={isWealthSystemActive ? "assets/images/light-icon/whatsapp-img-wealth.svg" : "assets/images/dark-icon/whatsapp-register-image-dark.svg"} alt="whatsapp-service" />
                                <img className='whatsapp-subscribe-background-image-dark' src={isWealthSystemActive ? "assets/images/dark-icon/whatsapp-bg-dark.svg" : "assets/images/dark-icon/whatsapp-bg-dark.svg"} alt='whatsApp Register' />
                            </>
                            :
                            <>
                                <img className='whatsapp-subscribe-image-light' src={isWealthSystemActive ? "assets/images/light-icon/whatsapp-img-wealth.svg" : "assets/images/light-icon/whatsapp-image.svg"} alt="whatsapp image" />
                                <img className='whatsapp-subscribe-background-image-light' src={isWealthSystemActive ? "assets/images/light-icon/whatsapp-bg.svg" : "assets/images/light-icon/whatsapp-bg.svg"} alt='whatsApp Register' />
                            </>
                        }
                    </div>
                    <div className='whatsapp-subscribe-container'>
                        <div className='whatsapp-subscribe-parent'>
                            <div className='subscribe-image' >
                                <img src="assets/images/subscribe.svg" alt="subscribe image" />
                            </div>
                            <p className='subscribe' style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}>Thank you  for <br /> subscribing!</p>
                            {
                                isWealthSystemActive ? <p className='subscribe-content' style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}>
                                    You have subscribed to our WhatsApp Services. Now services are just a WhatsApp message away. Please send us a <span className='subscribe-content-para'>Hi</span> on <span className='subscribe-content-para'>7506596060</span> to access your investment related services over WhatsApp.
                                </p> : <p className='subscribe-content' style={{ color: `${themeState === "THEME_DARK" ? "#fff" : "#000"}` }}>
                                    You have subscribed to our WhatsApp Services. Now services are just a WhatsApp message away. Please send us a <span className='subscribe-content-para'>Hi</span> on <span className='subscribe-content-para'>7506756060</span> to access your loan related services over WhatsApp.
                                </p>
                            }
                            <Button onClick={() => { setWhatsAppSuccessPopUp(false); navigate("/overview") }} className={isWealthSystemActive ? "subscribe-button-wealth" : "subscribe-button"}>
                                {/* <img src="assets/images/arrow-left-white.svg" alt="arrow-left" /> */}
                                {
                                    isWealthSystemActive ?

                                        <p>Back to Dashboard</p>
                                        :
                                        <p>Close</p>
                                }
                            </Button>
                        </div>
                    </div>
                    <img onClick={() => { setWhatsAppSuccessPopUp(false) }} className='close-whatsapp-service' src="assets/images/crossImg.svg" alt="cross image" />
                </div>
            </div>
        </Modal>
    )
}
WhatsappServiceSubscribe.propTypes = {
    setWhatsAppSuccessPopUp: PropTypes.func
}

export default WhatsappServiceSubscribe;