import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getCustomerSegmentationDetails = (data) => {
    
    return new Promise((resolve, reject) => {
        stalwartApiCall('POST', data, 'shaft/api/sfdc/customer-segmentation-details/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default getCustomerSegmentationDetails;