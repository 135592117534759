import axios from "axios";
import { useNavigate } from "react-router-dom";
import logoutUser from "../../apis/logout-user/logout-user";
import DataMapping from "../../context/data-mapping";
import LogoutServiceObject from "../../services/LogoutService";
import { redirect } from "react-router-dom"
import commonFunctionObject from "../../common-function";


const stalwartApiCall = (method, data, url, headers = {}) => {
    return new Promise((resolve, reject) => {
        commonFunctionObject.getDeviceAndBrowserInfo().then((deviceInfo) => {
            // add device details in request object header {deviceType, browsersName}
            let addDeviceDetails = { ...data, header: { ...data.header, "deviceType": deviceInfo.deviceType, "browser": deviceInfo.browser } }
            const axiosRequest = {
                method: method,
                data: addDeviceDetails,
                url,
                headers: {
                    ...headers
                }
            }
            return axios(axiosRequest).then((response) => {

                switch (response.status) {
                    case 200:
                        resolve(response);
                        break;
                    case 401:
                        let authToken = axiosRequest.data.header.authToken;
                        if (axiosRequest.url.toLowerCase().search('logout-user') !== -1) {
                            redirect('/login');
                        } else {
                            let requestJson = {
                                "header": {
                                    "authToken": authToken
                                },
                                "body": {}
                            }
                            logOutApiCall(requestJson);
                        }
                        break;
                    default:
                        reject(response);
                }
            }).catch(error => {
                switch (error.response.status) {
                    case 200:
                        reject(error);
                        break;
                    case 401:
                        let authToken = axiosRequest.data.header.authToken;
                        if (axiosRequest.url.toLowerCase().search('logout-user') !== -1) {
                            redirect("login");
                        } else {
                            let requestJson = {
                                "header": {
                                    "authToken": authToken
                                },
                                "body": {}
                            }
                            logOutApiCall(requestJson);
                            redirect("login");
                        }
                        break;
                    default:
                        reject(error);
                }
                reject(error);
            })
        }).catch((error) => {
            reject("01")
        })

    })
}

const logOutApiCall = (reqObj) => {
    LogoutServiceObject.logOutUserService(reqObj).then((response) => {
        console.log("Logut");
    }).catch(() => {
        redirect("login")
    });
    redirect("login")
}

export default stalwartApiCall;