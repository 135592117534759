import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const getNewReferralDetails = (data) => {

    let requestJson = {
        "header": {
            "authToken": "MTI4OjoxMDAwMDo6ZDBmN2I4MGNiODIyNWY2MWMyNzMzN2I3YmM0MmY0NmQ6OjZlZTdjYTcwNDkyMmZlOTE5MGVlMTFlZDNlYzQ2ZDVhOjpkdmJuR2t5QW5qUmV2OHV5UDdnVnEyQXdtL21HcUlCMUx2NVVYeG5lb2M0PQ=="
        },
        "body": {
            "city": "leafvillage",
            "occupation": "shinobi",
            "description": "egrgrtgt",
            "productCode": "HL",
            "mobileNumber": "7898456512",
            "firstName": "naruto",
            "lastName": "uzumaki",
            "email": "narutouzumaki@gmail.com",
            "middleName": "jiraya",
            "relationshipWithReferrer": "friend",
            "referrerCCID": "AYCPG2169A"
        }
    }

    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/qualitrics/get-qualitrics-url/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/sfdc/generate-new-referral/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default getNewReferralDetails;