import React, { useContext } from 'react'
import './WealthTransactionsList.scss'
import Modal from "../../../global/Modal";
import { useState } from 'react';
import ThemeContext from '../../../context/ThemeContext/store/ThemeContext';
import DataMapping from '../../../context/data-mapping';
import RadiantBorder from '../../../global/RadiantBorder';
import PropTypes from 'prop-types';

const WealthTransactionsList = ({ setTransactionModal, filteredArray }) => {


  const [themeState] = useContext(ThemeContext);

  return (
    <Modal>

      <div className={`wealth-transaction-modal-container ${themeState}`} style={{ color: `${themeState === "THEME_DARK" ? "#fff" : ""}` }}>
        <div className='wealth-transaction-modal-closebtn'>
          {/* <h2>Last five transactions</h2> */}
          <img onClick={() => setTransactionModal(false)} src={`${themeState === "THEME_DARK" ? "assets/images/wealth-close-dark.svg" : "assets/images/wealth-close.svg"}`} alt="arrow" />
        </div>
        <div className='wealth-transaction-modal-header'>
          <p>Last five transactions</p>
        </div>

        <div className='wealth-transaction-modal-body'>

          <div className='wealth-transaction-section'>
            {
              filteredArray.length > 0 ?
                <div className='tableScroll'>
                  {/* <TableComponent tabelHeading={["Transaction Date","Description", "Charge Amount", "Paid Amount", "Unpaid Charges"]} tableData={splitChargesData[activeContract][popUptype].data}></TableComponent> */}
                  <table className='wealth-transaction-title'>
                    <thead>
                      <tr>
                        <th>DATE</th>
                        <th>ACTIVITY</th>
                        <th>SECURITY NAME</th>
                        <th>FOLIO NO.</th>
                        <th>QUANTITY</th>
                        <th>NAV</th>
                        <th>AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody className='wealth-transaction-data'>
                      {
                        filteredArray.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>{value.Date}</td>
                              <td>{value.TransactionType}</td>
                              <td>{value.Security}</td>
                              <td>{value.AccountName}</td>
                              <td>{value.Quantity}</td>
                              <td>{value.TransactionRate}</td>
                              <td>{value.Amount}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>

                  </table>
                </div>

                :
                <div className='noTransactions'>
                  <p>No Data Found</p>
                </div>

            }

          </div>
        </div>

      </div>

    </Modal>
  )
}

WealthTransactionsList.propTypes = {
  setTransactionModal: PropTypes.func,
  filteredArray: PropTypes.array,
}
export default WealthTransactionsList