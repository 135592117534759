import React, { useContext } from "react";
import "./TimeOutPopUp.scss";
import { useTimer } from "react-timer-hook";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import DataMapping from "../../context/data-mapping";
import useSessionAlive from "../../customHooks/useSessionAlive";
import { RESET_CONTEXT } from "../../context/data-mapping/action/action.type";
import PropTypes from 'prop-types';
const TimeOutPopUp = ({ reset, timer }) => {
  const [data, dispatchData] = useContext(DataMapping);

  const { isUserSessionAlive } = useSessionAlive();

  const navigate = useNavigate();
  useEffect(() => {
    if (timer === "00:00") {
      sessionStorage.clear();
      dispatchData({ type: RESET_CONTEXT })
      navigate("/login");
    }
  }, [timer]);

  const getAliveResponse = () => {
    isUserSessionAlive(data?.authToken).then((response) => {
      console.log("userAlive", response);
    });
  };

  const handleClick = () => {
    getAliveResponse();
    reset();
  };

  return (
    <div
      className={`timeout-pop-up-main-container ${timer === "00:00" ? "hide-pop-up" : ""
        }`}
    >
      <div className="timeout-pop-up-divs">
        <img src="assets/images/Clock.svg" alt="clock" />
        <p>Session Expires in</p>
        <p>{timer}</p>
      </div>
      <div className="timeout-pop-up-divs">
        <img src="assets/images/resetIcon.svg" alt="reset-icon" />
        <p onClick={handleClick}>Extend</p>
      </div>
    </div>
  );
};

TimeOutPopUp.propTypes = {
  reset : PropTypes.func,
  timer : PropTypes.string
}

export default TimeOutPopUp;
