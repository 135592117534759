import React, {useContext} from 'react';
import Iframe from 'react-iframe'
import './IframeBBPS.scss';
import {useLocation} from 'react-router-dom';


const IframeBBPS = () =>{
    const location = useLocation();
    return (
        <Iframe  url={location.state.url.finalUrl}
            className="iframe-content"
        />
    )
}

export default IframeBBPS;