import axios from "axios";
import stalwartApiCall from "../../utilities/stalwartCommonApi";

const searchMaster = (data) => {
    let requestJson = {
        "header": {
            "authToken": "e232dd238dddac713a87ca339474f36aad5d7d67924ec1371a192692e1c7991c"
        },
        "body": {}
    }
    return new Promise((resolve, reject) => {
        // axios.post('/shaft/api/sfdc/service-request/v1', data).then(response => {
        stalwartApiCall('POST', data, '/shaft/api/db/get-search-master-details/v1').then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    });
}

export default searchMaster;